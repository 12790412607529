import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { getWeek, getYear } from "date-fns";

import { createAndSendEvent } from "@chef/events";
import { nextDeliveryWeekDateObj } from "@chef/helpers";
import { invariant } from "@chef/utils/invariant";
import { sumByProperty } from "@chef/utils/array";
import { isEqualStrings } from "@chef/utils/equal";

import { setAddonProducts } from "../features/basket";
import { api } from "../graphql/api";
import {
  deriveProductPropertiesFromState,
  deriveVariationPropertyFromState,
} from "./util";
import type { RootState } from "..";
import { getStandaloneProductCategoryIdsFromProductCategories } from "../helpers";

/**
 * TODO: I think this could be written a lot cleaner if we changed the redux actions to be addAddonProduct and removeAddonProduct.
 * the reason I'm not doing this now is that I'm not sure what the impact of removing `selectAddonProducts` would be, and it seems like a rabbit hole
 * */

const listener = (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setAddonProducts,
    effect: async (action, listenerApi) => {
      const previousState = listenerApi.getOriginalState() as RootState;

      const prevAddonCount = sumByProperty(
        previousState.basket.addonProducts,
        "quantity",
      );
      const newAddonCount = sumByProperty(action.payload, "quantity");

      let addedOrRemoved: "added" | "removed" = "added";

      if (prevAddonCount > newAddonCount) {
        addedOrRemoved = "removed";
      }

      let changedProduct = action.payload.find(
        (ap) =>
          ap.quantity !==
          previousState.basket.addonProducts.find((p) =>
            isEqualStrings(p.productId, ap.productId),
          )?.quantity,
      );

      // This means the product has been removed, and is not included in the payload
      if (addedOrRemoved === "removed" && !changedProduct) {
        changedProduct = previousState.basket.addonProducts.find(
          (existingBasketProduct) =>
            !action.payload.some((payloadProduct) =>
              isEqualStrings(
                existingBasketProduct.productId,
                payloadProduct.productId,
              ),
            ),
        );
      }

      if (addedOrRemoved === "added" && !changedProduct) {
        changedProduct = action.payload.find(
          (existingBasketProduct) =>
            !previousState.basket.addonProducts.some((payloadProduct) =>
              isEqualStrings(
                existingBasketProduct.productId,
                payloadProduct.productId,
              ),
            ),
        );
      }

      invariant(changedProduct, "No changed product found");

      const categoriesData = await listenerApi
        .dispatch(api.endpoints.productCategories.initiate())
        .unwrap();

      const weekObj = nextDeliveryWeekDateObj();
      const week = getWeek(weekObj);
      const year = getYear(weekObj);
      const standaloneProductsData = await listenerApi
        .dispatch(
          api.endpoints.productsByCategories.initiate({
            categoryIds: getStandaloneProductCategoryIdsFromProductCategories(
              categoriesData.productCategories,
            ),
            week,
            year,
          }),
        )
        .unwrap();

      const [category, name, productTypeId] = deriveProductPropertiesFromState(
        ["productTypeName", "name", "productTypeId"],
        changedProduct.productId,
        standaloneProductsData,
      );

      const variant = deriveVariationPropertyFromState(
        "name",
        changedProduct.productId,
        changedProduct.variationId,
        standaloneProductsData,
      );

      const eventPayload = {
        affiliation: "Frontend process",
        category,
        name,
        category_id: productTypeId,
        product_id: changedProduct.productId,
        quantity: 1,
        variant,
        variant_id: changedProduct.variationId,
        price: changedProduct.price,
      } as const;

      if (addedOrRemoved === "removed") {
        createAndSendEvent("Product Removed", eventPayload);
      } else {
        createAndSendEvent("Product Added", eventPayload);
      }
    },
  });

export default listener;
