import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      THANK_YOU_FOR_YOUR_FEEDBACK: "Takk for din tilbakemelding!",
      DO_YOU_WANT_THIS_RECIPE_BACK_ON_THE_MENU:
        "Ønsker du denne retten tilbake på menyen?",
      REPORT_INTEREST: "Meld interesse",
      THIS_DISH_IS_CURRENTLY_ON_THE_MENU: "Denne retten er på menyen nå!",
      GO_TO_ORDERING: "Bestilling",
      THIS_DISH_IS_BACK_ON_THE_MENU_IN_WEEK: (week: number) =>
        `Denne retten er tilbake på menyen i uke ${week}.`,
      REMINDER_WLL_BE_SENT: "Påminnelse sendes når retten er tilbake!",
      REMIND_ME: "Påminn meg",
    },
    se: {
      THANK_YOU_FOR_YOUR_FEEDBACK: "Tack för din feedback!",
      DO_YOU_WANT_THIS_RECIPE_BACK_ON_THE_MENU:
        "Vill du ha den här rätten tillbaka på menyn?",
      REPORT_INTEREST: "Visa intresse",
      THIS_DISH_IS_CURRENTLY_ON_THE_MENU: "Den här rätten finns på menyn nu!",
      GO_TO_ORDERING: "Beställning",
      THIS_DISH_IS_BACK_ON_THE_MENU_IN_WEEK: (week: number) =>
        `Den här rätten är tillbaka på menyn i vecka ${week}.`,
      REMINDER_WLL_BE_SENT: "Påminnelse skickas när rättan är tillbaka!",
      REMIND_ME: "Påminn mig",
    },
    dk: {
      THANK_YOU_FOR_YOUR_FEEDBACK: "Tak for din feedback!",
      DO_YOU_WANT_THIS_RECIPE_BACK_ON_THE_MENU:
        "Vil du have denne ret tilbage på menuen?",
      REPORT_INTEREST: "Meld interesse",
      THIS_DISH_IS_CURRENTLY_ON_THE_MENU: "Denne ret er på menuen nu!",
      GO_TO_ORDERING: "Bestilling",
      THIS_DISH_IS_BACK_ON_THE_MENU_IN_WEEK: (week: number) =>
        `Denne ret er tilbage på menuen i uge ${week}.`,
      REMINDER_WLL_BE_SENT: "Påmindelse sendes når retten er tilbage!",
      REMIND_ME: "Påmind mig",
    },
  } as const
)[language];
