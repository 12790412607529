import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Button, ButtonCircle, Card, Tooltip } from "@chef/components";
import { Close, Help, Minus, Plus } from "@chef/icons/small";
import {
  IDeviationProduct,
  selectSubscribedStandaloneModal,
  setSubscribedStandaloneModal,
} from "@chef/state-management";

import { Modal } from "./modal/Modal";

import { intl } from "./SubscribedStandaloneModal.Intl";

interface Props {
  updateSubscription: (product: IDeviationProduct) => void;
}

export const SubscribedStandaloneModal = ({ updateSubscription }: Props) => {
  const dispatch = useDispatch();

  const {
    open,
    deviationProduct: variation,
    productName,
    maxQtyAllowed,
  } = useSelector(selectSubscribedStandaloneModal);

  const [quantity, setQuantity] = useState(variation?.quantity || 1);
  const maxCount = maxQtyAllowed || 1;

  useEffect(() => {
    if (open && variation) {
      setQuantity(variation.quantity);
    }
  }, [open, variation]);

  if (!variation) {
    return null;
  }

  const closeModal = () =>
    dispatch(
      setSubscribedStandaloneModal({
        open: false,
        deviationProduct: null,
        productName: null,
        maxQtyAllowed: null,
      }),
    );

  const onSave = () => {
    updateSubscription({
      productId: variation.productId,
      productTypeId: variation.productTypeId,
      quantity: quantity,
      variationId: variation.variationId,
      price: variation.price,
    });
    closeModal();
  };

  return (
    <Modal
      name="subscribed-standalone-modal"
      open={open}
      onClose={closeModal}
      closeable={false}
      size="md"
    >
      <Card
        noPadding
        className="flex flex-col items-center w-full pb-20 grow bg-background"
      >
        <div className="flex flex-row justify-end w-full">
          <button
            className="w-10 h-10 rounded bg-grey-3 hover:bg-grey-2 "
            onClick={closeModal}
          >
            <Close />
          </button>
        </div>
        <div className="max-w-md pt-4 text-center">
          <h1 className="text-2xl">
            <strong>{intl.CHOOSE_HOW_MANY_YOU_WANT_TO_SUBSCRIBE_TO}</strong>
          </h1>
          <p className="px-4 mt-4">
            <strong>{productName}</strong>{" "}
            {intl.WILL_BE_ADDED_TO_EVERY_DELIVERY}
          </p>
        </div>
        <div className="flex flex-row items-center m-4">
          <ButtonCircle
            Icon={Minus}
            disabled={quantity <= 0}
            onClick={() => setQuantity((q) => q - 1)}
          />
          <div className="py-3 m-4 bg-white rounded border-grey-1 border-1.5 px-6">
            <strong className="text-2xl">
              {quantity} {intl.PIECES}
            </strong>
          </div>
          <ButtonCircle
            primary
            Icon={Plus}
            disabled={quantity >= maxCount}
            onClick={() => setQuantity((q) => q + 1)}
          />
        </div>
        <div className="flex flex-col justify-center w-64 gap-3">
          <Button primary onClick={onSave}>
            {intl.SAVE_CHANGE}
          </Button>
          <Button outlined onClick={closeModal}>
            {intl.CANCEL}
          </Button>
        </div>
        {intl.SUBSCRIBE_TO_GROCERIES_TOOLTIP_TEXT !== "" && (
          <div className="flex flex-row items-center mt-10">
            <Tooltip
              variant="light"
              element={
                <div className="max-w-xs m-4">
                  {intl.SUBSCRIBE_TO_GROCERIES_TOOLTIP_TEXT}
                </div>
              }
            >
              <Help className="text-primary" />
              <span className="pl-2 text-sm">
                {intl.SUBSCRIBE_TO_GROCERIES}
              </span>
            </Tooltip>
          </div>
        )}
      </Card>
    </Modal>
  );
};
