import {
  isMealboxProduct,
  isOneSubProduct,
  isPickAndMixProduct,
  isStandaloneProduct,
  isAddonProduct,
  isFinancialProduct,
} from "@chef/helpers";
import { OrderQuery } from "@chef/state-management";
import { isNotEmptyArray } from "@chef/utils/array";
import { nonNullable } from "@chef/utils/nonNullable";
import { getAttribute } from "@chef/state-management/helpers";

import { Meals } from "../shared/Meals";

interface OrderMealsProps {
  order: OrderQuery["order"];
  initiallyExpanded?: boolean;
}

export const OrderMeals = ({ order, initiallyExpanded }: OrderMealsProps) => {
  const primaryProduct = order.orderLines.find(
    (ol) =>
      isMealboxProduct(ol.variation.product) ||
      isFinancialProduct(ol.variation.product) ||
      isOneSubProduct(ol.variation.product),
  );
  const pickAndMixProduct = order.orderLines.find((ol) =>
    isPickAndMixProduct(ol.variation.product),
  );

  const product = primaryProduct || pickAndMixProduct;

  if (!product) {
    return null;
  }

  const recipes = order.orderLines
    .filter(
      (orderLine) =>
        !(
          isStandaloneProduct(orderLine.variation.product) ||
          isAddonProduct(orderLine.variation.product)
        ),
    )
    .flatMap((ol) => {
      if (!isNotEmptyArray(ol.recipes)) {
        return null;
      }

      if (pickAndMixProduct) {
        const recipe = ol.recipes[0];
        return {
          recipe,
          price: ol.fullPrice,
        };
      } else if (isMealboxProduct(ol.variation.product)) {
        return ol.recipes.map((recipe) => ({
          recipe,
          price: 0,
        }));
      }
      return null;
    })
    .filter(nonNullable);

  const portions = +(getAttribute("Portions", product?.variation) || "0");
  const priceOfMeals = recipes.reduce((acc, recipe) => acc + recipe.price, 0);

  return (
    <Meals
      dishes={
        recipes?.map((recipe) => ({
          name: recipe.recipe.recipeName,
          extraPrice: primaryProduct ? recipe.price : 0,
        })) || []
      }
      portions={portions}
      priceOfMeals={primaryProduct?.fullPrice || priceOfMeals}
      initiallyExpanded={initiallyExpanded}
      quantity={product.quantity}
    />
  );
};
