import { useState } from "react";
import clsx from "clsx";

import { Checkbox } from "@chef/components";

interface RecipeStepProps {
  description: string;
  heading: string;
  order: number;
  type?: "default" | "progress";
  children?: React.ReactNode;
}

export const RecipeStep = ({
  description,
  heading,
  order,
  type = "default",
  children,
}: RecipeStepProps) => {
  const [checked, setChecked] = useState(false);

  if (type === "progress") {
    return (
      <div className="mt-3">
        <Checkbox
          onChange={() => setChecked(!checked)}
          checked={checked}
          className={clsx(
            checked ? "bg-informationBG border-none" : "border-grey-1",
            "rounded-full text-secondary",
          )}
        >
          <p className={clsx(checked && "text-secondary", "flex gap-2")}>
            <strong>
              {order}. <span dangerouslySetInnerHTML={{ __html: heading }} />
            </strong>
          </p>
        </Checkbox>

        <div
          className={clsx(
            checked
              ? "text-grey-1 truncate border-informationBG border-1"
              : "border-grey-1",
            "pl-6 ml-2.5 border-l-2 border-dashed pb-4 mt-4",
          )}
        >
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={clsx(checked && "truncate")}
          />
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="pb-5 mt-3">
      <strong className="flex gap-2 pb-2">
        {order}.
        <p dangerouslySetInnerHTML={{ __html: heading }} />
      </strong>

      <div dangerouslySetInnerHTML={{ __html: description }} />
      {children}
    </div>
  );
};
