import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      ADD_RECIPE_TO_FAVORITES: (recipeName: string) =>
        `Legg til i dine favoritter ${recipeName}`,
      REMOVE_RECIPE_FROM_FAVORITES: (recipeName: string) =>
        `Fjern fra dine favoritter ${recipeName}`,
    },
    se: {
      ADD_RECIPE_TO_FAVORITES: (recipeName: string) =>
        `Lägg till i dina favoriter ${recipeName}`,
      REMOVE_RECIPE_FROM_FAVORITES: (recipeName: string) =>
        `Ta bort från dina favoriter ${recipeName}`,
    },
    dk: {
      ADD_RECIPE_TO_FAVORITES: (recipeName: string) =>
        `Tilføj til dine favoritter ${recipeName}`,
      REMOVE_RECIPE_FROM_FAVORITES: (recipeName: string) =>
        `Fjern fra dine favoritter ${recipeName}`,
    },
  } as const
)[language];
