import clsx from "clsx";
import FocusLock from "react-focus-lock";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { motion, useDragControls, type Variants } from "framer-motion";

import { Close } from "@chef/icons/small";
import { useIntercom } from "@chef/hooks";
import { ButtonCircle } from "@chef/components";

import { BackToTopButton } from "../BackToTopButton";

import { intl } from "./Modal.Intl";

type ModalProps = {
  open: boolean;
  name: string;
  title?: string;
  size?: "auto" | "sm" | "md" | "lg" | "xl" | "fullscreen";
  backToTop?: boolean;
  children?:
    | ((args: {
        onClose?: () => void;
        closeable: boolean;
        open: boolean;
      }) => React.ReactNode)
    | React.ReactNode;
} & (
  | {
      animation: "fly-up";
      gesture?: "pull-down-to-close" | false;
    }
  | {
      animation: "fly-down";
      gesture?: "pull-up-to-close" | false;
    }
  | {
      animation?: "none";
      gesture?: never;
    }
) &
  (
    | { closeable?: boolean; onClose: () => void }
    | { closeable?: false; onClose?: () => void }
  );

const variants: Variants = {
  none: {},
  "fly-up": {
    y: "40vh",
    opacity: 1,
  },
  "fly-down": {
    y: "-40vh",
    opacity: 1,
  },
};

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      open,
      size = "lg",
      animation = "none",
      closeable = true,
      backToTop = true,
      gesture,
      name,
      title,
      onClose,
      children,
    },
    forwardedRef,
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const modalRoot = useRef<HTMLDivElement | null>(
      typeof document !== "undefined"
        ? document.querySelector("#modalNode")
        : null,
    );

    const [isScrollable, setIsScrollable] = useState(false);

    const dragControls = useDragControls();
    const { hideBubble, showBubble } = useIntercom();

    useEffect(() => {
      if (!modalRoot.current) {
        modalRoot.current = document.querySelector("#modalNode");
      }
    }, []);

    useEffect(() => {
      const el = document.querySelector<HTMLDivElement>("#modalNode");

      if (!el) {
        return;
      }

      modalRoot.current = el;
    }, []);

    useEffect(() => {
      if (!open) {
        return;
      }

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape" && open) {
          onClose?.();
        }
      };

      const onResize = () => {
        const refObject = forwardedRef as React.RefObject<HTMLDivElement>;

        if (!refObject?.current) {
          return;
        }

        const modalHeight = refObject.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const _isScrollable = modalHeight > windowHeight;

        setIsScrollable(_isScrollable);

        if (!_isScrollable && window.innerWidth < 768) {
          const topMargin = windowHeight;
          refObject.current.style.marginTop = `${topMargin}px`;
        }
      };

      if (open) {
        hideBubble();
        document.body.style.overflow = "hidden";

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("resize", onResize);

        onResize();
      } else {
        showBubble();
        document.body.style.overflow = "auto";
      }

      return () => {
        showBubble();
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("resize", onResize);

        document.body.style.overflow = "auto";
      };
    }, [open, onClose, hideBubble, showBubble]);

    if (!open) {
      return null;
    }

    const element = (
      <FocusLock>
        <div
          ref={forwardedRef}
          className="fixed inset-0 z-50 w-screen overflow-auto h-dvh"
          onClick={() => {
            if (closeable) {
              onClose?.();
            }
          }}
        >
          <div
            aria-hidden="true"
            className="fixed inset-0 z-30 w-full h-dvh bg-black/60 backdrop-blur-sm"
          />
          <motion.div
            ref={ref}
            role="dialog"
            aria-modal="true"
            id={name}
            aria-labelledby={`${name}-dialog-title`}
            className={clsx("relative z-50", {
              "md:px-4 xl:px-8": size !== "fullscreen",
            })}
            variants={variants}
            initial={animation}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              type: "spring",
              stiffness: 720,
              damping: 42,
            }}
          >
            <motion.div
              drag="y"
              dragListener={false}
              dragControls={dragControls}
              dragConstraints={{ top: 0, bottom: 0 }}
              onDragEnd={(_, info) => {
                if (gesture === "pull-down-to-close" && info.offset.y > 100) {
                  onClose?.();
                }

                if (gesture === "pull-up-to-close" && info.offset.y < -100) {
                  onClose?.();
                }
              }}
              className={clsx("relative mx-auto", {
                "max-w-screen-sm": size === "sm",
                "max-w-screen-md": size === "md",
                "max-w-screen-lg": size === "lg",
                "max-w-screen-xl": size === "xl",
                "h-screen w-full": size === "fullscreen",
                "mx-auto": size !== "fullscreen",
                "md:my-4 xl:my-8":
                  size !== "fullscreen" && animation === "none",
                "md:mt-4 xl:mt-8":
                  size !== "fullscreen" && animation === "fly-up" && !gesture,
                "md:mb-4 xl:mb-8":
                  size !== "fullscreen" && animation === "fly-down" && !gesture,
                "mt-8 md:mt-16": gesture === "pull-down-to-close",
                "mb-8 md:mt-16": gesture === "pull-up-to-close",
              })}
              onClick={(e) => e.stopPropagation()}
            >
              {title && (
                <div>
                  <h2 id={`${name}-dialog-title`}>{title}</h2>
                </div>
              )}

              {gesture && (
                <div
                  onPointerDown={(e) => dragControls.start(e)}
                  className={clsx(
                    "absolute z-20 flex justify-center w-full h-40 lg:hidden touch-none",
                    {
                      "top-0 items-start": gesture === "pull-down-to-close",
                      "bottom-0 items-end": gesture === "pull-up-to-close",
                    },
                  )}
                >
                  <div className="w-32 h-1 my-1 bg-black rounded" />
                </div>
              )}

              {closeable && (
                <button
                  onClick={onClose}
                  className={clsx(
                    "absolute top-0 right-0 z-20 flex items-center justify-center w-10 h-10 bg-grey-3",
                    size !== "fullscreen" && "rounded-tr-lg rounded-bl-lg ",
                  )}
                  aria-label={intl.CLOSE}
                >
                  <Close className="w-4 h-4" />
                </button>
              )}

              {typeof children === "function"
                ? children({ onClose, closeable, open })
                : children}

              {backToTop && isScrollable && (
                <div className="mt-6">
                  <BackToTopButton element={ref.current} sticky={false} />
                </div>
              )}

              {closeable && isScrollable && (
                <div className="flex items-center justify-center w-full h-10 my-6">
                  <ButtonCircle
                    onClick={onClose}
                    Icon={Close}
                    className="flex items-center w-10 h-10 gap-2 rounded-full"
                  />
                </div>
              )}
            </motion.div>
          </motion.div>
        </div>
      </FocusLock>
    );

    if (!modalRoot.current) {
      return null;
    }

    return createPortal(element, modalRoot.current);
  },
);

Modal.displayName = "Modal";
