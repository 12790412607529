import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import Link from "next/link";

import {
  formatTimeInterval,
  getWeek,
  getYear,
  nextDeliveryWeekDateObj,
} from "@chef/helpers";
import {
  IAddressValidationModel,
  TimeblocksByRangeWithGeoQuery,
  useTimeblocksByRangeWithGeoQuery,
} from "@chef/state-management";
import { useExtendedForm } from "@chef/hooks";
import { Button, Card } from "@chef/components";
import { ChevronRight, Time } from "@chef/icons/src/components/small";
import { BRAND_PATHS, WEEKDAY } from "@chef/constants";
import { groupTimeblocksDataByWeekday } from "@chef/state-management/helpers";

import { ContactForm } from "./ContactForm";
import { AddressForm } from "./AddressForm";
import { PageLoader } from "./PageLoader";

import { intl } from "./DeliveryChecker.Intl";

interface DeliveryCheckerGeoFenceProps {
  showCTA?: boolean;
}

interface GeofenceDeliveryStatusProps {
  timeblocksByRangeWithGeo: TimeblocksByRangeWithGeoQuery["timeblocksByRangeWithGeo"];
  postalCode: string;
  validatedAddress: IAddressValidationModel;
  showCTA?: boolean;
}

const schema = yup
  .object()
  .shape({
    streetAddress: yup.string().required(),
    postalCode: yup.string().required(),
    postalArea: yup.string().required(),
  })
  .required();
type AddressFormValues = yup.InferType<typeof schema>;

export const DeliveryCheckerGeoFence = ({
                                          showCTA,
                                        }: DeliveryCheckerGeoFenceProps) => {
  const nextDeliveryWeekObj = nextDeliveryWeekDateObj();
  const week = getWeek(nextDeliveryWeekObj);
  const year = getYear(nextDeliveryWeekObj);

  const methods = useExtendedForm<AddressFormValues>({
    defaultValues: {
      postalCode: "",
      postalArea: "",
      streetAddress: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { formValues } = methods;
  const { validatedAddress = {}, postalCode } = formValues;

  const { currentData: timeblocksWithGeo, isFetching } =
    useTimeblocksByRangeWithGeoQuery(
      {
        week,
        year,
        range: 1,
        postalCode,
        latitude: validatedAddress.latitude,
        longitude: validatedAddress.longitude,
      },
      {
        skip:
          postalCode.length !== intl.POSTAL_CODE_LENGTH ||
          !validatedAddress.latitude ||
          !validatedAddress.longitude,
      },
    );

  return (
    <div>
      <Card className="mb-6">
        <p>{intl.WHY_WE_NEED_FULL_ADDRESS}</p>
      </Card>
      <FormProvider {...methods}>
        <div className="grid grid-cols-12 gap-4">
          <AddressForm />
        </div>
      </FormProvider>
      {isFetching && (
        <div className="my-4">
          <PageLoader />
        </div>
      )}
      {timeblocksWithGeo && validatedAddress.streetNumber && (
        <GeoFenceDeliveryStatus
          timeblocksByRangeWithGeo={timeblocksWithGeo.timeblocksByRangeWithGeo}
          postalCode={validatedAddress.postalCode}
          validatedAddress={validatedAddress}
          showCTA={showCTA}
        />
      )}
    </div>
  );
};

const GeoFenceDeliveryStatus = ({
                                  timeblocksByRangeWithGeo,
                                  postalCode,
                                  validatedAddress,
                                  showCTA,
                                }: GeofenceDeliveryStatusProps) => {
  const timeblocksData = timeblocksByRangeWithGeo.weeks[0].timeblocks;

  const canDeliver = timeblocksByRangeWithGeo.deliveryAvailable;
  const addressNameString = `${validatedAddress.streetName} ${validatedAddress.streetNumber}`;

  if (!canDeliver) {
    return (
      <Card
        className="flex flex-col gap-2 px-4 py-6 md:px-10 md:py-10 bg-grey-3"
        noShadow
        transparent
        noPadding
      >
        <h2>
          <strong>{intl.SORRY}</strong>
        </h2>

        <p>{intl.WE_CANNOT_DELIVER_TO(addressNameString)}</p>

        <ContactForm postalCode={postalCode} address={addressNameString} />
      </Card>
    );
  }

  return (
    <Card
      className="flex flex-col gap-8 px-4 py-6 md:px-10 md:py-10 bg-grey-3"
      noShadow
      transparent
      noPadding
    >
      <h2>
        <strong>{intl.OF_COURSE_WE_DELIVER_TO(addressNameString)}</strong>
      </h2>
      <dl className="flex flex-col gap-6">
        {groupTimeblocksDataByWeekday(timeblocksData).map(
          ({ weekday, timeblocks }) => (
            <div key={weekday} className="flex items-center gap-4">
              <Time className="w-5 h-5" />
              <div className="flex flex-col">
                <dl>
                  <strong>{intl.WEEKDAYS[weekday as WEEKDAY]}</strong>
                </dl>
                <dd>
                  {timeblocks
                    .map((timeblock) =>
                      formatTimeInterval(
                        timeblock.from,
                        timeblock.to,
                        "h",
                        " - ",
                      ),
                    )
                    .join(", eller ")}
                </dd>
              </div>
            </div>
          ),
        )}
      </dl>
      {showCTA && (
        <div className="flex justify-center">
          <Link href={BRAND_PATHS.SIGNUP_HREF}>
            <Button IconRight={ChevronRight} primary>
              {intl.GET_STARTED}
            </Button>
          </Link>
        </div>
      )}
    </Card>
  );
};
