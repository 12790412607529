import { useState } from "react";

import { Button, Snackbar } from "@chef/components";

import { useConfirmDialog } from "@chef/hooks";
import { Trash } from "@chef/icons/small";
import {
  showNotification,
  useUpdateBillingAgreementMutation,
} from "@chef/state-management";
import { ACCOUNT_STATUSES, BRAND_NAME_FULL } from "@chef/constants";
import { useTrack } from "@chef/state-management/hooks";

import { intl } from "./DeleteAccountButton.Intl";

export const DeleteAccountButton = () => {
  const track = useTrack({ serverSide: true });
  const [updateBillingAgreementMutation] = useUpdateBillingAgreementMutation();

  const { isShowing, handleShow, handleConfirm, handleCancel } =
    useConfirmDialog();

  const [accountDeletionRequestSubmitted, setAccountDeletionRequestSubmitted] =
    useState(false);

  const handleSubmitAccountDeletionRequest = () => {
    handleShow(async () => {
      await track("accountDeletionRequested", {
        affiliation: "Frontend process",
      });

      await updateBillingAgreementMutation({
        status: ACCOUNT_STATUSES.PAUSED,
      }).unwrap();

      showNotification({
        type: "warn",
        message: intl.YOUR_REQUEST_HAS_BEEN_RECEIVED,
      });

      setAccountDeletionRequestSubmitted(true);
    });
  };

  return (
    <Snackbar error showIcon={false} className="mt-4">
      <p>
        <strong>
          {intl.DELETE_ACCOUNT_AND_ALL_YOUR_DATA_FROM} {BRAND_NAME_FULL}
        </strong>
      </p>

      <p className="my-4 text-sm italic">{intl.FULL_DISCLAIMER}</p>

      {isShowing ? (
        <div className="flex gap-2 mt-4">
          <Button
            onClick={handleConfirm}
            className="grow"
            error={!accountDeletionRequestSubmitted}
            primary
            tiny
          >
            {intl.CONFIRM_DELETION}
          </Button>
          <Button onClick={handleCancel} className="grow" outlined tiny>
            {intl.CANCEL}
          </Button>
        </div>
      ) : (
        <Button
          IconLeft={Trash}
          disabled={accountDeletionRequestSubmitted}
          onClick={handleSubmitAccountDeletionRequest}
          className="mt-4"
          error
          outlined
          tiny
          id="delete-account-btn"
        >
          {intl.DELETE_ACCOUNT}
        </Button>
      )}
    </Snackbar>
  );
};
