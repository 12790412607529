import * as React from "react";
import { SVGProps } from "react";
const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    fill="none"
    {...props}
  >
    <path
      d="M9.66671 7H7.33337V3.66667M1.33337 7C1.33337 7.78793 1.48857 8.56815 1.7901 9.2961C2.09162 10.0241 2.53358 10.6855 3.09073 11.2426C3.64788 11.7998 4.30932 12.2417 5.03727 12.5433C5.76523 12.8448 6.54544 13 7.33337 13C8.12131 13 8.90152 12.8448 9.62947 12.5433C10.3574 12.2417 11.0189 11.7998 11.576 11.2426C12.1332 10.6855 12.5751 10.0241 12.8767 9.2961C13.1782 8.56815 13.3334 7.78793 13.3334 7C13.3334 6.21207 13.1782 5.43185 12.8767 4.7039C12.5751 3.97595 12.1332 3.31451 11.576 2.75736C11.0189 2.20021 10.3574 1.75825 9.62947 1.45672C8.90152 1.15519 8.12131 1 7.33337 1C6.54544 1 5.76523 1.15519 5.03727 1.45672C4.30932 1.75825 3.64788 2.20021 3.09073 2.75736C2.53358 3.31451 2.09162 3.97595 1.7901 4.7039C1.48857 5.43185 1.33337 6.21207 1.33337 7Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Clock;
