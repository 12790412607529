import { useContext } from "react";

import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext } from "react";

import { Event } from "@chef/events";

interface AnalyticsContextValue {
  analytics: AnalyticsBrowser | undefined;
  queue: Event[];
}

export const AnalyticsContext = createContext<AnalyticsContextValue | null>(
  null,
);

export const useAnalytics = (overrideThrow = false) => {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    // NOTE: this shouldn't ever be used -- needed for datadog temporarily
    // TODO RTK: remove once all brands are on RTK
    if (overrideThrow) {
      return null;
    }
    throw new Error("useAnalytics used outside of TrackingProvider");
  }

  return ctx.analytics;
};
