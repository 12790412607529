import { useRef } from "react";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import Link from "next/link";

import { selectNotificationsQueue } from "@chef/state-management";

import Notification from "./Notification";

interface NotificationsProps {
  limit?: number;
  position?: // | "top-left"
  "top-center";
  // | "top-right"
  // | "bottom-left"
  // | "bottom-center"
  // | "bottom-right";
}

export const Notifications = ({
  limit = 5,
  position = "top-center",
}: NotificationsProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const queue = useSelector(selectNotificationsQueue);

  return (
    <aside
      className={clsx(
        "fixed flex flex-col gap-2 z-max inset-4 md:inset-[unset] pointer-events-none",
        {
          // "top-right": "md:top-4 md:right-4",
          "top-center": "md:top-4 md:left-1/2 md:transform md:-translate-x-1/2",
          // "top-left": "md:top-4 md:left-4",
          // "bottom-right": "md:bottom-4 md:right-4",
          // "bottom-center":
          //   "md:bottom-4 md:left-1/2 md:transform md:-translate-x-1/2",
          // "bottom-left": "md:bottom-4 md:left-4",
        }[position],
      )}
      ref={containerRef}
    >
      <AnimatePresence mode="popLayout">
        {queue.slice(0, limit).map((notification) => {
          const { id, message, title, links, ...rest } = notification;

          return (
            <motion.div
              key={id}
              layoutId={`notification-${id}`}
              initial={{
                y: -300,
              }}
              animate={{ y: 0 }}
              exit={{
                y: -300,
              }}
            >
              <Notification
                {...rest}
                notificationId={id}
                className="pointer-events-auto print:hidden"
                hideIcon
              >
                {title && (
                  <div>
                    <strong>{title}</strong>
                  </div>
                )}
                <div>{message}</div>
                {links && (
                  <div className="flex flex-col gap-2 mt-2">
                    {links.map((link) =>
                      link.external ? (
                        <a
                          key={link.label}
                          href={link.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          {link.label}
                        </a>
                      ) : (
                        <Link
                          key={link.label}
                          href={link.href}
                          className="underline"
                        >
                          {link.label}
                        </Link>
                      ),
                    )}
                  </div>
                )}
              </Notification>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </aside>
  );
};
