import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      INPUT_YOUR_POSTAL_CODE: "Skriv inn ditt postnummer",
      CHECKING_POSTAL_CODE: "Sjekker postnummer...",
      POSTAL_CODE_LENGTH: 4,
      SORRY: "Beklager!",
      WE_CANNOT_DELIVER_TO: (postalArea: string) =>
        `Vi kan dessverre ikke levere til ${postalArea}`,
      OF_COURSE_WE_DELIVER_TO: (postalArea: string) =>
        `Selvfølgelig leverer vi til ${postalArea}!`,
      GET_STARTED: "Kom i gang",
      WEEKDAYS: {
        0: "Søndager",
        1: "Mandager",
        2: "Tirsdager",
        3: "Onsdager",
        4: "Torsdager",
        5: "Fredager",
        6: "Lørdager",
      },
      WHY_WE_NEED_FULL_ADDRESS:
        "I noen områder leverer vi kun til enkelte adresser, og trenger derfor din fulle adresse for å vite om vi leverer til deg eller ikke.",
    },
    se: {
      INPUT_YOUR_POSTAL_CODE: "Skriv in ditt postnummer",
      CHECKING_POSTAL_CODE: "Kontrollerar postnummer...",
      POSTAL_CODE_LENGTH: 5,
      SORRY: "Beklagar",
      WE_CANNOT_DELIVER_TO: (postalArea: string) =>
        `Vi kan tyvärr inte leverera till ${postalArea}`,
      OF_COURSE_WE_DELIVER_TO: (postalArea: string) =>
        `Självklart levererar vi till ${postalArea}!`,
      GET_STARTED: "Komma igång",
      WEEKDAYS: {
        0: "Söndagar",
        1: "Måndagar",
        2: "Tisdagar",
        3: "Onsdagar",
        4: "Torsdagar",
        5: "Fredagar",
        6: "Lördagar",
      },
      WHY_WE_NEED_FULL_ADDRESS:
        "I några områden levererar vi endast till vissa adresser och behöver därför din fullständiga adress för att veta om vi levererar till dig eller inte.",
    },
    dk: {
      INPUT_YOUR_POSTAL_CODE: "Indtast dit postnummer",
      CHECKING_POSTAL_CODE: "Kontrollerer dit postnummer...",
      POSTAL_CODE_LENGTH: 4,
      SORRY: "Beklager",
      WE_CANNOT_DELIVER_TO: (postalArea: string) =>
        `Vi kan desværre ikke levere til ${postalArea}`,
      OF_COURSE_WE_DELIVER_TO: (postalArea: string) =>
        `Selvfølgelig leverer vi til ${postalArea}!`,
      GET_STARTED: "Kom igang",
      WEEKDAYS: {
        0: "Søndag",
        1: "Mandag",
        2: "Tirsdag",
        3: "Onsdag",
        4: "Torsdag",
        5: "Fredag",
        6: "Lørdag",
      },
      WHY_WE_NEED_FULL_ADDRESS:
        "I nogle områder leverer vi kun til visse adresser og har derfor brug for din fulde adresse for at vide, om vi leverer til dig eller ej.",
    },
  } as const
)[language];
