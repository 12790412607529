import { invariant } from "@chef/utils/invariant";
import { nonNullable } from "@chef/utils/nonNullable";
import { ERROR_CODES, ErrorCode, language } from "@chef/constants";

import type {
  PickAndMixQuery,
  ProductsByCategoriesQuery,
} from "../graphql/generated";
import type { IDeviationBasket, IDeviationProduct } from "../features/basket";
import {
  getAttribute,
  isAddonProduct,
  isLimitedQuantityProduct,
  isMealboxProduct,
  isPickAndMixProduct,
  isStandaloneProduct,
} from "../helpers";
import { showNotification } from "../features";

const intl = (
  {
    no: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Noe uventet gikk galt. Kontakt support om problemet vedvarer",
    },

    se: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Något gick oväntat fel. Kontakta kundsupport om problemet kvarstår",
    },

    dk: {
      SOMETHING_UNEXPECTED_WENT_WRONG:
        "Noget gik uventet galt. Kontakt kundesupport, hvis problemet fortsætter",
    },
  } as const
)[language];

type PickAndMixQueryProduct = PickAndMixQuery["pickAndMix"][0]["product"];
type PickAndMixQueryVariation =
  PickAndMixQuery["pickAndMix"][0]["product"]["variations"][0];

type ProductsByCategoriesQueryProduct =
  ProductsByCategoriesQuery["productsByCategories"]["0"]["products"]["0"];
type ProductsByCategoriesQueryVariation =
  ProductsByCategoriesQuery["productsByCategories"]["0"]["products"]["0"]["variations"]["0"];

export const findDifferentProductsInDeviations = (
  a: IDeviationBasket,
  b: IDeviationBasket,
) => {
  const aProducts = a.products.filter((p) => isPickAndMixProduct(p));

  const bProducts = b.products.filter((p) => isPickAndMixProduct(p));

  const results: {
    type: "removed" | "added";
    productId: string;
    variationId: string;
    productTypeId: string;
    quantity: number;
    price: number;
  }[] = [];

  for (const aProduct of aProducts) {
    const bProduct = bProducts.find(
      (p) =>
        p.productId === aProduct.productId &&
        p.variationId === aProduct.variationId,
    );

    if (!bProduct) {
      results.push({
        type: "removed",
        productId: aProduct.productId,
        variationId: aProduct.variationId,
        productTypeId: aProduct.productTypeId,
        quantity: aProduct.quantity,
        price: aProduct.price,
      });
      continue;
    }

    if (aProduct.quantity < bProduct.quantity) {
      results.push({
        type: "added",
        productId: aProduct.productId,
        variationId: aProduct.variationId,
        productTypeId: aProduct.productTypeId,
        quantity: bProduct.quantity - aProduct.quantity,
        price: aProduct.price,
      });
    }

    if (aProduct.quantity > bProduct.quantity) {
      results.push({
        type: "removed",
        productId: aProduct.productId,
        variationId: aProduct.variationId,
        productTypeId: aProduct.productTypeId,
        quantity: aProduct.quantity - bProduct.quantity,
        price: aProduct.price,
      });
    }

    if (aProduct.quantity === bProduct.quantity) {
      continue;
    }
  }

  for (const bProduct of bProducts) {
    const aProduct = aProducts.find(
      (p) =>
        p.productId === bProduct.productId &&
        p.variationId === bProduct.variationId,
    );

    if (!aProduct) {
      results.push({
        type: "added",
        productId: bProduct.productId,
        variationId: bProduct.variationId,
        productTypeId: bProduct.productTypeId,
        quantity: bProduct.quantity,
        price: bProduct.price,
      });

      continue;
    }
  }

  return results;
};

export const deriveProductPropertyFromState = <
  T extends keyof (PickAndMixQueryProduct | ProductsByCategoriesQueryProduct),
>(
  property: T,
  productId: string,
  products: PickAndMixQuery | ProductsByCategoriesQuery,
) => {
  if ("pickAndMix" in products) {
    const obj = products.pickAndMix.find((p) => p.productId === productId);

    invariant(obj, `Product ${productId} not found`);

    return obj.product[property];
  } else {
    const obj = products.productsByCategories
      .flatMap((p) => p.products)
      .find((p) => p.productId === productId);

    invariant(obj, `Product ${productId} not found`);

    return obj[property];
  }
};

export const deriveVariationPropertyFromState = <
  T extends keyof (
    | PickAndMixQueryVariation
    | ProductsByCategoriesQueryVariation
  ),
>(
  property: T,
  productId: string,
  variationId: string,
  products: PickAndMixQuery | ProductsByCategoriesQuery,
) => {
  if ("pickAndMix" in products) {
    const obj = products.pickAndMix.find((p) => p.productId === productId);

    invariant(obj, `Product ${productId} not found`);

    const variation = obj.product.variations.find(
      (v) => v.variationId === variationId,
    );

    invariant(variation, `Variation ${variationId} not found`);

    return variation[property];
  } else {
    const obj = products.productsByCategories
      .flatMap((p) => p.products)
      .find((p) => p.productId === productId);

    invariant(obj, `Product ${productId} not found`);

    const variation = obj.variations.find((v) => v.variationId === variationId);

    invariant(variation, `Variation ${variationId} not found`);

    return variation[property];
  }
};

export const deriveProductPropertiesFromState = <
  T extends keyof (PickAndMixQueryProduct | ProductsByCategoriesQueryProduct),
>(
  properties: T[],
  productId: string,
  products: PickAndMixQuery | ProductsByCategoriesQuery,
) => {
  return properties.map((p) =>
    deriveProductPropertyFromState(p, productId, products),
  );
};

export const deriveVariationPropertiesFromState = <
  T extends keyof (
    | PickAndMixQueryVariation
    | ProductsByCategoriesQueryVariation
  ),
>(
  properties: T[],
  productId: string,
  variationId: string,
  products: PickAndMixQuery | ProductsByCategoriesQuery,
) => {
  return properties.map((p) =>
    deriveVariationPropertyFromState(p, productId, variationId, products),
  );
};

export const createPayloadProductsArray = (
  products?: IDeviationProduct[],
  pickAndMix?: PickAndMixQuery["pickAndMix"],
  productsByCategories?: ProductsByCategoriesQuery["productsByCategories"],
) => {
  if (!products || (!pickAndMix && !productsByCategories)) {
    console.error(
      "createPayloadProductsArray called with missing products, pickAndMix or productsByCategories. This impacts the quality of our tracking",
      {
        products,
        pickAndMix,
        productsByCategories,
      },
    );
    return;
  }

  const meals = products.filter(isPickAndMixProduct).length;

  return products
    .map((p) => {
      if (pickAndMix && isPickAndMixProduct(p)) {
        const [category, name] = deriveProductPropertiesFromState(
          ["productTypeName", "name"],
          p.productId,
          { pickAndMix },
        );

        const variant = deriveVariationPropertyFromState(
          "name",
          p.productId,
          p.variationId,
          { pickAndMix },
        );

        const attributes = deriveVariationPropertyFromState(
          "attributes",
          p.productId,
          p.variationId,
          { pickAndMix },
        );

        const portions = +(getAttribute("Portions", { attributes }) || -1);

        return {
          category,
          category_id: p.productTypeId,
          product_id: p.productId,
          price: p.price,
          quantity: p.quantity,
          variant,
          variant_id: p.variationId,
          meals,
          name,
          portions,
        };
      }

      if (
        productsByCategories &&
        (isMealboxProduct(p) ||
          isStandaloneProduct(p) ||
          isAddonProduct(p) ||
          isLimitedQuantityProduct(p))
      ) {
        const [category, name] = deriveProductPropertiesFromState(
          ["productTypeName", "name"],
          p.productId,
          { productsByCategories },
        );

        const variant = deriveVariationPropertyFromState(
          "name",
          p.productId,
          p.variationId,
          { productsByCategories },
        );

        const attributes = deriveVariationPropertyFromState(
          "attributes",
          p.productId,
          p.variationId,
          { productsByCategories },
        );

        const portions = +(getAttribute("Portions", { attributes }) || -1);

        return {
          category,
          category_id: p.productTypeId,
          product_id: p.productId,
          price: p.price,
          quantity: p.quantity,
          variant,
          variant_id: p.variationId,
          meals,
          name,
          portions,
        };
      }

      return null;
    })
    .filter(nonNullable);
};

export const showErrorNotification = (action: any) => {
  if (action["meta"].requestStatus === "rejected") {
    const errorCode = action["payload"].data?.ErrorCode;
    const errorObj =
      errorCode !== undefined ? ERROR_CODES[errorCode as ErrorCode] : undefined;

    const error = errorObj
      ? errorObj.userMessage
      : intl.SOMETHING_UNEXPECTED_WENT_WRONG;

    showNotification({
      type: "error",
      message: error,
    });
  }
};
