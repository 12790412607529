import type { Step } from "./types";
import { Logo } from "../Logo";
import { StepsVariantComponent } from "./StepsComponent";

type StepsVariantProps = {
  steps?: Step[];
  current?: number;
  title?: string;
};

export const SignupVariant = ({
  steps = [],
  title,
  current = 1,
}: StepsVariantProps) => {
  return (
    <div className="flex flex-col items-center justify-between pb-3 lg:py-3 lg:flex-row ">
      <div className="flex flex-col items-center justify-center py-3 lg:py-0">
        <Logo href="/" />
      </div>
      <StepsVariantComponent steps={steps} title={title} current={current} />
      <div aria-hidden="true" className="w-48" />
    </div>
  );
};
