import { BRAND_NAME } from "@chef/constants";
import { RecipeAndStepsQuery } from "@chef/state-management";

export const formatPortionsIngredientSections = (
  portions: RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"],
) => {
  if (!portions) {
    return [];
  }
  return [...portions]
    .sort((a, b) => {
      if (a.size < b.size) {
        return -1;
      }

      if (a.size > b.size) {
        return 1;
      }
      return 0;
    })
    .map((portion) => {
      return {
        ...portion,
        ingredientSections: [
          ...portion.ingredientSections.filter((s) =>
            s.ingredients.some((i) => !i.isBasis),
          ),
          {
            sectionId: "basis", //TODO: uuid ?
            sectionTitle: intl.BASIS_SECTION_TITLE,
            isBasisArray: true,
            ingredients: [
              ...portion.ingredientSections
                .flatMap((s) => s.ingredients)
                .filter((i) => i.isBasis)
                .map((i) => i.name),
            ],
          },
        ],
      };
    });
};

export const splitRecipeStepString = (step: string) => {
  const splittedStepString = step.split(":");

  const hasHeading = splittedStepString.length > 1;

  const heading = hasHeading ? splittedStepString[0].replace(":", "") : "";
  const description = hasHeading
    ? splittedStepString.slice(1).join(":")
    : splittedStepString[0];

  return { heading, description };
};
const MINUTES_IN_HOUR = 60;
const ROUNDING_THRESHOLD = 30;

export const getCookingTime = (cookingTimeMax: number): string => {
  if (typeof cookingTimeMax !== "number") {
    return "";
  }

  const isHours = cookingTimeMax >= MINUTES_IN_HOUR;

  if (isHours) {
    const hours = Math.floor(cookingTimeMax / MINUTES_IN_HOUR);
    const remainingMinutes = cookingTimeMax % MINUTES_IN_HOUR;
    const roundedHours =
      remainingMinutes >= ROUNDING_THRESHOLD ? hours + 1 : hours;
    const hourLabel = roundedHours > 1 ? intl.HOURS : intl.HOUR;

    return `${roundedHours} ${hourLabel}`;
  } else {
    return `${cookingTimeMax} ${intl.MINUTES}`;
  }
};

const intl = {
  AMK: {
    BASIS_SECTION_TITLE: "Du må selv ha",
    MINUTES: "min",
    HOUR: "time",
    HOURS: "timer",
  },
  GL: {
    BASIS_SECTION_TITLE: "Du må selv ha",
    MINUTES: "min",
    HOUR: "time",
    HOURS: "timer",
  },
  LMK: {
    BASIS_SECTION_TITLE: "Du måste ha detta själv",
    MINUTES: "min",
    HOUR: "timme",
    HOURS: "timmar",
  },
  RN: {
    BASIS_SECTION_TITLE: "Du skal selv have",
    MINUTES: "min",
    HOUR: "time",
    HOURS: "timer",
  },
}[BRAND_NAME];

const isPlusPortion = (portionSize: string): boolean =>
  portionSize.endsWith("+");

export const getPlusPortionString = (portionSizeString: string): string => {
  return isPlusPortion(portionSizeString) ? " (PLUS)" : "";
};
