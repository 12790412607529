import clsx from "clsx";

import { Check, ChevronRight } from "@chef/icons/small";

import type { Step } from "./types";

interface StepComponentProps {
  n: number;
  /**
   * <0: done
   * 0: current
   * >0: next
   */
  pos: number;
  children: React.ReactNode;
}

const DesktopStepComponent = ({ n, pos, children }: StepComponentProps) => {
  return (
    <div className="relative flex items-center gap-2 group">
      <svg width="24" height="24" className="absolute">
        <circle
          cx="12"
          cy="12"
          r="10"
          strokeWidth={2}
          className={clsx(
            pos < 1 ? "fill-primary" : "fill-white stroke-primary",
          )}
        />
        {pos < 0 ? (
          <Check y="7" x="7" className="text-white text-xxs" />
        ) : (
          <text
            className={clsx("text-xs", pos < 1 ? "fill-white" : "fill-black")}
            y="16"
            x="9"
          >
            {n}
          </text>
        )}
      </svg>
      <span className="ml-8 text-sm">{children}</span>
      <ChevronRight className="text-xs text-grey-1 group-last:hidden" />
    </div>
  );
};

const MobileStepComponent = ({
  pos,
  children,
}: Omit<StepComponentProps, "n">) => {
  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          "w-full h-1 rounded",
          pos < 1 ? "bg-primary" : "bg-grey-2",
        )}
      />
      <div
        className={clsx(pos > 0 && "text-grey-1", "mt-1 text-xxs text-center")}
      >
        {children}
      </div>
    </div>
  );
};

export const StepsVariantComponent = ({
  steps = [],
  title,
  current = 1,
}: {
  steps: Step[];
  title: string | undefined;
  current: number;
}) => {
  return (
    <>
      <div className="hidden gap-4 xl:flex">
        {steps &&
          steps.map(({ title }, index) => (
            <DesktopStepComponent
              key={title}
              n={index + 1}
              pos={index + 1 - current}
            >
              {title}
            </DesktopStepComponent>
          ))}
        {title && <p>{title}</p>}
      </div>

      <div className="relative flex gap-3 xl:hidden">
        {steps &&
          steps.map(({ title }, index) => (
            <MobileStepComponent key={title} pos={index + 1 - current}>
              {title}
            </MobileStepComponent>
          ))}
        {title && <p>{title}</p>}
      </div>
    </>
  );
};
