import { useMemo } from "react";
import { endOfWeek, isToday, startOfWeek } from "date-fns";
import clsx from "clsx";

import { getNextCutoffs } from "@chef/helpers";
import { capitalize } from "@chef/utils/string";
import { isNotEmptyArray } from "@chef/utils/array";
import { isEqualStrings } from "@chef/utils/equal";
import { normalizeDate } from "@chef/utils/time";

import { locales } from "./DatePicker.Intl";

type DatePickerProps = DatePickerBaseProps &
  (DatePickerSelectDateProps | DatePickerCalendarViewProps);

type DatePickerBaseProps = {
  className?: string;
};

type DatePickerSelectDateProps = {
  availableDays: string[];
  onChange: (day: string) => void;
  value: string | undefined;
};

type DatePickerCalendarViewProps = {
  availableDays?: never;
  onChange?: never;
  value?: never;
};

const OPTIONS = {
  locale: locales,
  weekStartsOn: 1,
} as const;

/**
 * @returns DatePicker component based on the next 4 cutoffs
 * @param availableDays - Array of datestrings that are available to be selected. Defaults to empty array.
 * @param onChange - Callback function that returns the selected datestring
 * @param value - Datestring that is selected
 * @param className - styling of the outer container of the component
 */
export const DatePicker = ({
  className,
  availableDays = [],
  onChange,
  value,
}: DatePickerProps) => {
  const cutoffs = getNextCutoffs(4);

  const weeksWithDays = useMemo(
    () =>
      cutoffs.map((cutoff) => {
        const firstDay = startOfWeek(new Date(cutoff.date), OPTIONS);

        return Array.from({ length: 7 }).map((_, i) => {
          return new Date(new Date(firstDay).setDate(firstDay.getDate() + i));
        });
      }),
    [cutoffs],
  );

  const weekDays = useMemo(
    () =>
      weeksWithDays[0].map((day) => {
        return {
          long: day.toLocaleDateString(locales.code, { weekday: "long" }),
          short: day.toLocaleDateString(locales.code, { weekday: "short" }),
        };
      }),
    [weeksWithDays],
  );

  const monthsLabel = useMemo(() => {
    const months: Set<string> = new Set();
    cutoffs.map((cutoff, i) => {
      const cutoffDate = new Date(cutoff.date);
      let date: Date;
      if (i === 0) {
        date = startOfWeek(cutoffDate, OPTIONS);
      } else {
        date = endOfWeek(cutoffDate, OPTIONS);
      }
      months.add(
        capitalize(date.toLocaleDateString(locales.code, { month: "long" })),
      );
    });
    const [f, ...rest] = Array.from(months);
    return isNotEmptyArray(rest) ? `${f} - ${rest.pop()}` : f;
  }, [cutoffs]);

  const handleDateChange = (date: Date) => {
    if (!onChange) {
      return;
    }
    const selectedDate = availableDays.find((day) =>
      isEqualStrings(normalizeDate(day), normalizeDate(date)),
    );
    if (!selectedDate) {
      return;
    }

    onChange(selectedDate);
  };

  const isSelected = (day: Date) => {
    if (!value) {
      return false;
    }
    return isEqualStrings(normalizeDate(value), normalizeDate(day));
  };

  const normalizedAvailableDays = useMemo(
    () => availableDays.map((day) => normalizeDate(day)),
    [availableDays],
  );

  const isDayAvailable = (day: Date) =>
    normalizedAvailableDays.includes(normalizeDate(day));

  return (
    <div className={className}>
      <div className="max-w-fit">
        <p className="mb-4 text-base text-center">
          <strong>{monthsLabel}</strong>
        </p>
        <table className="w-full border-collapse">
          <thead aria-hidden="true">
            <tr className="flex w-full gap-2 mb-5 grow">
              {weekDays.map((day) => (
                <th
                  key={day.long}
                  aria-label={day.long}
                  className="w-full text-sm font-medium uppercase"
                >
                  {day.short}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="flex flex-col gap-2">
            {weeksWithDays.map((week, i) => (
              <tr key={i} className="flex w-full gap-2">
                {week.map((day) => (
                  <td key={day.toISOString()} className="text-center w-9 h-9">
                    <button
                      className={clsx(
                        "inline-flex items-center justify-center w-full h-full text-base rounded-full border-solid border-1.5 border-secondary text-secondary",
                        {
                          "border-transparent !text-grey-1":
                            !isDayAvailable(day),
                          "bg-secondary text-white": isSelected(day),
                          "bg-grey-3": isToday(day),
                        },
                      )}
                      type="button"
                      aria-label={day.toLocaleDateString(locales.code, {
                        dateStyle: "full",
                      })}
                      disabled={!isDayAvailable(day)}
                      onClick={() => handleDateChange(day)}
                    >
                      <strong className="pt-px pl-px">{day.getDate()}</strong>
                    </button>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
