import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
} from "@reduxjs/toolkit/query/react";
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { DocumentNode } from "graphql";
import { HYDRATE } from "next-redux-wrapper";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "@chef/helpers";

import { isBrowser, isNode } from "@chef/utils/runtime";

import { getAgreementId } from "./user";
import { getLoginCookieToken } from "../util";

const getAuthorization = () => {
  const token = getLoginCookieToken();

  if (!token) {
    return "";
  }

  return `Bearer ${token}`;
};

const createHeaders = () => {
  const headers: HeadersInit = {};

  if (typeof window !== "undefined") {
    headers["x-current-url"] = window.location.href;
  }

  const agreementId = getAgreementId();

  if (agreementId) {
    headers["x-agreement-id"] = agreementId.toString();
  }

  headers["apollographql-client-name"] = process.env.NX_APP_NAME;
  headers["apollographql-client-version"] = `${process.env.NX_APP_VERSION}-${
    isNode() ? "server" : "client"
  }`;

  return headers;
};

const link = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

export interface GraphQLClientQuery {
  document: string | DocumentNode;
  variables?: any;
}

export interface ExtraOptionsArgs {
  companyId?: string;
  language?: string;
  agreementId?: boolean;
  anonymous?: boolean;
}

const customBaseQuery = (): BaseQueryFn<
  GraphQLClientQuery | string | FetchArgs,
  unknown,
  unknown,
  ExtraOptionsArgs
> => {
  const uri = new URL("/graphql", process.env.GRAPHV4_URL).toString();
  const retryLink = new RetryLink();

  const anonymousLink = link.concat(retryLink).concat(
    new HttpLink({
      uri,
      headers: createHeaders(),
    }),
  );

  const privateLink = link.concat(retryLink).concat(
    new HttpLink({
      uri,
      headers: { ...createHeaders(), authorization: getAuthorization() },
    }),
  );

  const client = new ApolloClient({
    ssrMode: !isBrowser(),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });

  const fetchClient = fetchBaseQuery({ baseUrl: "/api" });

  return async (args, api, extraOptions) => {
    const createFetchHeaders = () => {
      if (extraOptions?.anonymous) {
        return {};
      }

      return { Authorization: getAuthorization() };
    };

    if (typeof args === "string") {
      if (isNode()) {
        console.warn(
          "fetchClient was initiated on the server, but this is not supported because it does not know the base URL",
        );
      }

      return fetchClient(
        { url: args, headers: createFetchHeaders() },
        api,
        extraOptions,
      );
    }

    if ("url" in args && args.url) {
      if (isNode()) {
        console.warn(
          "fetchClient was initiated on the server, but this is not supported because it does not know the base URL",
        );
      }

      return fetchClient(
        { ...args, headers: createFetchHeaders() },
        api,
        extraOptions,
      );
    }

    // this case should be impossible -- just doing it so TS doesn't complain
    if (!("document" in args)) {
      throw new Error("Missing document");
    }

    // we supply companyId here to the variables so we don't need to pass it for every request
    if (extraOptions?.companyId) {
      args.variables = {
        ...args.variables,
        companyId: extraOptions.companyId,
      };
    }

    // we supply language here to the variables so we don't need to pass it for every request
    if (extraOptions?.language) {
      args.variables = {
        ...args.variables,
        language: extraOptions.language,
      };
    }

    // we supply agreementId here to the variables so we don't need to pass it for every request
    if (extraOptions?.agreementId) {
      const agreementId = getAgreementId();

      if (!agreementId && process.env.NODE_ENV === "development") {
        console.warn(
          "agreementId is not set when trying to call an endpoint requiring it",
        );
      }

      args.variables = {
        ...args.variables,
        agreementId,
      };
    }

    let response;

    if (extraOptions?.anonymous) {
      client.setLink(anonymousLink);

      response = await client.query({
        variables: args.variables,
        query: gql(args.document as string),
      });
    } else {
      client.setLink(privateLink);

      response = await client.query({
        variables: args.variables,
        query: gql(args.document as string),
      });
    }

    if (response.errors) {
      return {
        data: undefined,
        error: response.errors,
      };
    }

    return {
      data: response.data,
      error: undefined,
    };
  };
};

const hydrateListeners: ((data: any) => void)[] = [];
export const listenToHydrate = (cb: (data: any) => void) => {
  hydrateListeners.push(cb);
};

export const query = customBaseQuery();
export const api = createApi({
  baseQuery: query,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      for (const cb of hydrateListeners) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cb(action.payload[reducerPath]);
      }

      // not sure why "payload" doesn't exist on the type, but it exists in reality
      // https://redux-toolkit.js.org/rtk-query/usage/server-side-rendering
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return action.payload[reducerPath];
    }
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 3600,
});
