import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

import { ButtonCircle } from "@chef/components";
import { ChevronLeft, ChevronRight } from "@chef/icons/small";

interface StepperPaginationProps {
  page: number;
  total: number;
  className?: string;
}

interface NavButtonProps {
  href: string;
  disabled: boolean;
}

const createNextPageHref = (path: string, page: number) => {
  // Check if path already has a page number
  const pageMatch = path.match(/\/\d+$/);

  if (pageMatch) {
    return path.replace(/\/\d+$/, `/${page}`);
  }

  return `${path}/${page}`;
};

const createPreviousPageHref = (path: string, page: number) => {
  // Check if path already has a page number
  const pageMatch = path.match(/\/\d+$/);

  if (pageMatch) {
    return path.replace(/\/\d+$/, `/${page}`);
  }

  return `${path}/${page}`;
};

const PrevButton = ({ href, disabled }: NavButtonProps) => {
  if (disabled) {
    return (
      <ButtonCircle
        outlined
        Icon={() => <ChevronLeft className={clsx("text-primary opacity-30")} />}
        disabled
      />
    );
  }

  return (
    <Link href={href}>
      <ButtonCircle
        outlined
        Icon={() => <ChevronLeft className="text-primary" />}
      />
    </Link>
  );
};

const NextButton = ({ href, disabled }: NavButtonProps) => {
  if (disabled) {
    return (
      <ButtonCircle
        outlined
        Icon={() => (
          <ChevronRight className={clsx("text-primary opacity-30")} />
        )}
        disabled
      />
    );
  }

  return (
    <Link href={href}>
      <ButtonCircle
        outlined
        Icon={() => <ChevronRight className="text-primary" />}
      />
    </Link>
  );
};

export const StepperPagination = ({
  page,
  total,
  className,
}: StepperPaginationProps) => {
  const router = useRouter();

  const prevPage = Math.max(1, page - 1);
  const nextPage = Math.min(total, page + 1);

  const isPrevDisabled = page === 1;
  const isNextDisabled = page === total;

  const nextPath = createNextPageHref(router.asPath, nextPage);
  const prevPath = createPreviousPageHref(router.asPath, prevPage);

  if (page <= 0 || page > total) {
    return null;
  }

  return (
    <div
      className={clsx(className, "flex items-center justify-center gap-4 mt-8")}
    >
      <PrevButton href={prevPath} disabled={isPrevDisabled} />
      <span className="text-primary">{page}</span>
      <NextButton href={nextPath} disabled={isNextDisabled} />
    </div>
  );
};
