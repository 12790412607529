import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment } from "react";
import { useRouter } from "next/router";

import { Button, Checkbox, Skeleton } from "@chef/components";
import {
  BillingQuery,
  useBillingQuery,
  useUpdateUserConsentsMutation,
} from "@chef/state-management";
import {
  isAccountAcceptedAllergenStoring,
  isAccountAcceptedDataProcessing,
} from "@chef/state-management/helpers";
import { CONSENT_IDS, BRAND_NAME } from "@chef/constants";
import { STAGE, usePreselector } from "@chef/state-management/hooks";
import {
  getWeek,
  getYear,
  nextDeliveryWeekDateObj,
  slugify,
} from "@chef/helpers";
import { Close } from "@chef/icons/small";

import { PreselectorLoader } from "../PreselectorLoader";

import { intl } from "./ConsentOptions.Intl";

interface ConsentOptionsProps {
  exitPath: string;
}

interface ConsentOptionsFormProps {
  billing: BillingQuery["billing"] | undefined;
  exitPath: string;
}

const schema = yup.object({
  dataProcessing: yup.boolean().required(),
  allergenStoring: yup.boolean().required(),
});

type ConsentFormData = yup.InferType<typeof schema>;

const consents = [
  {
    id: CONSENT_IDS.DATA_PROCESSING[BRAND_NAME],
    label: intl.DATA_PROCESSING_LABEL,
    description: intl.DATA_PROCESSING_DESCRIPTION,
    name: "dataProcessing",
  },
  {
    id: CONSENT_IDS.ALLERGEN_STORING[BRAND_NAME],
    label: intl.ALLERGY_LABEL,
    description: intl.ALLERGY_DESCRIPTION,
    name: "allergenStoring",
  },
] as const;

export const ConsentOptions = ({ exitPath }: ConsentOptionsProps) => {
  const { data: billingQuery, isLoading: isLoadingBilling } = useBillingQuery();

  return (
    <div className="flex flex-col gap-6 p-2">
      <div className="flex flex-col gap-3">
        <h2 className="text-xl">
          <strong>{intl.CONSENT_OPTIONS}</strong>
        </h2>
        <div className="text-base">
          <p>{intl.CONSENTS_DESCRIPTION} </p>
        </div>
      </div>
      {isLoadingBilling && (
        <>
          <Skeleton height={10} />
          <Skeleton height={10} />
        </>
      )}
      {!isLoadingBilling && (
        <ConsentOptionsForm
          billing={billingQuery?.billing}
          exitPath={exitPath}
        />
      )}
    </div>
  );
};

const ConsentOptionsForm = ({ billing, exitPath }: ConsentOptionsFormProps) => {
  const router = useRouter();

  const date = nextDeliveryWeekDateObj();
  const week = getWeek(date);
  const year = getYear(date);

  const { trigger, stage, expire } = usePreselector({
    onStageChange: {
      [STAGE.COMPLETED]: () => {
        expire();
        router.push(exitPath);
      },
      [STAGE.COMPLETED_WITH_ALLERGEN]: () => {
        expire();
        router.push(exitPath);
      },
      [STAGE.FAILED]: () => {
        expire();
        router.push(exitPath);
      },
    },
  });

  const hasAcceptedDataProcessing = isAccountAcceptedDataProcessing(
    billing?.consents,
  );

  const hasAcceptedAllergenStoring = isAccountAcceptedAllergenStoring(
    billing?.consents,
  );

  const { handleSubmit, control, watch } = useForm<ConsentFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      dataProcessing: hasAcceptedDataProcessing,
      allergenStoring: hasAcceptedAllergenStoring,
    },
  });

  const [updateUserConsentsMutation, { isLoading: isMutating }] =
    useUpdateUserConsentsMutation();

  const [dataProcessing, allergenStoring] = watch([
    "dataProcessing",
    "allergenStoring",
  ]);

  const changeConsequences: string[] = [];
  if (hasAcceptedDataProcessing && !dataProcessing) {
    changeConsequences.push(intl.WE_CANNOT_BASE_RECOMMENDATIONS_ON_HISTORY);
  }
  if (hasAcceptedAllergenStoring && !allergenStoring) {
    changeConsequences.push(
      intl.WE_CANNNOT_SAVE_YOUR_ALLERGEN,
      intl.YOU_CAN_BE_RECOMMENDED_DINNERS_WITH_ALLERGENS,
    );
  }

  const onSubmit = async (data: ConsentFormData) => {
    const consentsToUpdate = consents.map((c) => ({
      consentId: c.id,
      isAccepted: data[c.name],
    }));

    try {
      await updateUserConsentsMutation(consentsToUpdate).unwrap();
      trigger({ week, year });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <PreselectorLoader stage={stage} />

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          {consents.map((c) => (
            <Controller
              key={c.id}
              control={control}
              name={c.name}
              render={({ field }) => (
                <Fragment key={c.id}>
                  <hr className="text-grey-2 " />
                  <Checkbox
                    onChange={field.onChange}
                    checked={field.value}
                    className="mb-5"
                  >
                    <div className="text-sm">
                      <strong>{c.label}</strong>
                      <p>{c.description}</p>
                    </div>
                  </Checkbox>
                </Fragment>
              )}
            />
          ))}
        </div>

        {changeConsequences.length > 0 && (
          <div className="flex flex-col gap-3 p-4 rounded bg-errorBG">
            <p className="text-sm">
              <strong>{intl.NOTE_THAT_YOUR_CHANGE_IMPLY}</strong>
            </p>
            {changeConsequences.map((consequence) => (
              <div className="flex gap-2" key={slugify(consequence)}>
                <div className="justify-start w-4">
                  <Close className="mb-2 w-4 p-0.75 text-white rounded-full bg-error" />
                </div>
                <p className="text-sm">{consequence}</p>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col justify-end gap-4 lg:flex-row-reverse lg:self-end">
          <Button primary type="submit" loading={isMutating}>
            {intl.SAVE}
          </Button>
          <Button outlined type="button" href={exitPath} disabled={isMutating}>
            {intl.CANCEL}
          </Button>
        </div>
      </form>
    </>
  );
};
