export const bufferToHex = (buffer: ArrayBuffer) => {
  const view = new DataView(buffer);

  let hexCodes = "";
  for (let index = 0; index < view.byteLength; index += 4) {
    hexCodes += view.getUint32(index).toString(16).padStart(8, "0");
  }

  return hexCodes;
};

const create =
  (algorithm: string) =>
  async (
    buffer: ArrayBuffer | Uint8Array | string,
    { outputFormat = "hex" } = {},
  ) => {
    let data: Uint8Array;

    if (typeof buffer === "string") {
      data = new globalThis.TextEncoder().encode(buffer);
    } else if (buffer instanceof Uint8Array) {
      data = buffer;
    } else {
      data = new Uint8Array(buffer);
    }

    const hash = await crypto.subtle.digest(algorithm, data);

    return outputFormat === "hex" ? bufferToHex(hash) : hash;
  };

export const sha256 = async (
  buffer: ArrayBuffer | Uint8Array | string,
  options = {},
): Promise<string> => {
  const hash = await create("SHA-256")(buffer, options);
  return typeof hash === "string" ? hash : bufferToHex(hash);
};
