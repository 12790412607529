import { useRouter } from "next/router";

import {
  useBillingQuery,
  useCreateOrUpdateMultipleSchedulersMutation,
  useUpdateBillingAgreementMutation,
} from "@chef/state-management";
import { Button, Card } from "@chef/components";
import { Delivery } from "@chef/icons/symbol";
import {
  ACCOUNT_STATUSES,
  BRAND_NAME,
  BRAND_PATHS,
  CONSENT_IDS,
  PRODUCT_TYPE_IDS,
} from "@chef/constants";
import { isEmptyArray, isNotEmptyArray } from "@chef/utils/array";
import {
  STAGE,
  useCalendarData,
  usePreselector,
} from "@chef/state-management/hooks";
import { getNextCutoff } from "@chef/helpers";
import { isEqualStrings } from "@chef/utils/equal";

import { Modal } from "./modal/Modal";

import { brand, intl } from "./AccountReactivationModal.Intl";

interface AccountReactivationModalProps {
  show: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  redirectUrl: string;
  schedulerUpdates?: {
    week: number;
    year: number;
    delivery: boolean;
  }[];
}

export const AccountReactivationModal = ({
  show,
  onClose,
  handleConfirm,
  redirectUrl,
  schedulerUpdates = [],
}: AccountReactivationModalProps) => {
  const router = useRouter();

  const { week, year } = getNextCutoff();

  const { data: billingQuery } = useBillingQuery(undefined, { skip: !show });

  const { calendar } = useCalendarData({
    week,
    year,
    skip: !show,
  });

  const [
    updateBillingAgreement,
    { isLoading: updateBillingAgreementIsLoading },
  ] = useUpdateBillingAgreementMutation();

  const [
    createOrUpdateMultipleSchedulers,
    { isLoading: createOrUpdateMultipleSchedulersisLoading },
  ] = useCreateOrUpdateMultipleSchedulersMutation();

  const { trigger, stage } = usePreselector();

  const hasAllergenPreferences = billingQuery?.billing.preferences.some(
    (p) => p.isAllergen,
  );

  const requiredConsentIds: string[] = [
    CONSENT_IDS.DATA_PROCESSING[BRAND_NAME],
  ];

  if (hasAllergenPreferences) {
    requiredConsentIds.push(CONSENT_IDS.ALLERGEN_STORING[BRAND_NAME]);
  }

  const isLoading =
    updateBillingAgreementIsLoading ||
    createOrUpdateMultipleSchedulersisLoading ||
    (stage > STAGE.IDLE && stage < STAGE.COMPLETED);

  const upcomingDeviationMeals = calendar?.map((c) =>
    c.baskets
      .find((b) => b.isDefault)
      ?.basketDetails.products.filter((p) =>
        isEqualStrings(
          p.variation.product.productTypeId,
          PRODUCT_TYPE_IDS.PICKANDMIX,
        ),
      ),
  );
  const isSomeUpcomingDeviationEmpty =
    upcomingDeviationMeals?.some(isEmptyArray);

  const handleReactivate = async () => {
    if (isSomeUpcomingDeviationEmpty) {
      trigger({
        week,
        year,
        requiredConsentIds,
        overrideDeviations: false,
      });
    }

    if (isNotEmptyArray(schedulerUpdates)) {
      await Promise.all([
        updateBillingAgreement({
          status: ACCOUNT_STATUSES.ACTIVE,
        }).unwrap(),
        createOrUpdateMultipleSchedulers({
          schedulers: schedulerUpdates,
          _suppressNotifications: true,
        }).unwrap(),
      ]);
    } else {
      await updateBillingAgreement({
        status: ACCOUNT_STATUSES.ACTIVE,
      }).unwrap();
    }

    handleConfirm();

    router.push(redirectUrl);
  };

  return (
    <Modal
      name="account-reactivation-modal"
      open={show}
      onClose={onClose}
      closeable={false}
      size="md"
    >
      <Card noPadding className="justify-center p-6 mt-20 text-center md:p-20 ">
        <div className="flex flex-col items-center justify-center text-center xl:col-start-5 col-span-full xl:col-span-4">
          <div className="flex items-center justify-center w-20 h-20 p-2 mb-6 rounded-full  bg-[#F4F4F1]">
            <Delivery className="w-20 h-20" />
          </div>
        </div>
        <h2>
          <strong>{intl.WELCOME_BACK}</strong>
        </h2>
        <p className="mt-2 md:text-l">{brand.NICE_THAT_YOU_ARE_BACK}</p>

        <div className="mt-10">
          <Button
            loading={isLoading}
            onClick={handleReactivate}
            className="w-full md:w-72 md:mb-2"
            primary
          >
            {intl.RESUME_SUBSCRIPTION}
          </Button>
        </div>
        <div className="mt-2">
          <Button
            disabled={isLoading}
            onClick={onClose}
            className="w-full md:w-72"
            outlined
          >
            {intl.CANCEL}
          </Button>

          <div className="mt-8 text-sm mt- text-grey-1">
            <p>
              {brand.BY_RESUMING_I_ACCEPT_THE_CURRENT}
              <a className="underline" href={BRAND_PATHS.TOS_PATH}>
                <strong>{brand.TERMS_AND_CONDITIONS}</strong>
              </a>
            </p>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
