import React, { HTMLAttributes } from "react";
import clsx from "clsx";

import {
  Check,
  Warning,
  Close,
  Loading,
  BulbOutlined,
} from "@chef/icons/small";

interface SnackbarProps {
  showIcon?: boolean;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  nudge?: boolean;
  loading?: boolean;
  closeable?: boolean;
  isClosed?: boolean;
  onClose?: () => void;
  children: React.ReactNode | string;
  className?: string;
  id?: string;
}

export const Snackbar = ({
  showIcon = true,
  success = false,
  warning = false,
  error = false,
  nudge = false,
  loading = false,
  closeable = false,
  isClosed = false,
  onClose = () => null,
  children,
  className,
  id,
  ...props
}: SnackbarProps) => {
  const baseStyle =
    "flex items-center justify-between rounded gap-x-3 shadow-sm relative";

  const iconWrapperBaseStyle =
    "w-10 h-10 flex items-center shrink-0 justify-center rounded-full";

  const typeStyles = {
    default: "",
    success: "bg-informationBG border-1.5 border-information",
    warning: "bg-highlightBG text-black border-1.5 border-highlight",
    error: "bg-errorBG border-1.5 border-error",
    nudge: "bg-background border-1.5 border-grey-2",
  };

  const iconWrapperStyle = {
    default: "",
    error: "bg-error text-white",
    success: "bg-information text-white",
    warning: "text-black",
    nudge: "bg-grey-2",
  };

  const iconTypeStyles = {
    default: "",
    success: "bg-information",
    warning: "bg-highlight text-black",
    error: "bg-error",
    nudge: "bg-grey-2 text-black",
  };

  let type: "default" | "success" | "warning" | "error" | "nudge" = "default";
  let Icon: typeof Check | null = null;

  if (success) {
    type = "success";
    Icon = Check;
  }

  if (warning) {
    type = "warning";
    Icon = Warning;
  }

  if (error) {
    type = "error";
    Icon = Close;
  }

  if (nudge) {
    type = "nudge";
    Icon = BulbOutlined;
  }

  if (loading) {
    Icon = ({ className }: HTMLAttributes<SVGSVGElement>) => (
      <Loading className={clsx("animate-spin h-6 w-6", className)} />
    );
  }

  if (!children || isClosed) {
    return null;
  }

  return (
    <div
      className={clsx(
        baseStyle,
        typeStyles[type],
        showIcon && "p-3.5",
        className,
      )}
      id={id}
      {...props}
    >
      {Icon && showIcon && (
        <div
          className={clsx(
            iconWrapperBaseStyle,
            iconWrapperStyle[type],
            iconTypeStyles[type],
          )}
        >
          <Icon />
        </div>
      )}
      <div
        className={clsx(
          "flex flex-col justify-center flex-grow",
          !showIcon && "px-3.5 py-2",
          closeable && "mr-8",
        )}
      >
        {children}
      </div>
      {closeable && (
        <div className="absolute top-0 right-0 grow-0">
          <button
            aria-label="close"
            className="w-10 h-10 rounded a11y-focus:focus-ring"
            onClick={onClose}
          >
            <Close className="w-3 h-3" />
          </button>
        </div>
      )}
    </div>
  );
};
