import { getNextCutoff, isAccountBadPayer } from "@chef/helpers";
import { toØre } from "@chef/utils/currency";
import { sumByFn } from "@chef/utils/array";
import { BRAND_PATHS, config, language } from "@chef/constants";

import { showNotification, useCreateBillingSessionMutation } from "../features";
import {
  useLazyBillingQuery,
  useLazyCalendarQuery,
  useLazyUserInfoQuery,
} from "../graphql/generated";
import {
  getBasketForCalendarWeek,
  getNextDeliveryFromCalendar,
  getPriceOfProductByVariation,
} from "../helpers";

const intl = (
  {
    no: {
      ERROR_BAD_PAYER:
        "Din kundekonto er midlertidig stengt pga. manglende betaling. Du kan ikke endre betalingsmetode nå.",
      CONTACT_CUSTOMER_SERVICE: "Kontakt kundeservice for mer informasjon.",
    },
    se: {
      ERROR_BAD_PAYER:
        "Ditt kundkonto är tillfälligt stängt på grund av utebliven betalning. Du kan inte ändra betalningsmetod just nu.",
      CONTACT_CUSTOMER_SERVICE: "Kontakta kundtjänst för mer information.",
    },
    dk: {
      ERROR_BAD_PAYER:
        "Din kundekonto er midlertidigt lukket på grund af manglende betaling. Du kan ikke ændre betalingsmetode nu.",
      CONTACT_CUSTOMER_SERVICE: "Kontakt kundeservice for mere information.",
    },
  } as const
)[language];

export const usePaymentSession = () => {
  const { week, year } = getNextCutoff();

  const [fetchUserInfo] = useLazyUserInfoQuery();

  const [fetchCalendar] = useLazyCalendarQuery();

  const [createBillingSession, { isLoading: isCreatingBillingSession }] =
    useCreateBillingSessionMutation();

  const [fetchBilling] = useLazyBillingQuery({ refetchOnFocus: true });

  const createSession = async () => {
    const [
      { data: userInfoQuery },
      { data: calendarQuery },
      { data: billingQuery },
    ] = await Promise.all([
      fetchUserInfo(),
      fetchCalendar({ week, year, range: 5 }),
      fetchBilling(),
    ]);

    if (!userInfoQuery || !calendarQuery || !billingQuery) {
      return;
    }

    if (isAccountBadPayer(billingQuery.billing.status)) {
      showNotification({
        message: intl.ERROR_BAD_PAYER,
        type: "error",
        links: [
          {
            href: BRAND_PATHS.CONTACT_US_PAGE,
            label: intl.CONTACT_CUSTOMER_SERVICE,
          },
        ],
      });
      return;
    }

    const { email, firstName, lastName, telephone } = userInfoQuery.userInfo;

    const calendar = calendarQuery.calendar;

    const url = new URL("/api/billing/callback", window.location.origin);
    url.searchParams.set("d", window.location.pathname);

    let total = 0;

    const calendarWeek =
      getNextDeliveryFromCalendar({ calendar }) || calendar[0];

    const basket = getBasketForCalendarWeek({ calendarWeek });

    const preselectorBasket = calendar.find(
      (c) => c.week === week && c.year === year,
    )?.preselectorBasket;

    if (!basket) {
      console.error("No basket found");
      return;
    }

    const basketItems = basket.basketDetails.products.map((p) => {
      const isPreselected = preselectorBasket?.variationIds.includes(
        p.variationId,
      );

      return {
        productId: p.variation.productId,
        productTypeId: p.variation.product.productTypeId,
        variationId: p.variationId,
        name: p.variation.name,
        quantity: p.quantity,
        price: isPreselected
          ? 0
          : toØre(getPriceOfProductByVariation(p.variation)),
      };
    });

    const sumOfItems = sumByFn(
      basketItems,
      (item) => item.price * item.quantity,
    );

    const deliveryFee = toØre(
      basket.shippingDetails.plannedDeliveryInformation.customerFee,
    );

    total += sumOfItems + deliveryFee;

    const net = total;
    const vat = Math.round(net * config.vatRate);

    const { redirectUri } = await createBillingSession({
      payment: {
        redirectUri: url.toString(),
      },
      subject: {
        email,
        firstName,
        lastName,
        nin: null,
        telephone,
      },
      basket: {
        items: basketItems,
        _meta: {
          deliveryFee,
          total,

          credit: 0, // Setting to 0 as it is difficult to predict
          discount: 0, // Setting to 0 as it is difficult to predict

          net,
          vat,

          week: basket.week,
          year: basket.year,
        },
      },
      cancellationUrl: window.location.href,
    }).unwrap();

    if (redirectUri) {
      window.location.href = redirectUri;
    }
  };

  return {
    createSession,
    isCreating: isCreatingBillingSession,
  };
};
