import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { createAndSendEvent } from "@chef/events";
import { invariant } from "@chef/utils/invariant";

import { RootState } from "..";
import { toggleCartModal } from "../features/modal";

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: toggleCartModal,
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState() as RootState;

      const cartIsOpen = state.modal.cart.open;

      invariant(
        action.payload.week && action.payload.year,
        "Missing week or year",
      );

      const deviation = state.basket.deviations.find(
        (d) => d.week === action.payload.week && d.year === action.payload.year,
      );

      invariant(deviation, "Could not find deviation");

      if (cartIsOpen) {
        createAndSendEvent("Cart Viewed", {
          affiliation: "Frontend process",
          products: deviation.products.map((bp) => ({
            product_id: bp.productId,
            variant_id: bp.variationId,
            quantity: bp.quantity,
            price: bp.price,
            category_id: bp.productTypeId,
          })),
        });
      }
    },
  });
