import Link from "next/link";
import { useState } from "react";

import { formatTimeInterval, getNextCutoff } from "@chef/helpers";
import { Button, Card, Input } from "@chef/components";
import { WEEKDAY, BRAND_PATHS } from "@chef/constants";
import { isEmptyArray } from "@chef/utils/array";
import { ChevronRight, Time } from "@chef/icons/small";
import {
  TimeblocksByRangeWithGeoQuery,
  useTimeblocksByRangeWithGeoQuery,
} from "@chef/state-management";
import { groupTimeblocksDataByWeekday } from "@chef/state-management/helpers";

import { ContactForm } from "./ContactForm";
import { DeliveryCheckerGeoFence } from "./DeliveryCheckerGeoFence";

import { intl } from "./DeliveryChecker.Intl";

interface DeliveryCheckerProps {
  showContactForm?: boolean;
  showCTA?: boolean;
}

interface ResultsProps {
  postalCode?: string;
  timeblocksData?: TimeblocksByRangeWithGeoQuery["timeblocksByRangeWithGeo"];
  isLoading?: boolean;
  showContactForm?: boolean;
  showCTA?: boolean;
}

const Results = ({
                   showContactForm,
                   showCTA,
                   postalCode,
                   timeblocksData,
                   isLoading,
                 }: ResultsProps) => {
  if (!postalCode || !timeblocksData || isLoading) {
    return null;
  }

  const firstTimeblock = timeblocksData.weeks[0]?.timeblocks[0];
  if (isEmptyArray(timeblocksData.weeks)) {
    return (
      <Card className="flex flex-col bg-errorBG" noShadow transparent>
        <h2>
          <strong>{intl.SORRY}</strong>
        </h2>

        <p>{intl.WE_CANNOT_DELIVER_TO(postalCode)}.</p>

        {showContactForm && (
          <div className="mt-2">
            <ContactForm postalCode={postalCode} />
          </div>
        )}
      </Card>
    );
  }

  return (
    <Card
      className="flex flex-col gap-8 px-4 py-6 md:px-10 md:py-10 bg-grey-3"
      noShadow
      transparent
      noPadding
    >
      <h2>
        <strong>{intl.OF_COURSE_WE_DELIVER_TO(firstTimeblock.city)}</strong>
      </h2>
      <dl className="flex flex-col gap-6">
        {groupTimeblocksDataByWeekday(timeblocksData.weeks[0]?.timeblocks).map(
          ({ weekday, timeblocks }) => (
            <div key={weekday} className="flex items-center gap-4">
              <Time className="w-5 h-5" />
              <div className="flex flex-col">
                <dl>
                  <strong>{intl.WEEKDAYS[weekday as WEEKDAY]}</strong>
                </dl>
                <dd>
                  {timeblocks
                    .map((timeblock) =>
                      formatTimeInterval(
                        timeblock.from,
                        timeblock.to,
                        "h",
                        " - ",
                      ),
                    )
                    .join(", eller ")}
                </dd>
              </div>
            </div>
          ),
        )}
      </dl>
      {showCTA && (
        <div className="flex justify-center">
          <Link href={BRAND_PATHS.SIGNUP_HREF}>
            <Button IconRight={ChevronRight} primary>
              {intl.GET_STARTED}
            </Button>
          </Link>
        </div>
      )}
    </Card>
  );
};

export const DeliveryChecker = ({
                                  showContactForm = true,
                                  showCTA = true,
                                }: DeliveryCheckerProps) => {
  const [postalCode, setPostalCode] = useState("");

  const { week, year } = getNextCutoff();

  const {
    currentData: timeblocksWithGeo,
    originalArgs,
    isFetching,
  } = useTimeblocksByRangeWithGeoQuery(
    { week, year, range: 1, postalCode },
    { skip: postalCode.length !== intl.POSTAL_CODE_LENGTH },
  );

  const isGeoRestrictedPostalCode =
    timeblocksWithGeo?.timeblocksByRangeWithGeo.isGeoRestricted;

  return (
    <>
      <Input
        name="postalCode"
        type="text"
        inputMode="numeric"
        label={intl.INPUT_YOUR_POSTAL_CODE}
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        disabled={isFetching}
        helperText={isFetching ? intl.CHECKING_POSTAL_CODE : undefined}
        maxLength={intl.POSTAL_CODE_LENGTH}
        className="mb-4"
      />
      {!isGeoRestrictedPostalCode ? (
        <Results
          postalCode={originalArgs?.postalCode}
          timeblocksData={timeblocksWithGeo?.timeblocksByRangeWithGeo}
          isLoading={isFetching}
          showContactForm={showContactForm}
          showCTA={showCTA}
        />
      ) : (
        <DeliveryCheckerGeoFence showCTA={showCTA} />
      )}
    </>
  );
};
