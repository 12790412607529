import { capitalize } from "@chef/utils/string";
import { unique } from "@chef/utils/array";

import { intl } from "./BasicGoodsBox.Intl";

interface BasicGoodsBoxProps {
  basicGoods: string[];
}

export const BasicGoodsBox = ({ basicGoods }: BasicGoodsBoxProps) => {
  const goods = unique([...basicGoods]).sort((a, b) => a.localeCompare(b));

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <div className="w-2 h-2 mr-2 circle bg-grey-1" />
        <strong>{intl.BASIC_GOODS}:</strong>
      </div>

      <ul className="flex flex-wrap gap-1 pb-4 mt-2 ">
        {goods.map((basicGood, i) => (
          <li className="after:content-[','] last:after:content-['']" key={i}>
            {i === 0 ? capitalize(basicGood) : basicGood}
          </li>
        ))}
      </ul>
    </div>
  );
};
