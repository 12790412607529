import { PRODUCT_CATEGORY_IDS, PRODUCT_TYPE_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { isOneSubProduct } from "@chef/helpers";

import {
  ICampaignBasket,
  IDeviationBasket,
  IDeviationProduct,
} from "../features";
import {
  CalendarQuery,
  OrdersQuery,
  PickAndMixQuery,
  ProductsByCategoriesQuery,
  ProductsByIdsQuery,
} from "../graphql/generated";
import { GetPriceOfPickAndMixProduct } from "../hooks/usePickAndMixPrice";
import {
  convertFromPortionsToPlusPortions,
  getAttribute,
  getMealsFromVariations,
  getPortionsValueFromVariationWithPlus,
  getVariationFromPickAndMix,
  getVariationFromProductsByCategories,
  isAddonProduct,
  isLimitedQuantityProduct,
  isMealboxProduct,
  isPickAndMixProduct,
  isStandaloneProduct,
  isVariationPlusPortions,
} from "./product";
import { getLoyaltyFee } from "./loyalty";

export const getMealboxVariationFromBasket = (args: {
  basketProducts: IDeviationBasket["products"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { basketProducts, productsByCategories } = args;

  const mealboxProduct = basketProducts.find(isOneSubProduct);
  if (!mealboxProduct) {
    return null;
  }

  const mealboxVariation = getVariationFromProductsByCategories({
    productCategoryId: PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
    productsByCategories,
    variationId: mealboxProduct.variationId,
  });

  return mealboxVariation;
};

export const getPreselectedMealboxIdFromBasket = (args: {
  basketProducts: IDeviationBasket["products"];
  productsByCategories: ProductsByCategoriesQuery["productsByCategories"];
}) => {
  const { basketProducts } = args;

  const mealboxInBasket = basketProducts.find(isMealboxProduct);

  return mealboxInBasket?.productId;
};

interface IGetBasketFinancesArgs<T extends ICampaignBasket | IDeviationBasket> {
  basketProducts: T["products"];
  customerFee?: number;
  loyaltyLevel?: number;
  discountAmount?: number;
}

export const getBasketFinances = <T extends ICampaignBasket | IDeviationBasket>(
  args: IGetBasketFinancesArgs<T>,
) => {
  const { basketProducts, customerFee, loyaltyLevel, discountAmount } = args;

  const deliveryFeeAfterLoyalty = getLoyaltyFee({
    loyaltyLevel: loyaltyLevel || 0,
    customerFee: customerFee || 0,
  });

  const finances = {
    addon: 0,
    pickAndMix: 0,
    mealbox: 0,
    total: deliveryFeeAfterLoyalty,
    customerFee: deliveryFeeAfterLoyalty,
  };

  for (const product of basketProducts) {
    const price = product.price * product.quantity;

    finances.total += price;
    if (isMealboxProduct(product)) {
      finances.mealbox += price;
    } else if (isPickAndMixProduct(product)) {
      finances.pickAndMix += price;
    } else if (
      isAddonProduct(product) ||
      isStandaloneProduct(product) ||
      isLimitedQuantityProduct(product)
    ) {
      finances.addon += price;
    }
  }

  finances.total -= Math.abs(discountAmount || 0);

  return finances;
};

export const DISCOUNT_TYPE = {
  MULTIPLICATIVE: 1,
  ADDITIVE: 2,
} as const;

type DiscountType = typeof DISCOUNT_TYPE[keyof typeof DISCOUNT_TYPE];

interface IGetBasketFinancesWithDiscount
  extends IGetBasketFinancesArgs<IDeviationBasket> {
  discounts: {
    amount: number;
    type?: DiscountType;
    amountType?: DiscountType;
  }[];
}

export const getBasketFinancesWithDiscount = (
  args: IGetBasketFinancesWithDiscount,
) => {
  const { discounts } = args;

  const basketFinances = getBasketFinances(args);
  if (!basketFinances) {
    return null;
  }

  const finances = {
    ...basketFinances,
    discount: 0,
  };

  for (const discount of discounts) {
    if (!discount.amount) {
      continue;
    }

    const type = discount.amountType || discount.type;

    let discountAmount = 0;
    if (type === DISCOUNT_TYPE.MULTIPLICATIVE) {
      discountAmount =
        finances.total - (finances.total * (100 - discount.amount)) / 100;
    } else if (type === DISCOUNT_TYPE.ADDITIVE) {
      discountAmount = discount.amount;
    }

    // in case the discountAmount is higher than the total
    finances.discount += Math.min(discountAmount, finances.total);
    finances.total = Math.max(0, finances.total - discountAmount);
  }

  return finances;
};

type NewDeviationMinimumType = Pick<
  IDeviationBasket["products"][0],
  "productId" | "productTypeId" | "quantity"
> & {
  // needs to be passed on PickAndMix if you want to support plus portions
  variationId?: string;
};

export const getNewBasketDeviation = (args: {
  getPriceOfPickAndMixProduct: GetPriceOfPickAndMixProduct;
  // minimum version ONLY applies to financial and pickAndMix
  basketProducts: NewDeviationMinimumType[];
  // requires the onesub product
  onesubProduct: ProductsByIdsQuery["products"][number];
  // and pickAndMix
  pickAndMix: PickAndMixQuery["pickAndMix"];
  // this cannot be plus portion
  portions: number;

  // NOTE: this is only intended to be used by week editor
  updateProduct?: {
    productId: string;
    variationId: string;
  };
}) => {
  const {
    getPriceOfPickAndMixProduct,
    basketProducts,
    onesubProduct,
    pickAndMix,
    portions,
    updateProduct,
  } = args;

  let primaryProduct: IDeviationBasket["products"][0] | null = null;
  const pickAndMixProducts: IDeviationBasket["products"] = [];
  const otherProducts: IDeviationBasket["products"] = [];

  for (const product of basketProducts) {
    if (isOneSubProduct(product)) {
      primaryProduct = {
        price: 0,
        variationId: "",
        ...product,
      };
    } else if (isPickAndMixProduct(product)) {
      pickAndMixProducts.push({
        price: 0,
        variationId: "",
        ...product,
      });
    } else {
      otherProducts.push(product as IDeviationBasket["products"][0]);
    }
  }

  if (!primaryProduct) {
    return null;
  }

  // update our pickAndMixProducts with the update
  if (updateProduct) {
    const idx = pickAndMixProducts.findIndex(
      (p) => p.productId === updateProduct.productId,
    );
    if (idx !== -1) {
      pickAndMixProducts.splice(idx, 1);
    } else {
      const product = pickAndMix.find((pnm) =>
        isEqualStrings(pnm.productId, updateProduct.productId),
      )?.product;
      if (!product) {
        return null;
      }

      pickAndMixProducts.push({
        price:
          getPriceOfPickAndMixProduct({
            product,
            variationId: updateProduct.variationId,
          }) || 0,
        productId: updateProduct.productId,
        productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
        quantity: 1,
        variationId: updateProduct.variationId,
      });
    }
  }

  // update our primary product
  let found = false;

  // make sure we have a variation that fits this meal size
  const mealsOptions = getMealsFromVariations({
    variations: onesubProduct.variations,
    portions: portions,
  });

  const hasVariationForThisMealSize = mealsOptions.includes(
    pickAndMixProducts.length,
  );

  for (const variation of onesubProduct.variations) {
    const p = +(getAttribute("Portions", variation) || 0);
    const meals = +(getAttribute("Meals", variation) || 0);

    // if meal/portion doesn't match, it isn't the correct variation
    if (hasVariationForThisMealSize && meals !== pickAndMixProducts.length) {
      continue;
    }
    if (p !== portions) {
      continue;
    }

    found = true;
    primaryProduct = {
      ...primaryProduct,
      price: variation.flexiblePriceV2.regularPrice,
      variationId: variation.variationId,
      quantity: 1,
    };
  }

  // if we didn't find the new financial variation, it means it doesn't exist
  // this should never happen
  if (!found) {
    console.error(args);
    return null;
  }

  // update the pick and mix products with the correct portion sizes
  const newPickAndMixProducts: IDeviationBasket["products"] = [];
  for (const pnm of pickAndMix) {
    for (const product of pickAndMixProducts) {
      if (!isEqualStrings(pnm.productId, product.productId)) {
        continue;
      }

      const currentVariation = getVariationFromPickAndMix({
        pickAndMix,
        productId: product.productId,
        variationId: product.variationId,
      });
      if (product.variationId && !currentVariation) {
        continue;
      }

      // get the preferred portions to lookup
      const portionsToLookup =
        currentVariation && isVariationPlusPortions(currentVariation)
          ? convertFromPortionsToPlusPortions(portions)
          : portions;

      // find newVariation using portionsToLookup
      let newVariation = pnm.product.variations.find(
        (v) => getPortionsValueFromVariationWithPlus(v) === portionsToLookup,
      );
      // if we didn't find the new variation, try finding it using the given portions value
      if (!newVariation) {
        newVariation = pnm.product.variations.find(
          (v) => getPortionsValueFromVariationWithPlus(v) === portions,
        );
      }
      if (!newVariation) {
        continue;
      }

      newPickAndMixProducts.push({
        ...product,
        variationId: newVariation.variationId,
        price:
          getPriceOfPickAndMixProduct({
            product: pnm.product,
            variationId: newVariation.variationId,
            basketProducts: [primaryProduct],
          }) || 0,
      });
    }
  }

  return [primaryProduct, ...newPickAndMixProducts, ...otherProducts];
};

export const getProductsFromOrderLines = (
  basketProducts: OrdersQuery["orders"][number]["orderLines"],
) => {
  return basketProducts?.map((basketProduct) => {
    const { pricePerQuantity, quantity, variation } = basketProduct;

    const { productId, productTypeId } = variation.product;
    return {
      price: pricePerQuantity,
      quantity,
      productId,
      productTypeId,
      variationId: variation.variationId,
    };
  });
};

export const getBasketForCalendarWeek = (args: {
  calendarWeek: CalendarQuery["calendar"][number];
}) => {
  const { calendarWeek } = args;

  return calendarWeek.baskets.find(
    (basket) =>
      basket.week === calendarWeek.week && basket.year === calendarWeek.year,
  );
};

export const compareBasketsProducts = (
  basketA: IDeviationProduct[],
  basketB: IDeviationProduct[],
) => {
  const productIds1 = basketA.map((item) => item.productId);
  const productIds2 = basketB.map((item) => item.productId);

  if (productIds1.length !== productIds2.length) {
    return false;
  }

  productIds1.sort();
  productIds2.sort();

  for (let i = 0; i < productIds1.length; i++) {
    if (productIds1[i] !== productIds2[i]) {
      return false;
    }
  }

  return true;
};
