import { format } from "date-fns";
import { locale } from "@chef/constants";

/**
 * @desc    Generate time obj for Norway time (to safeguard against timezones)
 * @returns {Date} Date object with norway's timezone
 */
function osloDateObj(): Date {
  const today = new Date();

  function stdTimezoneOffset(d: Date) {
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  }

  function dst(d: Date) {
    return d.getTimezoneOffset() < stdTimezoneOffset(d);
  }

  let offset = +1; // Oslo is in GMT +1
  if (dst(today)) {
    offset = +2;
  }

  return new Date(new Date().getTime() + offset * 3600 * 1000);
}

const getDayName = ({ date, locale }: { date: Date; locale: Locale }) => {
  return format(date, "EEEE", { locale }).toLowerCase();
};

const getDayOfWeek = ({ date }: { date: Date }) => {
  return format(date, "EEEE", { locale }).toLowerCase();
};

/**
 * @desc    Transforms datestring from API into Date Object
 * @param   dateStr - datestring to parse
 * @returns Date object
 */

const parseTimeStringFromApi = (dateStr: string) => {
  const parsed = `${dateStr
    .replace(new RegExp("/", "g"), "-")
    .replace(" ", "T")}Z`;
  return new Date(parsed);
};

/**
 * @desc    Normalizes date into `yyyy-MM-dd` format
 * @param   date - Date object or datestring
 * @returns Normalized datestring `yyyy-MM-dd`
 */
const normalizeDate = (date: Date | string) => {
  if (typeof date === "string") {
    return format(new Date(date), "yyyy-MM-dd");
  }
  return format(date, "yyyy-MM-dd");
};

export {
  osloDateObj,
  getDayName,
  getDayOfWeek,
  parseTimeStringFromApi,
  normalizeDate,
};
