import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Input } from "@chef/components";
import { useExtendedForm } from "@chef/hooks";

import { showNotification } from "@chef/state-management";
import { useTrack } from "@chef/state-management/hooks";

import { intl } from "./ContactForm.Intl";

interface ContactFormProps {
  postalCode: string;
  address?: string;
}

interface FormData {
  postalCode: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  email: string;
  phone?: string;
}

const schema = yup.object().shape({
  postalCode: yup.string().required(intl.POSTAL_CODE_IS_REQUIRED),
  firstName: yup.string(),
  lastName: yup.string(),
  address: yup.string(),
  email: yup
    .string()
    .email(intl.MUST_BE_A_VALID_EMAIL)
    .required(intl.EMAIL_IS_REQUIRED),
  phone: yup.string(),
});

export const ContactForm = ({ postalCode, address }: ContactFormProps) => {
  const track = useTrack({ serverSide: true });
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useExtendedForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      postalCode,
      address,
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await track("postalCodeInterestSubmitted", {
        affiliation: "Frontend process",
        postalCode: data.postalCode,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        phone: data.phone,
      });
    } catch {
      showNotification({
        message: intl.SOMETHING_WENT_WRONG,
        type: "error",
      });
    }
  };

  if (isSubmitSuccessful) {
    return (
      <div>
        <h2 className="text-xl md:text-xl">
          {intl.THANK_YOU_FOR_YOUR_INTEREST}
        </h2>
        <p className="mt-4">
          {intl.WE_WILL_CONTACT_YOU_WHEN_WE_CAN_OFFER_DELIVERIES_IN_YOUR_AREA}
        </p>
      </div>
    );
  }

  return (
    <>
      <p className="mb-4 text-lg">
        <strong>{intl.IF_YOU_WANT_TO_BE_CONTACTED_WHEN_WE_DELIVER}</strong>
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Input
          {...register("postalCode")}
          label={intl.POSTAL_CODE}
          helperText={intl.MUST_BE_FILLED_IN}
        />

        <Input
          {...register("email")}
          label={intl.EMAIL}
          helperText={intl.MUST_BE_FILLED_IN}
        />

        <Input {...register("firstName")} label={intl.FIRST_NAME} />

        <Input {...register("lastName")} label={intl.LAST_NAME} />

        <Input {...register("address")} label={intl.ADDRESS} />

        <Input {...register("phone")} label={intl.PHONE} />

        <Button type="submit" loading={isSubmitting} primary>
          {intl.SEND}
        </Button>
      </form>
    </>
  );
};
