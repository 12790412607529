import { useMemo } from "react";
import { useSelector } from "react-redux";

import { config, PRODUCT_IDS } from "@chef/constants";
import { isEqualStrings } from "@chef/utils/equal";
import { isMealboxProduct, isOneSubProduct } from "@chef/helpers";
import { isNotEmptyArray } from "@chef/utils/array";

import { selectDeviationProducts } from "../features";
import { useProductsByIdsQuery } from "../graphql/generated";
import {
  getAttribute,
  getMealsFromVariations,
  isPickAndMixProduct,
} from "../helpers/product";

// The purpose of this hook is to give access to commonly used data that is often needed
// when dealing with the basket. Do not include a bunch of niche stuff in this hook.
export const useExtendedBasketData = (args: {
  week: number;
  year: number;
  skip?: boolean;
}) => {
  const { week, year } = args;

  const skip = !week || !year || args.skip;

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const {
    data: productsByIdsQuery,
    isLoading: isLoadingProductsByIds,
    isFetching: isFetchingProductsByIds,
    isError: isErrorProductsByIds,
  } = useProductsByIdsQuery(
    {
      productIds: [PRODUCT_IDS.ONESUB],
      week,
      year,
    },
    { skip },
  );

  const products = productsByIdsQuery?.products;
  const onesubProduct = products?.find(isOneSubProduct);

  const selectedMealbox = selectedProducts?.find(isMealboxProduct);
  const mealboxVariation = onesubProduct?.variations.find((v) =>
    isEqualStrings(v.variationId, selectedMealbox?.variationId),
  );

  const selectedPortions = useMemo(() => {
    if (!mealboxVariation) {
      return config.order.defaultPortions;
    }
    return +(
      getAttribute("Portions", mealboxVariation) || config.order.defaultPortions
    );
  }, [mealboxVariation]);

  const selectedMeals = useMemo(() => {
    if (!mealboxVariation) {
      if (!selectedProducts) {
        return config.order.defaultMeals;
      }
      return selectedProducts.filter(isPickAndMixProduct).length;
    }

    return +(
      getAttribute("Meals", mealboxVariation) || config.order.defaultMeals
    );
  }, [mealboxVariation]);

  const mealsOptionsForSelectedPortions = getMealsFromVariations({
    variations: onesubProduct?.variations,
    portions: selectedPortions,
  });

  const minMealsForSelectedPortions = isNotEmptyArray(
    mealsOptionsForSelectedPortions,
  )
    ? Math.min(...mealsOptionsForSelectedPortions)
    : config.order.minMeals;

  return {
    data: {
      selectedProducts,
      mealboxVariation,
      mealboxProduct: onesubProduct,
      mealsOptions: mealsOptionsForSelectedPortions,
      minMeals: minMealsForSelectedPortions,
      portions: selectedPortions,
      meals: selectedMeals,
    },
    isLoading: isLoadingProductsByIds,
    isFetching: isFetchingProductsByIds,
    isError: isErrorProductsByIds,
  };
};
