import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { CURRENCY, language, ORDER_TYPE_IDS } from "@chef/constants";
import { createAndSendEvent } from "@chef/events";

import { RootState } from "..";
import {
  showNotification,
  setSignupCoupon,
  selectSignupApplicantInfo,
  selectSignupMealbox,
} from "../features";

const intl = {
  se: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} är aktiverad`,
  },
  no: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} er aktivert`,
  },
  dk: {
    COUPON_CODE_ACTIVATED: (couponCode: string) => `${couponCode} tilføjet`,
  },
}[language];

export default (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setSignupCoupon,
    effect: async (action, { getState, getOriginalState }) => {
      if (
        (getOriginalState() as RootState).signupRtk.coupon?.couponCode ===
        action.payload.couponCode
      ) {
        return;
      }

      if (action.payload.source !== "default") {
        showNotification({
          type: "success",
          message: intl.COUPON_CODE_ACTIVATED(action.payload.couponCode),
        });
      }

      if (action.payload.userInitiated) {
        const state = getState() as RootState;

        const applicantInfo = selectSignupApplicantInfo(state);

        if (!applicantInfo) {
          console.error(
            "Required property applicantinfo not set in signup state before tracking signup coupon",
          );
        }

        const selectedMealbox = selectSignupMealbox(state);

        createAndSendEvent("Order Updated", {
          affiliation: "Frontend process",
          campaign_id: action.payload.discountId,
          coupon: action.payload.couponCode,
          currency: CURRENCY,
          discount: action.payload.amount,
          discount_code: action.payload.name,
          discount_code_id: action.payload.discountId,
          order_id: applicantInfo?.applicantId || "",
          order_type_id: ORDER_TYPE_IDS.RECURRING,
          products: [
            {
              variant_id: selectedMealbox.variationId,
              product_id: selectedMealbox.productId,
              quantity: 1,
            },
          ],
        });
      }
    },
  });
