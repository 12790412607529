import { Key } from "react";
import clsx from "clsx";

import { RadioButtonBar, RadioButtonOption } from "../components";
import { Language } from "../../types";

const intl = {
  no: {
    PORTIONS: "pers",
  },
  se: {
    PORTIONS: "pers",
  },
  dk: {
    PORTIONS: "pers",
  },
};
interface PortionSizeSelectorProps<T> {
  name: string;
  options: RadioButtonOption<T>[];
  onChange: (value: T) => void;
  value: T;
  language: Language;
  className?: string;
}

const PortionSizeSelector = <T extends Key | null | undefined>({
  name,
  options,
  onChange,
  value,
  language = "no",
  className,
}: PortionSizeSelectorProps<T>) => {
  const { PORTIONS } = intl[language];

  return (
    <div
      className={clsx(
        "flex gap-3 md:gap-2 items-center justify-start",
        className,
      )}
    >
      <RadioButtonBar
        name={`${name}-portions-size-selector`}
        options={options}
        onChange={onChange}
        value={value}
        small
        hasCheckmark
        suffix={PORTIONS}
      />
    </div>
  );
};

export default PortionSizeSelector;
