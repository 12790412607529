import * as React from "react";
import { SVGProps } from "react";
const OldBrowser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={150} height={150} rx={75} fill="#FFF6E8" />
    <rect
      x={49}
      y={33.6406}
      width={52}
      height={82}
      rx={4.92857}
      stroke="currentColor"
      strokeWidth={3}
    />
    <path
      d="M66.5391 104.391H83.4621"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M83.1562 76.7482C84.7185 76.7482 85.7651 76.1276 86.8698 75.0229C87.9745 73.9182 88.5951 72.4199 88.5951 70.8576C88.5951 69.2953 87.9745 67.797 86.8698 66.6923C85.7651 65.5876 84.2668 64.967 82.7045 64.967H82.4779C81.7953 63.4527 80.6415 62.1997 79.1886 61.3947C77.7357 60.5898 76.0615 60.276 74.4157 60.5002C72.7699 60.7244 71.2406 61.4745 70.056 62.6388C68.8713 63.8031 68.0947 65.3191 67.842 66.9607C67.1616 66.7729 66.449 66.7317 65.7515 66.8399C65.0539 66.9482 64.3873 67.2033 63.7958 67.5885C63.2043 67.9736 62.7013 68.4801 62.3202 69.0742C61.9391 69.6684 61.6885 70.3367 61.5851 71.0349C61.4816 71.7332 61.5277 72.4455 61.7201 73.1246C61.9126 73.8037 62.2472 74.4342 62.7016 74.9743C63.1561 75.5145 63.72 75.9519 64.3562 76.2577C64.9924 76.5635 65.6863 76.7307 66.392 76.7482"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75 68.5938V78.5625M71.375 74.9375L75 78.5625L78.625 74.9375"
      stroke="currentColor"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={101.5} cy={39.5} r={15.5} fill="currentColor" />
    <path
      d="M93.8372 44H95.4932V39.104L97.9892 44H99.5492V35.54H97.8812V40.46L95.3972 35.54H93.8372V44ZM101.978 46.364H103.586L106.418 37.256H104.726L103.526 41.744L102.326 37.256H100.634L102.722 43.988L101.978 46.364ZM107.273 37.616L107.861 41.396H108.269L108.869 37.616C108.941 37.136 109.013 36.74 109.013 36.392C109.013 35.864 108.665 35.456 108.065 35.456C107.477 35.456 107.117 35.864 107.117 36.392C107.117 36.74 107.201 37.136 107.273 37.616ZM107.105 43.148C107.105 43.676 107.549 44.096 108.065 44.096C108.605 44.096 109.037 43.676 109.037 43.148C109.037 42.62 108.605 42.188 108.065 42.188C107.549 42.188 107.105 42.62 107.105 43.148Z"
      fill="white"
    />
  </svg>
);
export default OldBrowser;
