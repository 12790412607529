import { useCallback, useContext } from "react";

import { Events } from "@chef/events";
import { AnalyticsContext, useAnalytics } from "./useAnalytics";
import { useTrackMutation } from "../features";

export const useTrack = (args?: { serverSide?: boolean }) => {
  const analytics = useAnalytics();
  const { serverSide } = args || {};
  const [backendTrack] = useTrackMutation();

  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error("useTrack used outside of TrackingProvider");
  }

  const fn = useCallback(
    <K extends keyof Events>(eventName: K, payload: Events[K]) => {
      if (serverSide) {
        return (async () => {
          let id: string | undefined;

          // Attempts to retrieve the user id from the analytics instance
          try {
            const user = await analytics?.user();

            const _id = user?.id() || user?.anonymousId();

            if (_id) {
              id = _id;
            }
          } catch {
            // Do nothing
          }

          return backendTrack({ eventName, payload, id }).unwrap();
        })();
      }

      return ctx.queue.push({ type: "track", eventName, payload });
    },
    [ctx.queue, backendTrack, serverSide, analytics],
  );

  return fn;
};
