import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { BRAND_NAME } from "@chef/constants";
import { useCallbackRef } from "@chef/hooks";
import {
  acceptAllConsents,
  rejectIosAppTrackingTransparency,
  useMeQuery,
} from "@chef/state-management";

import { MobileAppContext } from "./MobileAppContext";
import { useMobileApp } from "./useMobileApp";

import {
  IFromAppPayload,
  MOBILE_APP_ENDPOINT,
  MobileAppSubscribe,
  SubscribeCallback,
  SubscribeEvents,
  OS,
} from "../types";

// this will call the app (if it exists) and set the isApp variable
const CheckIfAppComponent = (props: {
  onAppConnect: (result: false | IFromAppPayload<"on-app-connect">) => void;
}) => {
  const { sendAppMessage, isOldApp, isApp } = useMobileApp();

  const onAppConnect = useCallbackRef(props.onAppConnect);

  useEffect(() => {
    sendAppMessage("on-app-connect", {})
      .then((result) => {
        onAppConnect(result);
      })
      .catch(() => {
        onAppConnect(false);
      });
  }, []);

  useEffect(() => {
    if (isOldApp) {
      document.body.classList.add("intercom-webview-fix");
    } else {
      document.body.classList.remove("intercom-webview-fix");
    }
  }, [isOldApp]);

  useEffect(() => {
    // only apply bottom margin to GL
    if (isApp && BRAND_NAME === "GL") {
      document.body.classList.add("intercom-bubble-bottom-margin");
    } else {
      document.body.classList.remove("intercom-bubble-bottom-margin");
    }
  }, [isApp]);

  return null;
};

const UpdateUserDeviceIdentifier = () => {
  const { isApp, sendAppMessage } = useMobileApp();
  const { data: isLoggedIn, isUninitialized } = useMeQuery();

  useEffect(() => {
    if (!isApp || isUninitialized) {
      return;
    }

    if (isLoggedIn) {
      sendAppMessage("on-refresh-device-token", {
        agreementId: isLoggedIn.agreementId,
      });
    } else {
      sendAppMessage("on-user-logout", {});
    }
  }, [isLoggedIn, isApp, isUninitialized]);

  return null;
};

const HandleAppTrackingTransparencyIOS = () => {
  const dispatch = useDispatch();
  const { subscribe } = useMobileApp();

  useEffect(() => {
    const unsubscribe = subscribe("on-app-tracking-transparency", (payload) => {
      if (payload.allowed) {
        dispatch(acceptAllConsents());
      } else {
        dispatch(rejectIosAppTrackingTransparency());
      }
    });

    return unsubscribe;
  }, [subscribe, dispatch]);

  return null;
};

interface MobileAppProviderProps {
  children?: React.ReactNode;
}

export const MobileAppProvider = ({ children }: MobileAppProviderProps) => {
  const [isApp, setIsApp] = useState(false);
  const [appVersion, setAppVersion] = useState<null | string>(null);
  const [appOS, setAppOS] = useState<null | OS>(null);

  const subscriptions = useRef<
    {
      event: SubscribeEvents<MOBILE_APP_ENDPOINT>;
      callback: SubscribeCallback<MOBILE_APP_ENDPOINT>;
    }[]
  >([]);

  useEffect(() => {
    const messageHandler = (message: Event | MessageEvent<any>) => {
      const data = (message as MessageEvent<any>)?.data;

      let payload: IFromAppPayload<MOBILE_APP_ENDPOINT>;
      try {
        payload = JSON.parse(data) as IFromAppPayload<MOBILE_APP_ENDPOINT>;

        // TODO: find a more robust way to confirm this
        if (!payload.id || !payload.type) {
          throw new Error(`Invalid message, not related to app: ${data}`);
        }
      } catch {
        return;
      }

      for (const { event, callback } of [...subscriptions.current]) {
        if (event !== "*" && event !== payload.type) {
          continue;
        }

        // should _probably_ deep copy this, because I'm 99% sure this will cause a bug in the future
        callback(payload);
      }
    };

    // https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work
    window.addEventListener("message", messageHandler);
    document.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
      document.removeEventListener("message", messageHandler);
    };
  }, []);

  const subscribe = useCallback<MobileAppSubscribe>(
    <T extends MOBILE_APP_ENDPOINT>(
      event: SubscribeEvents<T>,
      callback: SubscribeCallback<T>,
    ) => {
      const entry = {
        event,
        callback: callback as any,
      };

      subscriptions.current.push(entry);

      return () => {
        subscriptions.current.slice(subscriptions.current.indexOf(entry), 1);
      };
    },
    [],
  );

  return (
    <MobileAppContext.Provider value={{ subscribe, isApp, appVersion, appOS }}>
      <CheckIfAppComponent
        onAppConnect={(result) => {
          if (result === false) {
            setIsApp(false);
          } else {
            setIsApp(true);
            setAppVersion(result.version);
            setAppOS(result.os);
          }
        }}
      />
      <UpdateUserDeviceIdentifier />
      <HandleAppTrackingTransparencyIOS />

      {children}
    </MobileAppContext.Provider>
  );
};
