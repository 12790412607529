import { RecipeAndStepsQuery } from "@chef/state-management";
import { isEmptyArray } from "@chef/utils/array";

import { intl } from "./NutritionalContentBox.Intl";

type NutritionalContent =
  RecipeAndStepsQuery["recipeAndSteps"]["instructions"]["portions"][number]["nutritionFacts"];

interface NutritionalContentBoxProps {
  nutritionalContent: NutritionalContent;
  type: "portion" | "gram";
}

type NutrientKey = NutritionalContent["recipeNutritionPer100g"];

export const NutritionalContentBox = ({
  nutritionalContent,
  type,
}: NutritionalContentBoxProps) => {
  const { recipeNutritionPerPortion, recipeNutritionPer100g } =
    nutritionalContent;

  const isMeasuredByPortion = type === "portion";

  const getNutrientValue = (nutrient: keyof NutrientKey) => {
    const nutritionSource = isMeasuredByPortion
      ? recipeNutritionPerPortion
      : recipeNutritionPer100g;

    if (!nutritionSource) {
      return null;
    }

    const nutritionValue = nutritionSource[nutrient];

    if (!nutritionValue) {
      return null;
    }

    const nutritionUnit = nutrient === "energyKcal" ? "kcal" : "g";
    return `${Math.round(nutritionValue)} ${nutritionUnit}`;
  };

  const nutrientEntries = new Map([
    [intl.ENERGY, getNutrientValue("energyKcal")],
    [intl.FATS, getNutrientValue("fat")],
    [intl.CARBOHYDRATES, getNutrientValue("carbs")],
    [intl.PROTEIN, getNutrientValue("protein")],
  ]);

  const validNutrients = Array.from(nutrientEntries).filter(
    ([, value]) => value,
  );

  if (isEmptyArray(validNutrients)) {
    return null;
  }

  return (
    <div className="flex flex-col w-full">
      <h6>
        <strong>
          {isMeasuredByPortion
            ? intl.NUTRITION_PER_PORTION
            : intl.NUTRITION_PER_100_GRAM}
        </strong>
      </h6>
      <div className="pt-1 pb-2">
        <dl>
          {validNutrients.map(([label, value]) => (
            <div key={label} className="flex gap-1">
              <dt>
                <strong>{label}:</strong>
              </dt>
              <dd>{value}</dd>
            </div>
          ))}
        </dl>
      </div>
      {/* <div className="grid grid-cols-3 pt-4 text-sm">
        <div className="col-span-2 pb-4 border-b border-black">
          <strong>Inhold</strong>
        </div>
        <div className="pb-4 text-right border-b border-black">
          <strong>Pr. portion</strong>
        </div>

        <div className="col-span-2 pt-6 pb-1">Fedt</div>
        <div className="pt-6 pb-1 text-right">{Math.round(recipeNutritionPerPortion.fat)} g</div>

        <div className="col-span-2 pb-4 text-xs text-grey-1">heraf mættede fedtsyrer</div>
        <div className="pb-4 text-xs text-right text-grey-1">
          {Math.round(recipeNutritionPerPortion.fatSaturated)} g
        </div>

        <div className="col-span-2 pt-4 pb-1">Kulhydrat</div>
        <div className="pt-4 pb-1 text-right">{Math.round(recipeNutritionPerPortion.carbs)} g</div>

        <div className="col-span-2 pb-4 text-xs text-grey-1">heraf sukkerarter</div>
        <div className="pb-4 text-xs text-right text-grey-1">
          {Math.round(recipeNutritionPerPortion.carbsSugar)} g
        </div>

        <div className="col-span-2 py-4">Protein</div>
        <div className="py-4 text-right">{Math.round(recipeNutritionPerPortion.protein)} g</div>

        <div className="col-span-2 py-4">Salt</div>
        <div className="py-4 text-right">{Math.round(recipeNutritionPerPortion.salt) || "N/A"} g</div>

        <div className="col-span-2 pt-4">Kostfibre</div>
        <div className="pt-4 text-right">{Math.round(recipeNutritionPerPortion.carbsFiber)} g</div>
      </div> */}
    </div>
  );
};
