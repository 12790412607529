import { PropsOf } from "../../types";
import { Card } from "../components";
import DeliveryCardLoader from "../internals/DeliveryCardLoader";
import RecipesPreview from "./RecipesPreview";

const intl = {
  no: {
    SPECIALITY_ITEM: "dagligvare",
    SPECIALITY_ITEMS: "dagligvarer",
  },
  se: {
    SPECIALITY_ITEM: "livsmedel",
    SPECIALITY_ITEMS: "livsmedel",
  },
  dk: {
    SPECIALITY_ITEM: "specialvare",
    SPECIALITY_ITEMS: "specialvarer",
  },
};

interface DeliveryCardProps extends PropsOf<typeof Card> {
  isLoading: boolean;
  children: React.ReactNode;
}

interface OtherOrderLinesProps {
  number: number;
  language?: "no" | "se" | "dk";
}

const DeliveryCard = ({ isLoading, children, ...props }: DeliveryCardProps) => {
  if (isLoading) {
    return <DeliveryCardLoader />;
  }

  return <Card {...props}>{children}</Card>;
};

DeliveryCard.RecipesPreview = RecipesPreview;

const OtherOrderLines = ({
  number,
  language = "dk",
  ...props
}: OtherOrderLinesProps) => {
  const { SPECIALITY_ITEMS } = intl[language];
  if (!number || number <= 0) {
    return null;
  }
  return (
    <div className="mt-3" {...props}>
      +{number} {SPECIALITY_ITEMS}
    </div>
  );
};

DeliveryCard.OtherOrderLines = OtherOrderLines;

export default DeliveryCard;
