import { datadogRum } from "@datadog/browser-rum";

export const initDatadogRum = () => {
  const applicationId = process.env.DATADOG_APPLICATION_ID;
  if (!applicationId) {
    throw new Error("Missing required env variable 'DATADOG_APPLICATION_ID'");
  }

  const clientToken = process.env.DATADOG_CLIENT_TOKEN;
  if (!clientToken) {
    throw new Error("Missing required env variable 'DATADOG_CLIENT_TOKEN'");
  }

  const deploymentTrackingVersion =
    process.env.DATADOG_DEPLOYMENT_TRACKING_VERSION;

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.eu",
    service: process.env.APP_NAME,
    version: deploymentTrackingVersion,
    env: process.env.APP_ENV,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["clickmap", "feature_flags"],
    trackFrustrations: true,
    allowedTracingUrls: [process.env.GRAPHV4_URL, process.env.APIM_URL],
  });
};
