import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";

import { PRODUCT_TYPE_IDS } from "@chef/constants";
import { createAndSendEvent } from "@chef/events";

import { setDeviation } from "../features/basket";
import { api } from "../graphql/api";
import {
  deriveProductPropertiesFromState,
  deriveVariationPropertyFromState,
  findDifferentProductsInDeviations,
} from "./util";
import type { RootState } from "..";

const listener = (listener: ListenerMiddlewareInstance) =>
  listener.startListening({
    actionCreator: setDeviation,
    effect: async (action, listenerApi) => {
      if (!action.payload.manual) {
        return;
      }

      const previousState = listenerApi.getOriginalState() as RootState;

      const previousDeviationIdx = previousState.basket.deviations.findIndex(
        (d) =>
          d.week === action.payload.basket.week &&
          d.year === action.payload.basket.year,
      );

      const previousDeviation =
        previousState.basket.deviations[previousDeviationIdx];

      const differences = findDifferentProductsInDeviations(
        previousDeviation,
        action.payload.basket,
      );

      const pickAndMix = await listenerApi
        .dispatch(
          api.endpoints.pickAndMix.initiate({
            productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
            week: action.payload.basket.week,
            year: action.payload.basket.year,
          }),
        )
        .unwrap();

      for (const difference of differences) {
        const [category, name, productTypeId] =
          deriveProductPropertiesFromState(
            ["productTypeName", "name", "productTypeId"],
            difference.productId,
            pickAndMix,
          );

        const variant = deriveVariationPropertyFromState(
          "name",
          difference.productId,
          difference.variationId,
          pickAndMix,
        );

        if (difference.type === "removed") {
          createAndSendEvent("Product Removed", {
            affiliation: "Frontend process",
            category,
            name,
            category_id: productTypeId,
            product_id: difference.productId,
            quantity: difference.quantity,
            variant,
            variant_id: difference.variationId,
            price: difference.price,
          });
        }
        if (difference.type === "added") {
          createAndSendEvent("Product Added", {
            affiliation: "Frontend process",
            category,
            name,
            category_id: productTypeId,
            product_id: difference.productId,
            quantity: difference.quantity,
            variant,
            variant_id: difference.variationId,
            price: difference.price,
          });
        }
      }
    },
  });

export default listener;
