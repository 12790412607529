import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { IconPrinter } from "@tabler/icons-react";

import {
  AverageDishRating,
  Button,
  ButtonCircle,
  ReadMoreWrapper,
  Tooltip,
} from "@chef/components";
import {
  CurrencyWrapper as Currency,
  DislikeButton,
  FavoriteButton,
  RecipeTags,
  ShareButton,
} from "@chef/smart-components";
import { Clock, Info, Milk, Plus } from "@chef/icons/small";
import { usePickAndMixPrice } from "@chef/state-management/hooks";
import { resetRecipeModal } from "@chef/state-management";
import { findFeaturedPortraitImage } from "@chef/state-management/helpers";
import {
  BASE_64_1X1_GREY,
  BRAND_NAME,
  BRAND_PATHS,
  language,
} from "@chef/constants";

import { IRecipeComponentProps } from "../RecipeModal";
import { PlusProteinTag } from "./PlusProteinTag";
import { RecipeInformation } from "./RecipeInformation";
import { CampaignTag } from "./CampaignTag";
import { AvailableMenuWeeks } from "./AvailableMenuWeeks";
import { getCookingTime } from "../../utils/recipe";

import { brand, intl } from "./RecipeDescription.Intl";

export const RecipeDescription = ({
  recipe,
  disableUpdateBasket,
  week,
  year,
  isInBasket,
  addOrRemoveToBasket,
  productVariation,
  plusProteinVariation,
  isPlusProteinInBasket,
  selectedPortionSize,
  view,
}: IRecipeComponentProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const chefPartnerScore = selectedPortionSize?.chefPartnerScore;
  const hasChefPartnerScore = !!chefPartnerScore;

  const pdfUrl = selectedPortionSize?.pdfUrl;

  const url = typeof window !== "undefined" ? window.location.href : "";

  const printUrl = router.query["print"] ? undefined : `${url}?print=true`;

  const image = findFeaturedPortraitImage(recipe);

  const hasControls = view === "deviation" && !!productVariation;

  const { getPriceOfPickAndMixProduct } = usePickAndMixPrice({
    week: week || 0,
    year: year || 0,
  });

  const normalPrice =
    (productVariation &&
      getPriceOfPickAndMixProduct({ variation: productVariation })) ||
    0;

  const plusPrice =
    (plusProteinVariation &&
      getPriceOfPickAndMixProduct({ variation: plusProteinVariation })) ||
    0;

  const isNegativePrice = normalPrice < 0;

  const hasExtraPrice =
    isPlusProteinInBasket || isNegativePrice || normalPrice > 0;

  const onClose = () => dispatch(resetRecipeModal());

  const numberOfIngredients = recipe.instructions.portions
    .map((portion) => portion.ingredientSections)
    .map((section) => section.flatMap((s) => s.ingredients))
    .find((i) => i)
    ?.filter((i) => !i.isBasis).length;

  const handleAddOrRemoveToBasket = () => {
    if (isInBasket) {
      return addOrRemoveToBasket?.();
    } else if (productVariation) {
      return addOrRemoveToBasket?.(productVariation);
    }
  };

  const hasHeadline = recipe.recipeNameHeadline && recipe.recipeNameSubheadline;

  const cookingTime = getCookingTime(+recipe.cookingTimeMax);

  return (
    <div className="grid grid-cols-4 gap-2 lg:gap-6 lg:grid-cols-10">
      <div className="flex flex-col col-span-4 lg:col-span-6 lg:order-1 min-h-96 md:min-h-112 lg:min-h-144">
        <div className="relative w-full grow">
          <Image
            src={image?.url || BASE_64_1X1_GREY}
            alt={recipe.recipeName}
            fill
            sizes="(min-width: 1024px) 50vw, 100vw"
            className="object-cover object-[left_75%] lg:rounded-tl-none rounded-bl-none rounded-tl-lg rounded-tr-lg"
            overrideSrc={image?.url || BASE_64_1X1_GREY}
          />
          <PlusProteinTag
            hasPlusProtein={!!plusProteinVariation}
            inBasket={!!isPlusProteinInBasket}
          />
          <div className="absolute flex gap-2 right-4 bottom-4 xl:right-6 xl:bottom-6 print:hidden">
            <FavoriteButton recipe={recipe} className="outline-none" />
            <DislikeButton recipe={recipe} className="outline-none" />
            <ShareButton href={url} text={recipe.recipeName} />
            <ButtonCircle
              Icon={() => <IconPrinter strokeWidth={1.5} />}
              onClick={(e) => {
                if (!printUrl) {
                  e.preventDefault();
                  window.print();
                }
              }}
              href={pdfUrl ? pdfUrl : printUrl}
              className="bg-white hover:bg-white"
            />
          </div>
        </div>

        {"availableMenuWeeks" in recipe && (
          <aside className="relative flex items-center justify-between gap-3 px-4 text-sm lg:rounded-br-lg xl:px-6 bg-informationBG">
            <AvailableMenuWeeks
              recipe={recipe}
              selectedPortionsSize={+(selectedPortionSize?.size || 4)}
            />
          </aside>
        )}
      </div>

      <div className="col-span-4 p-4 lg:p-10">
        <div className="relative h-full">
          <div className="flex flex-wrap items-center gap-2">
            <CampaignTag recipe={recipe} />
            <RecipeTags recipe={recipe} showCookingTime={false} />

            {hasChefPartnerScore && (
              <div className="flex items-center gap-2 whitespace-nowrap">
                <a
                  href={BRAND_PATHS.WEIGHT_WATCHERS_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={clsx(
                      "flex justify-center items-center text-center bg-[#3435A5] text-white gap-1 min-w-[28px] max-w-auto h-5 text-xs px-1.5 rounded-[40px] font-medium",
                      chefPartnerScore.length === 1 && "min-h-[28px]",
                    )}
                  >
                    <span>{chefPartnerScore}</span>
                  </div>
                </a>
                <span>
                  <div className="text-xs text-grey-1">
                    Points<sup>®</sup>värde per portion
                  </div>
                </span>
              </div>
            )}
          </div>

          {hasHeadline ? (
            <>
              <h1 className="mt-4 lg:text-3xl">
                <strong>{recipe.recipeNameHeadline}</strong>
              </h1>
              <strong>{recipe.recipeNameSubheadline}</strong>
            </>
          ) : (
            <h1 className="mt-4 lg:text-3xl">
              <strong>{recipe.recipeName}</strong>
            </h1>
          )}

          <div className="flex my-4">
            <AverageDishRating
              language={language}
              numberOfRatings={recipe.numberOfRatings}
              averageRating={recipe.averageRating}
            />
          </div>

          <div className="grid grid-cols-12 gap-1">
            <RecipeInformation Icon={Clock} className="col-span-4">
              <strong>{`${intl.UNDER} ${cookingTime}`}</strong>
            </RecipeInformation>

            <RecipeInformation Icon={Milk} className="col-span-4">
              <strong>
                {numberOfIngredients} {intl.INGREDIENTS}
              </strong>
            </RecipeInformation>
          </div>

          {hasChefPartnerScore && BRAND_NAME === "LMK" && (
            <div className="pt-4 text-base">
              <p>
                <span>
                  Detta recept är ett samarbete med ViktVäktarna. Har du frågor
                  om receptet eller ViktVäktarnas program, hör av dig till{" "}
                  <a
                    href="mailto:matkasse@viktvaktarna.se"
                    className="underline"
                  >
                    matkasse@viktvaktarna.se
                  </a>
                </span>
              </p>
            </div>
          )}

          {recipe.recipeDescription && (
            <ReadMoreWrapper
              height="medium"
              variant="minimal"
              className="mt-6 mb-4 text-left lg:mt-4"
              minHeight={144}
              elementClassName="py-4"
            >
              {recipe.recipeDescription}
            </ReadMoreWrapper>
          )}

          {!recipe.recipeDescription && <div className="pt-4 lg:pt-16" />}

          {view === "conversion" && (
            <Link href={BRAND_PATHS.MY_MENU_LOGOUT_PAGE}>
              <Button onClick={onClose} primary>
                {intl.SEE_THE_WEEKLY_MENU}
              </Button>
            </Link>
          )}

          {hasControls && (
            <>
              <div className="grid grid-cols-12 gap-2 mt-8">
                <div
                  className={clsx(
                    hasExtraPrice &&
                      "order-2 md:col-start-1 md:order-1 col-start-5",
                    "col-span-8",
                  )}
                >
                  <Button
                    onClick={handleAddOrRemoveToBasket}
                    primary={isInBasket}
                    outlined={!isInBasket}
                    disabled={disableUpdateBasket}
                    full
                  >
                    {!isInBasket && <Plus />}{" "}
                    {isInBasket ? intl.THE_DISH_WAS_ADDED : intl.ADD}
                  </Button>
                </div>
                <div
                  className={clsx(
                    hasExtraPrice &&
                      "order-1 col-span-4 col-start-1 md:col-start-9 md:order-2 ",
                    "flex items-center",
                  )}
                >
                  <div className="flex flex-col justify-center ml-4">
                    <div className="text-xs">
                      {disableUpdateBasket ? (
                        <span>{intl.MAX_DISHES_ADDED}</span>
                      ) : (
                        <>
                          <div className="text-xs">
                            {normalPrice > 0 && intl.THIS_DISH}
                          </div>

                          <strong className="text-base">
                            <Currency
                              prefix={isNegativePrice ? "-" : "+"}
                              hideIfZero
                            >
                              {isPlusProteinInBasket ? plusPrice : normalPrice}
                            </Currency>
                          </strong>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {isInBasket && plusProteinVariation && (
                <button
                  onClick={() =>
                    addOrRemoveToBasket?.(
                      isPlusProteinInBasket
                        ? productVariation
                        : plusProteinVariation,
                    )
                  }
                  className={clsx(
                    "p-4 mt-6 border hover:cursor-pointer w-80 rounded",
                    isPlusProteinInBasket
                      ? " border-secondary bg-informationBG"
                      : "border-transparent bg-background",
                  )}
                >
                  <div className="flex items-center justify-between w-full text-sm underline hover:cursor-pointer">
                    <div className="flex items-center w-full ">
                      <input
                        type="checkbox"
                        id="ekstraProtein"
                        name="ekstraProtein"
                        className="mr-2 text-black hover:cursor-pointer"
                        checked={isPlusProteinInBasket}
                      />
                      <strong>
                        {isPlusProteinInBasket ? (
                          "PLUS (extra protein) tilføjet"
                        ) : (
                          <>
                            <span>Tilføj PLUS (ekstra protein) </span>
                            <Currency hideIfZero prefix="+">
                              {/* we want to display how much the plus adds compared to normal */}
                              {plusPrice - normalPrice}
                            </Currency>
                          </>
                        )}
                      </strong>
                    </div>
                    <Tooltip
                      className="text-sm w-80 -right-4 md:w-96 print:w-96 bottom-14"
                      element="PLUS giver dig ekstra protein. Et godt valg til dig, der er ekstra sulten eller bare vil have mere kød/fisk. Medfører en omkostning."
                      popperOptions={{
                        placement: "top",
                      }}
                    >
                      <span>
                        <Info className="w-6 h-6" />
                      </span>
                    </Tooltip>
                  </div>
                </button>
              )}
            </>
          )}

          {view === "static-conversion" && (
            <div className="mt-6 lg:mt-8">
              <Link
                href={BRAND_PATHS.HOW_IT_WORKS_PAGE}
                passHref
                legacyBehavior
              >
                <Button primary onClick={onClose}>
                  {brand.CTA_TEXT}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
