import { nb, sv, da } from "date-fns/locale";
import { language } from "@chef/constants";

export const locales = (
  {
    no: nb,
    se: sv,
    dk: da,
  } as const
)[language];
