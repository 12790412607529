import { useMeQuery } from "../features";
import {
  CalendarQuery,
  OrderQuery,
  PickAndMixQuery,
  useUserInfoQuery,
} from "../graphql/generated";
import {
  getAttribute,
  getRecipeFromPickAndMix,
  isFinancialProduct,
  isMealboxProduct,
  isPickAndMixProduct,
} from "../helpers";

interface Data {
  recipeIds: number[];
  week: number;
  year: number;
  name: string;
  portions: number;
  meals: number;
  agreementId: number;
}

type OrderType = OrderQuery["order"];
type CalendarType = CalendarQuery["calendar"][number]["baskets"][number];

type UseOrderDetailsProps =
  | { type: "order"; mainObject: OrderType }
  | {
      type: "deviation";
      mainObject: CalendarType | undefined;
      pickAndMix: PickAndMixQuery["pickAndMix"] | undefined;
    };

/**
 * A React hook that extracts and formats order details based on the provided type.
 *
 * This hook processes order or deviation (calendar) data and returns structured information
 * for sharing or further use.
 *
 * @param {UseOrderDetailsProps} props - The props determining the type of data to process.
 *   - If `type` is `"order"`, it processes `OrderType` data.
 *   - If `type` is `"deviation"`, it processes `CalendarType` data and requires `pickAndMix`.
 *
 * @returns {Object} An object containing:
 *   - `data`: The formatted order details or `null` if required data is missing.
 *   - `isReady`: A boolean indicating whether the data is available.
 *
 * @example
 * const { data, isReady } = useOrderDetails({ type: "order", mainObject: orderData });
 * if (isReady) {
 *   console.log(data.recipeIds); // Output: [123, 456, 789]
 * }
 */
export const useOrderDetails = (
  props: UseOrderDetailsProps,
): { data: Data | null; isReady: boolean } => {
  const { data: meQuery } = useMeQuery();
  const { data: userInfoQuery } = useUserInfoQuery();

  const name = userInfoQuery?.userInfo.firstName;
  const agreementId = meQuery?.agreementId;

  let recipeIds: number[];
  let portions: number;
  let meals: number;

  const { mainObject } = props;

  if (!mainObject || !name || !agreementId) {
    return { data: null, isReady: false };
  }

  const { week, year } = mainObject;

  if (props.type === "order") {
    const order = mainObject as OrderType;
    const recipes = order.orderLines.flatMap((ol) => ol.recipes);
    recipeIds = recipes.map((recipe) => recipe.recipeId) || [];
    meals = recipes.length;

    const product = order.orderLines.find(
      (ol) =>
        isMealboxProduct(ol.variation.product) ||
        isFinancialProduct(ol.variation.product) ||
        isPickAndMixProduct(ol.variation.product),
    );

    portions =
      (product && Number(getAttribute("Portions", product?.variation))) || 0;
  } else {
    const deviation = mainObject as CalendarType;
    const { pickAndMix } = props;

    if (!pickAndMix) {
      return { data: null, isReady: false };
    }

    const pickAndMixProductsInBasket =
      deviation.basketDetails.products.filter((p) =>
        isPickAndMixProduct(p.variation.product),
      ) || [];

    const recipesInBasket = pickAndMixProductsInBasket.map((bp) =>
      getRecipeFromPickAndMix({
        pickAndMix,
        productId: bp.variation.productId,
        variationId: bp.variationId,
      }),
    );

    const variation = pickAndMixProductsInBasket[0].variation;

    portions = (variation && Number(getAttribute("Portions", variation))) || 0;

    recipeIds = recipesInBasket
      .map((p) => p?.recipeId)
      .filter((r): r is number => r !== undefined);

    meals = recipeIds.length;
  }

  return {
    data: {
      recipeIds,
      week,
      year,
      name,
      portions,
      meals,
      agreementId,
    },
    isReady: true,
  };
};
