import { TimeblocksByRangeWithGeoQuery } from "../graphql/generated";

export const groupTimeblocksDataByWeekday = (
  timeblocksData: TimeblocksByRangeWithGeoQuery["timeblocksByRangeWithGeo"]["weeks"][0]["timeblocks"],
) => {
  if (!timeblocksData) {
    return [];
  }

  const groupedByWeekday = timeblocksData.reduce(
    (acc: { [key: number]: typeof timeblocksData }, timeblock) => {
      const weekday = timeblock.weekday;

      if (!acc[weekday]) {
        acc[weekday] = [];
      }

      acc[weekday].push(timeblock);

      return acc;
    },
    {} as { [key: number]: typeof timeblocksData },
  );

  return Object.entries(groupedByWeekday).map(([weekday, timeblocks]) => ({
    weekday: Number(weekday),
    timeblocks,
  }));
};
