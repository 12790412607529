import { BRAND_NAME, config, language } from "@chef/constants";

export const intl = (
  {
    no: {
      ADD: "Legg til",
      ADDED: "Lagt til!",
      MAX_DISHES_ADDED: `Du har allerede valgt ${config.order.maxMeals} retter`,
      THIS_DISH: "Denne retten",
      INGREDIENTS: "Ingredienser",
      THE_DISH_WAS_ADDED: "Retten er valgt",
      UNDER: `Under`,
      SEE_THE_WEEKLY_MENU: "Vis ukens meny",
    },
    se: {
      ADD: "Lägg till",
      ADDED: "Tillagd",
      MAX_DISHES_ADDED: `Du har redan valt ${config.order.maxMeals} rätter`,
      THIS_DISH: "Denna rätt",
      INGREDIENTS: "Ingredienser",
      THE_DISH_WAS_ADDED: "Rätten är vald",
      UNDER: `Under`,
      SEE_THE_WEEKLY_MENU: "Visa veckans meny",
    },
    dk: {
      ADD: "Tilføj",
      ADDED: "Tilføjet!",
      MAX_DISHES_ADDED: `Du har allerede valgt ${config.order.maxMeals} retter`,
      THIS_DISH: "Denne ret",
      INGREDIENTS: "Ingredienser",
      THE_DISH_WAS_ADDED: "Retten er valgt",
      UNDER: `Under`,
      SEE_THE_WEEKLY_MENU: "Vis ugens menu",
    },
  } as const
)[language];

export const brand = (
  {
    AMK: {
      CTA_TEXT: "Slik fungerer Adams Matkasse",
    },
    GL: {
      CTA_TEXT: "Slik fungerer Godtlevert",
    },
    LMK: {
      CTA_TEXT: "Så funkar Linas Matkasse",
    },
    RN: {
      CTA_TEXT: "Sådan fungerer RetNemt",
    },
  } as const
)[BRAND_NAME];
