import { useEffect } from "react";
import Link from "next/link";

import { Banner, Button, Card } from "@chef/components";
import { BRAND_PATHS, PAYMENT_PARTNER_IDS } from "@chef/constants";
import { useBillingQuery, useUserInfoQuery } from "@chef/state-management";
import { Modal } from "@chef/smart-components";
import { VippsBadge } from "@chef/icons/other";
import { useIntercom, useSessionState } from "@chef/hooks";
import { noop } from "@chef/utils/noop";
import { usePaymentSession } from "@chef/state-management/hooks";
import { isEqualStrings } from "@chef/utils/equal";
import { useFlag } from "@chef/feature-flags";
import { capitalize } from "@chef/utils/string";

import { Logo } from "../Logo";

export const OldVippsBanner = () => {
  const isPopupEnabled = useFlag("global_old_vipps_popup");
  const isPopupClosable = useFlag("global_old_vipps_popup_closable");

  const { data: billingQuery } = useBillingQuery();
  const { data: userInfoQuery } = useUserInfoQuery();

  const { createSession, isCreating } = usePaymentSession();

  const [isOpen, setIsOpen] = useSessionState("old_vipps_popup_open", true);

  const { showBubble, hideBubble } = useIntercom(!isOpen);

  useEffect(() => {
    if (isOpen) {
      hideBubble();

      return () => {
        showBubble();
      };
    } else {
      return noop;
    }
  }, [isOpen, showBubble, hideBubble]);

  if (!billingQuery || !userInfoQuery) {
    return null;
  }

  const { paymentPartnerId, currentPaymentCard } = billingQuery.billing;
  const { firstName } = userInfoQuery.userInfo;

  const isOldVipps =
    isEqualStrings(paymentPartnerId, PAYMENT_PARTNER_IDS.ADYEN) &&
    isEqualStrings(currentPaymentCard.tokenType, "vipps");

  if (!isOldVipps) {
    return null;
  }

  return (
    <>
      <Banner onClick={createSession} highlighted>
        <span className="mr-2">Vipps-avtalen løper snart ut.</span>
        <span className="underline">
          <strong>Forny</strong>
        </span>
      </Banner>

      <Modal
        name="old-vipps-payment-method"
        open={isPopupEnabled && isOpen}
        size="lg"
        onClose={() => setIsOpen(false)}
        closeable={isPopupClosable}
      >
        <Card className="h-screen pt-10 md:h-auto page-padding">
          <div className="flex flex-col h-full max-w-screen-sm gap-6 mx-auto md:max-w-screen-md">
            <VippsBadge className="xl:mt-10 w-36 h-9" />

            <h1>
              <strong>
                Hei {capitalize(firstName)}! Du har en gammel Vipps-avtale som
                er i ferd med å løpe ut.
              </strong>
            </h1>
            <p className="text-lg">
              Din nåværende Vipps-avtale vil snart ikke fungere lenger. Dersom
              betalingsmetoden ikke oppdateres vil du motta faktura med
              fakturagebyr på dine fremtidige bestillinger.
            </p>
            <p className="text-lg">
              Hvis du har spørsmål kan du kontakte{" "}
              <Link
                href={BRAND_PATHS.CONTACT_US_PAGE}
                onClick={() => setIsOpen(false)}
              >
                <strong className="underline">kundeservice her</strong>
              </Link>
              .
            </p>
            <Button onClick={createSession} loading={isCreating} primary full>
              Forny Vipps-avtalen
            </Button>
            <div aria-hidden="true" className="grow" />
            <div className="py-6">
              <Logo />
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};
