import { language } from "@chef/constants";

export const intl = {
  // TODO LGF-2096: make sure these translations are proper
  no: {
    CONSENT_SETTINGS_TITLE: "Denne nettsiden bruker cookies",
    CONSENT_SETTINGS_DESCRIPTION:
      "Nettsiden bruker cookies - noen cookies er nødvendige for at nettsiden skal fungere, mens andre gir oss informasjon om hvordan du bruker nettsiden, slik at vi kan foredre den for deg og andre. Cookies og personopplysninger brukes også for å vise deg tilpassede annonser.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_URL: "/informasjonskapsler",
    CONSENT_SETTINGS_ACCEPT_ALL_COOKIES: "Godta alle cookies",
    CONSENT_SETTINGS_ACCEPT_SELECTED_COOKIES: "Godta valgte cookies",
    CONSENT_SETTINGS_REJECT_ALL: "Avslå alle",
    CONSENT_SETTINGS_SAVE_AND_CLOSE: "Lagre valgte",
    CONSENT_SETTINGS_SHOW_DETAILS: "Vis detaljer",
    CONSENT_SETTINGS_COOKIE_DECLARATION_TITLE: "Forklaring",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_NAME: "Nødvendig",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_DESCRIPTION:
      "Vi bruker informasjonskapsler som er nødvendige for at nettstedet skal fungere som det skal.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_TEXT: "Les mer i vår cookiepolicy.",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_NAME: "Funksjonell",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_DESCRIPTION:
      "For å overvåke ytelsen til nettstedet vårt og forbedre nettleseropplevelsen din.",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_NAME: "Annonsering",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_DESCRIPTION:
      "For å tilpasse og måle effektiviteten av annonsering på vår og andre nettsider.",
    CONSENT_SETTINGS_CATEGORY_MARKETING_NAME: "Markedsføring og analyse",
    CONSENT_SETTINGS_CATEGORY_MARKETING_DESCRIPTION:
      "For å forstå brukeratferd for å gi deg en mer relevant nettopplevelse eller tilpasse innholdet på nettstedet vårt.",
    READ_MORE: "Les mer i",
    OUR_COOKIE_POLICY: "vår cookiepolicy",
    GOOGLE_PRIVACY_POLICY: "Googles nettsted for personvern og vilkår",
    AND: "og i",
  },
  se: {
    CONSENT_SETTINGS_TITLE: "Denna hemsida använder cookies",
    CONSENT_SETTINGS_DESCRIPTION:
      "Webbplatsen använder cookies. Vissa cookies är nödvändiga för att webbplatsen ska fungera, medan andra ger oss information om hur du använder webbplatsen så att vi kan förbättra den för dig och andra. Cookies och personuppgifter används också för att visa annonser som passar just dig.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_URL: "/cookiepolicy",
    CONSENT_SETTINGS_ACCEPT_ALL_COOKIES: "Acceptera alla",
    CONSENT_SETTINGS_ACCEPT_SELECTED_COOKIES: "Acceptera utvalda cookies",
    CONSENT_SETTINGS_REJECT_ALL: "Avvis alla",
    CONSENT_SETTINGS_SAVE_AND_CLOSE: "Spara & stäng",
    CONSENT_SETTINGS_SHOW_DETAILS: "Visa detaljer",
    CONSENT_SETTINGS_COOKIE_DECLARATION_TITLE: "Cookie-förklaring",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_NAME: "Nödvändiga",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_DESCRIPTION:
      "Vi använder webbläsarcookies som är nödvändiga för att sidan ska fungera som avsett.",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_NAME: "Funktionella",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_DESCRIPTION:
      "För att övervaka prestandan på vår webbplats och för att förbättra din surfupplevelse.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_TEXT: "Läs mer i vår cookiepolicy.",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_NAME: "Annonsering",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_DESCRIPTION:
      "För att anpassa och mäta effektiviteten av annonsering på vår webbplats och andra webbplatser.",
    CONSENT_SETTINGS_CATEGORY_MARKETING_NAME: "Marknadsföring och analys",
    CONSENT_SETTINGS_CATEGORY_MARKETING_DESCRIPTION:
      "För att förstå användarbeteende för att ge dig en mer relevant webbupplevelse eller anpassa innehållet på vår webbplats.",
    READ_MORE: "Läs mer i",
    OUR_COOKIE_POLICY: "vår cookiepolicy",
    GOOGLE_PRIVACY_POLICY: "Googles sekretess och användarvillkor",
    AND: "och i",
  },
  dk: {
    CONSENT_SETTINGS_TITLE: "Denne hjemside bruger cookies",
    CONSENT_SETTINGS_DESCRIPTION:
      "Denne hjemmeside bruger cookies. Hjemmesiden bruger cookies. Nogle er nødvendige for, at hjemmesiden fungerer korrekt, mens andre hjælper os med at forstå, hvordan du anvender hjemmesiden, så vi kan forbedre den for dig og andre brugere. Cookies og persondata anvendes også til målrettet annoncering.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_URL: "/cookiepolitik",
    CONSENT_SETTINGS_ACCEPT_ALL_COOKIES: "Tillad alle",
    CONSENT_SETTINGS_ACCEPT_SELECTED_COOKIES: "Tillad udvalgte cookies",
    CONSENT_SETTINGS_REJECT_ALL: "Afvis alle",
    CONSENT_SETTINGS_SAVE_AND_CLOSE: "Gem og luk",
    CONSENT_SETTINGS_SHOW_DETAILS: "Vis detaljer",
    CONSENT_SETTINGS_COOKIE_DECLARATION_TITLE: "Cookie-erklæring",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_NAME: "Nødvendig",
    CONSENT_SETTINGS_CATEGORY_ESSENTIAL_DESCRIPTION:
      "Vi bruger browsercookies, som er nødvendige for, at hjemmesiden kan fungere efter hensigten.",
    CONSENT_SETTINGS_COOKIE_POLICY_LINK_TEXT: "Læs mere i vores cookiepolitik.",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_NAME: "Funktionel",
    CONSENT_SETTINGS_CATEGORY_FUNCTIONAL_DESCRIPTION:
      "For at overvåge ydeevnen af ​​vores hjemmeside og forbedre din browseroplevelse.",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_NAME: "Annoncering",
    CONSENT_SETTINGS_CATEGORY_ADVERTISING_DESCRIPTION:
      "At tilpasse og måle effektiviteten af annoncering på vores hjemmeside og andre hjemmesider.",
    CONSENT_SETTINGS_CATEGORY_MARKETING_NAME: "Markedsføring og analyse",
    CONSENT_SETTINGS_CATEGORY_MARKETING_DESCRIPTION:
      "For at forstå brugeradfærd samt give dig en mere relevant onlineoplevelse eller tilpasse indholdet af vores hjemmeside.",
    READ_MORE: "Læs mere i",
    OUR_COOKIE_POLICY: "vores cookiepolitik",
    GOOGLE_PRIVACY_POLICY: "Googles privatlivspolitik og vilkår",
    AND: "og i",
  },
}[language];
