import { useCallback, useContext } from "react";
import { AnalyticsContext } from "./useAnalytics";

export const useIdentify = () => {
  const ctx = useContext(AnalyticsContext);

  if (!ctx) {
    throw new Error("useIdentify used outside of TrackingProvider");
  }

  const fn = useCallback(
    (options: Record<string, any>) => {
      return ctx.queue.push({ type: "identify", options });
    },
    [ctx.queue],
  );

  return fn;
};
