import Link from "next/link";

import { useMeQuery } from "@chef/state-management";
import { BRAND_PATHS, language } from "@chef/constants";
import { Button } from "@chef/components";
import { useEffectOnce } from "@chef/hooks";
import { useTrack } from "@chef/state-management/hooks";

import { AppShell } from "../AppShell";

export const InternalServerErrorPage = () => {
  const track = useTrack();
  const { data: isLoggedIn } = useMeQuery();

  useEffectOnce(() => {
    track("internalServerError", {
      affiliation: "Frontend process",
      statusCode: 500,
    });
  }, true);

  return (
    <AppShell title={intl.INTERNAL_SERVER_ERROR}>
      <AppShell.Meta />

      <AppShell.Header />

      <AppShell.Main>
        <aside>
          <code>{intl.ERROR_CODE} 500</code>
        </aside>
        <h1 className="my-4 lg:my-6">
          <strong>{intl.INTERNAL_SERVER_ERROR}</strong>
        </h1>
        <div className="max-w-lg space-y-4 lg:space-y-6">
          <p>{intl.SOMETHING_WENT_WRONG_WHILE_TRYING_TO_ACCESS_THIS_PAGE}</p>
          <p>
            {intl.FOR_ASSISTANCE_PLEASE_CONTACT}
            <Link
              href={BRAND_PATHS.CONTACT_US_PAGE_LOGOUT}
              className="underline"
            >
              {intl.CUSTOMER_SEVICE}
            </Link>
            .
          </p>
        </div>
        <div className="mt-8 space-x-2 lg:mt-12">
          <Button onClick={() => window.history.back()} outlined>
            {intl.BACK}
          </Button>
          <Link
            href={
              isLoggedIn
                ? BRAND_PATHS.MY_MENU_PAGE
                : BRAND_PATHS.MY_MENU_LOGOUT_PAGE
            }
            passHref
            legacyBehavior
          >
            <Button primary>{intl.SEE_THIS_WEEKS_MEALS}</Button>
          </Link>
        </div>
      </AppShell.Main>
      <AppShell.Footer />
    </AppShell>
  );
};

const intl = (
  {
    no: {
      ERROR_CODE: "Feilkode",
      INTERNAL_SERVER_ERROR: "Intern serverfeil",
      SOMETHING_WENT_WRONG_WHILE_TRYING_TO_ACCESS_THIS_PAGE:
        "Noe gikk galt når vi prøvde å nå denne siden.",
      FOR_ASSISTANCE_PLEASE_CONTACT: "For assistanse, vennligst kontakt ",
      CUSTOMER_SEVICE: "kundeservice",
      BACK: "Tilbake",
      SEE_THIS_WEEKS_MEALS: "Se ukens retter",
    },
    se: {
      ERROR_CODE: "Felkod",
      INTERNAL_SERVER_ERROR: "Intern serverfel",
      SOMETHING_WENT_WRONG_WHILE_TRYING_TO_ACCESS_THIS_PAGE:
        "Något gick fel när vi försökte nå denna sida.",
      FOR_ASSISTANCE_PLEASE_CONTACT: "För hjälp, vänligen kontakta ",
      CUSTOMER_SEVICE: "kundtjänst",
      BACK: "Tillbaka",
      SEE_THIS_WEEKS_MEALS: "Se veckans rätter",
    },
    dk: {
      ERROR_CODE: "Fejlkode",
      INTERNAL_SERVER_ERROR: "Intern serverfejl",
      SOMETHING_WENT_WRONG_WHILE_TRYING_TO_ACCESS_THIS_PAGE:
        "Noget gik galt, da vi forsøgte at tilgå denne side.",
      FOR_ASSISTANCE_PLEASE_CONTACT: "For hjælp, kontakt venligst ",
      CUSTOMER_SEVICE: "kundeservice",
      BACK: "Tilbage",
    },
  } as const
)[language];
