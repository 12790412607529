import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ChatBox, RadioButtonBar } from "@chef/components";
import {
  ProductsByIdsQuery,
  selectSignupCoupon,
  useMeQuery,
} from "@chef/state-management";
import {
  getAttribute,
  getDiscountPrice,
  getMealsFromVariations,
  getPortionsFromVariations,
  getPriceOfProductByVariation,
} from "@chef/state-management/helpers";
import { Discount } from "@chef/icons/small";
import { isNotEmptyArray } from "@chef/utils/array";

import { CurrencyWrapper as Currency } from "../CurrencyWrapper";

import { intl } from "./PortionsMealsSelect.Intl";

interface PortionsMealsSelectProps {
  mealboxProduct?: ProductsByIdsQuery["products"][0];
  showPriceOverview?: boolean;
  couponAmount?: number;
  animation?: "none" | "default";
  setParams?: (params: { meals?: number; portions?: number }) => void;
}

export const PortionsMealsSelect = ({
  mealboxProduct,
  showPriceOverview = true,
  couponAmount = 0,
  animation = "none",
}: PortionsMealsSelectProps) => {
  const { control, watch, setValue } = useFormContext();

  const [selectedPortions, selectedMeals] = watch(["portions", "meals"]);

  const { data: user } = useMeQuery();
  const isLoggedIn = !!user;

  const portionOptions = getPortionsFromVariations({
    variations: mealboxProduct?.variations,
  });

  const mealsOptions = getMealsFromVariations({
    variations: mealboxProduct?.variations,
    portions: selectedPortions,
  });

  const couponDetails = useSelector(selectSignupCoupon);

  useEffect(() => {
    if (
      isNotEmptyArray(mealsOptions) &&
      !mealsOptions.includes(selectedMeals)
    ) {
      setValue("meals", mealsOptions[0]);
    }
  }, [selectedMeals, mealsOptions, setValue]);

  useEffect(() => {
    if (selectedPortions === 1) {
      setValue("conceptPreferenceIds", []);
      setValue("tastePreferenceIds", []);
    }
  }, [selectedPortions, setValue]);

  if (!mealboxProduct) {
    return null;
  }

  const selectedVariation = mealboxProduct.variations.find(
    (v) =>
      getAttribute("Portions", v) === selectedPortions?.toString() &&
      getAttribute("Meals", v) === selectedMeals?.toString(),
  );

  const variationPrice =
    (selectedVariation && getPriceOfProductByVariation(selectedVariation)) || 0;

  const { flexiblePriceWithDiscount, isPriceDiscounted } = getDiscountPrice({
    variationPrice,
    couponAmount,
    couponDetails: couponDetails || undefined,
    type: isLoggedIn ? "logged-in" : "signup",
  });

  const totalAmountOfPortions = selectedMeals * selectedPortions;

  const regularPricePerPortion = variationPrice / totalAmountOfPortions;
  const discountPricePerPortion =
    flexiblePriceWithDiscount / totalAmountOfPortions;

  const finalPrice = selectedVariation?.finalPrice;

  return (
    <div className="lg:grid lg:grid-cols-2">
      <div className="flex flex-col gap-8 lg:pr-2">
        <div className="flex flex-col gap-4" id="portions-select">
          <p className="text-sm">
            <strong>{intl.NUMBER_OF_PORTIONS}</strong>
          </p>
          <Controller
            control={control}
            name="portions"
            render={({ field }) => (
              <RadioButtonBar
                hasCheckmark
                animation={animation}
                options={portionOptions.map((p) => ({
                  value: p,
                  name: p.toString(),
                }))}
                onChange={(value) => {
                  field.onChange(value);
                }}
                value={selectedPortions || field.value}
                suffix={intl.PORTIONS}
                name="portions"
              />
            )}
          />
          <p className="text-xs">{intl.NUMBER_OF_PORTIONS_DESCRIPTION}</p>
        </div>
        <div className="flex flex-col gap-4" id="meals-select">
          <p className="text-sm">
            <strong>{intl.NUMBER_OF_MEALS}</strong>
          </p>
          <Controller
            control={control}
            name="meals"
            render={({ field }) => (
              <RadioButtonBar
                animation={animation}
                options={mealsOptions.map((m) => ({
                  value: m,
                  name: m.toString(),
                }))}
                onChange={(value) => {
                  field.onChange(value);
                }}
                value={selectedMeals || field.value}
                suffix={intl.MEALS}
                name="meals"
                hasCheckmark
              />
            )}
          />
          <p className="text-xs">{intl.NUMBER_OF_MEALS_DESCRIPTION}</p>
        </div>
      </div>
      {showPriceOverview && (
        <div className="lg:pl-6 lg:pt-5 mb-4 lg:border-l-1.5 lg:border-grey-3">
          <ChatBox
            id="price-overview-card"
            className="flex-col lg:triangle-left"
            background="grey-3"
            noPadding
          >
            <div className="flex flex-col gap-1 p-4">
              {isPriceDiscounted && (
                <Discount className="absolute text-white -top-2 -right-2 md:-top-3 md:-right-3 w-9 h-9 fill-error" />
              )}
              <p className="mb-1 text-sm text-left" id="price-overview-card">
                <strong>{intl.MEALKIT_SUBSCRIPTION}</strong>
              </p>
              <p className="text-sm text-left">
                {selectedMeals} {intl.MEALS} {intl.FOR} {selectedPortions}{" "}
                {intl.PEOPLE_PER_WEEK}
              </p>
              <p className="text-sm text-left">
                {totalAmountOfPortions} {intl.TOTAL_PORTIONS}
              </p>
              <hr className="my-2 text-grey-1" />
              <div className="flex justify-between text-sm">
                <p>{intl.REGULAR_PRICE}</p>
                <Currency>{variationPrice}</Currency>
              </div>
              <div className="flex justify-between text-sm">
                <p>{intl.PRICE_PER_PORTION}</p>
                <span className="flex gap-4">
                  {isPriceDiscounted && (
                    <Currency className="line-through text-grey-1">
                      {regularPricePerPortion}
                    </Currency>
                  )}
                  <Currency
                    className={clsx(isPriceDiscounted && "text-discount")}
                  >
                    {discountPricePerPortion}
                  </Currency>
                </span>
              </div>
            </div>
            <div className="flex justify-between px-4 py-2 text-sm rounded-b bg-grey-2">
              <p>
                <strong>{intl.TOTAL}</strong>
              </p>
              <span className="flex gap-4">
                {isPriceDiscounted && (
                  <Currency className="line-through text-grey-1">
                    {finalPrice}
                  </Currency>
                )}
                <Currency
                  className={clsx(isPriceDiscounted && "text-discount")}
                >
                  {flexiblePriceWithDiscount}
                </Currency>
              </span>
            </div>
          </ChatBox>
          {!isPriceDiscounted && (
            <p className="pt-4 text-xs">
              {intl.TOTAL_PRICE} <strong>{intl.BEFORE_DISCOUNTS}</strong>{" "}
              {intl.DEDUCTED_YOU_CAN_SEE_DISCOUNTS_ON_ACCOUNT}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
