import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Gradient } from "./Gradient";

interface ReadMoreButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  id?: string;
}

interface ReadMoreWrapperBaseProps {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
  height?: "short" | "medium" | "long";
  background?: "white" | "background";
  buttonId?: string;
  disabled?: boolean;
}

type ExpandableContentProps =
  | {
      minHeight?: number;
      elementClassName?: string;
    }
  | {
      minHeight?: never;
      elementClassName?: never;
    };

type ReadMoreWrapperProps = ReadMoreWrapperBaseProps &
  ExpandableContentProps &
  (
    | {
        variant?: "default";
        moreButtonText: string;
        lessButtonText: string;
      }
    | {
        variant: "minimal";
        moreButtonText?: never;
        lessButtonText?: never;
      }
    | {
        variant: "hideControl";
        moreButtonText?: never;
        lessButtonText: string;
      }
  );

const ReadMoreButton = ({
  onClick,
  children,
  className,
  id,
}: ReadMoreButtonProps) => {
  return (
    <button onClick={onClick} className={clsx(className, "w-full")} id={id}>
      <div>{children}</div>
    </button>
  );
};

export const ReadMoreWrapper = ({
  height = "medium",
  variant = "default",
  background = "white",
  lessButtonText,
  moreButtonText,
  buttonId,
  className,
  innerClassName,
  elementClassName,
  children,
  minHeight,
  disabled,
}: ReadMoreWrapperProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isElementHeightWithinLimit, setIsElementHeightWithinLimit] =
    useState(false);

  const readMoreRef = React.useRef<HTMLDivElement | null>(null);

  const checkIfElementHeightIsWithinLimit = (
    ref: React.RefObject<HTMLDivElement>,
    minHeight: number,
  ) => {
    if (!ref.current || !minHeight) {
      return false;
    }

    console.log(ref.current.clientHeight, minHeight);

    return ref.current.clientHeight <= minHeight;
  };

  useEffect(() => {
    if (!minHeight) {
      return;
    }

    const handleResize = () => {
      if (readMoreRef.current) {
        setIsElementHeightWithinLimit(
          checkIfElementHeightIsWithinLimit(readMoreRef, minHeight),
        );
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minHeight]);

  if (isElementHeightWithinLimit || disabled) {
    return <div className={clsx(elementClassName)}>{children}</div>;
  }

  if (variant === "minimal") {
    return (
      <details onToggle={() => setIsExpanded(true)}>
        <summary
          className={clsx(
            isExpanded ? "cursor-text pointer-events-none" : "cursor-pointer",
            "list-none [&::-webkit-details-marker]:hidden",
          )}
        >
          <Gradient
            height={height}
            background={background}
            isExpanded={isExpanded}
            className={className}
          >
            <div ref={readMoreRef}>{children}</div>
          </Gradient>
        </summary>
      </details>
    );
  }

  if (variant === "hideControl") {
    return (
      <>
        <details onToggle={() => setIsExpanded(true)}>
          <summary
            className={clsx(
              isExpanded ? "cursor-text pointer-events-none" : "cursor-pointer",
              "list-none [&::-webkit-details-marker]:hidden",
            )}
          >
            <Gradient
              height={height}
              background={background}
              isExpanded={isExpanded}
              className={className}
            >
              <div ref={readMoreRef}>{children}</div>
            </Gradient>
          </summary>
        </details>

        <ReadMoreButton
          onClick={() => setIsExpanded(false)}
          className={clsx(innerClassName)}
        >
          <div>{lessButtonText}</div>
        </ReadMoreButton>
      </>
    );
  }

  return (
    <div
      className={clsx({
        "bg-white": background === "white",
        "bg-background": background === "background",
      })}
    >
      <Gradient
        height={height}
        background={background}
        isExpanded={isExpanded}
        className={className}
      >
        <div ref={readMoreRef}> {children}</div>
      </Gradient>

      <ReadMoreButton
        onClick={() => setIsExpanded(!isExpanded)}
        className={clsx(innerClassName)}
        id={buttonId}
      >
        {isExpanded ? lessButtonText : moreButtonText}
      </ReadMoreButton>
    </div>
  );
};
