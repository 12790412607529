import { ICategories, IDestination } from "@chef/state-management";

import { CATEGORIES } from "./categories";

export const convertCategoriesToIntegrations = (
  consents: ICategories,
  destinations: IDestination[],
) => {
  const integrations: Record<string, boolean> = {
    All: consents.All,
    "Segment.io": true,
  };

  for (const destination of destinations) {
    const category = CATEGORIES.find((c) =>
      c.types.includes(destination.category),
    );

    if (!category || !(category.key in consents)) {
      continue;
    }

    integrations[destination.name] = consents[category.key];
  }

  return integrations;
};
