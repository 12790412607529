import { isPickAndMixProduct, isMealboxProduct } from "@chef/helpers";
import { CalendarQuery } from "@chef/state-management";
import {
  getRecipeFromPickAndMix,
  getAttribute,
} from "@chef/state-management/helpers";
import { useCalendarData } from "@chef/state-management/hooks";
import { isEqualStrings } from "@chef/utils/equal";
import { PRODUCT_IDS, VARIATION_IDS } from "@chef/constants";
import { sumByProperty } from "@chef/utils/array";

import { Meals } from "../shared/Meals";
import { useFinancesForCalendarWeek } from "./useFinancesForCalendarWeek";

interface CalendarWeekMealsProps {
  calendarWeek: CalendarQuery["calendar"][number];
  initiallyExpanded?: boolean;
}

export const CalendarWeekMeals = ({
  calendarWeek,
  initiallyExpanded,
}: CalendarWeekMealsProps) => {
  const { basket, pickAndMix } = useCalendarData({
    week: calendarWeek.week,
    year: calendarWeek.year,
  });

  const calendarBasket = calendarWeek.baskets.find(
    (basket) =>
      basket.year === calendarWeek.year && basket.week === calendarWeek.week,
  );

  const finances = useFinancesForCalendarWeek({ calendarWeek });


  if (!pickAndMix) {
    return null;
  }

  const pickAndMixProductsWithRecipes = basket
    .filter((p) => isPickAndMixProduct(p))
    .map((bp) => ({
      ...bp,
      recipe: getRecipeFromPickAndMix({
        pickAndMix,
        productId: bp.productId,
        variationId: bp.variationId,
      }),
    }));

  // We have to use the basket from calendar because the basket from useCalendarData does not have the full product info
  const basketFinancialOrMealbox = calendarBasket?.basketDetails.products.find(
    (p) => isMealboxProduct(p.variation.product),
  );
  const portions = +(
    getAttribute(
      "Portions",
      basketFinancialOrMealbox?.variation || { attributes: [] },
    ) || 0
  );

  const extraPrice = sumByProperty(pickAndMixProductsWithRecipes, "price");

  const isOneSubAndNotSingle = basket.some(
    (product) =>
      isEqualStrings(product.productId, PRODUCT_IDS.ONESUB) &&
      !isEqualStrings(product.variationId, VARIATION_IDS.ONESUB_SINGLE),
  );

  return (
    <Meals
      dishes={pickAndMixProductsWithRecipes.map((product) => ({
        name: product.recipe?.recipeName,
        extraPrice: product.price,
      }))}
      // We will keep finances.financil to make the price logic compatible with the old one, but can be removed when we replace it with OneSub
      priceOfMeals={finances.mealbox + extraPrice}
      portions={portions}
      initiallyExpanded={initiallyExpanded}
      minMeals={isOneSubAndNotSingle ? 2 : undefined}
    />
  );
};
