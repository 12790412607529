import { useMemo } from "react";

import { PRODUCT_TYPE_IDS } from "@chef/constants";
import { getNextCutoff, isPickAndMixProduct } from "@chef/helpers";

import {
  useCalendarQuery,
  usePickAndMixQuery,
  useProductCategoriesQuery,
  useProductsByCategoriesQuery,
} from "../graphql/generated";

import { IDeviationProduct } from "../features";

import {
  getBasketDataFromCalendar,
  getPriceOfProductByVariation,
  getStandaloneProductCategoryIdsFromProductCategories,
} from "../helpers";

import { usePickAndMixPrice } from "./usePickAndMixPrice";

export const useCalendarData = (args: {
  week: number;
  year: number;
  skip?: boolean;
  range?: number;
}) => {
  const { week, year, range = 5 } = args;

  const skip = !week || !year || args.skip;

  const { week: currentWeek, year: currentYear } = getNextCutoff();

  const { data: { calendar } = {}, ...calendarQuery } = useCalendarQuery(
    {
      week: currentWeek,
      year: currentYear,
      range,
    },
    { skip },
  );

  const { data: { pickAndMix } = {}, ...pickAndMixQuery } = usePickAndMixQuery(
    {
      productTypeId: PRODUCT_TYPE_IDS.PICKANDMIX,
      week,
      year,
    },
    { skip },
  );

  const { data: { productCategories } = {}, ...productCategoriesQuery } =
    useProductCategoriesQuery();

  const { data: { productsByCategories } = {}, ...productsByCategoriesQuery } =
    useProductsByCategoriesQuery(
      {
        categoryIds: getStandaloneProductCategoryIdsFromProductCategories(
          productCategories || [],
        ),
        week,
        year,
      },
      { skip: skip || !productCategories },
    );

  const { getPriceOfPickAndMixProduct, ...pickAndMixPriceHook } =
    usePickAndMixPrice({
      week,
      year,
      skip,
    });

  const basket = useMemo<IDeviationProduct[]>(() => {
    if (
      !calendar ||
      !pickAndMix ||
      !productsByCategories ||
      !productCategories
    ) {
      return [];
    }

    const calendarBasket = getBasketDataFromCalendar({
      calendar,
      week,
      year,
    });

    if (!calendarBasket) {
      return [];
    }

    return calendarBasket.basketDetails.products
      .map((product) => ({
        productId: product.variation.product.productId,
        productTypeId: product.variation.product.productTypeId,
        quantity: product.quantity,
        variationId: product.variationId,
        price: getPriceOfProductByVariation(product.variation),
      }))
      .map((bp, _, basketProducts) => {
        if (!isPickAndMixProduct(bp)) {
          return bp;
        }

        return {
          ...bp,
          price:
            getPriceOfPickAndMixProduct({
              basketProducts: basketProducts,
              variationId: bp.variationId,
            }) || 0,
        };
      });
  }, [
    calendar,
    getPriceOfPickAndMixProduct,
    pickAndMix,
    productsByCategories,
    productCategories,
    week,
    year,
  ]);

  return {
    basket,

    calendar: calendarQuery.currentData?.calendar,
    pickAndMix: pickAndMixQuery.currentData?.pickAndMix,
    productsByCategories:
      productsByCategoriesQuery.currentData?.productsByCategories,
    productCategories: productCategoriesQuery.currentData?.productCategories,

    calendarError: calendarQuery?.error,

    isLoading:
      calendarQuery.isLoading ||
      pickAndMixQuery.isLoading ||
      productsByCategoriesQuery.isLoading ||
      productCategoriesQuery.isLoading ||
      pickAndMixPriceHook.isLoading,
    isFetching:
      calendarQuery.isFetching ||
      pickAndMixQuery.isFetching ||
      productsByCategoriesQuery.isFetching ||
      productCategoriesQuery.isFetching ||
      pickAndMixPriceHook.isFetching,
    isError:
      calendarQuery.isError ||
      pickAndMixQuery.isError ||
      productsByCategoriesQuery.isError ||
      productCategoriesQuery.isError ||
      pickAndMixPriceHook.isError,
    isSuccess:
      calendarQuery.isSuccess &&
      pickAndMixQuery.isSuccess &&
      productCategoriesQuery.isSuccess &&
      productsByCategoriesQuery.isSuccess,
  };
};
