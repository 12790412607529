import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import { Button, Checkbox, Rating, Textarea } from "@chef/components";
import {
  useDeleteCommentFromRecipeMutation,
  useRateAndCommentRecipeMutation,
} from "@chef/state-management";
import { useExtendedForm } from "@chef/hooks";
import { ChevronDown } from "@chef/icons/small";
import { useMobileApp } from "@chef/feature-mobile-app";
import { useAppRatingAction } from "@chef/state-management/hooks";

import { intl } from "./RatingAndCommentContainer.Intl";

interface DisclosureProps {
  Text?: () => JSX.Element;
  open: boolean;
  onSetOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

const Disclosure = ({
  Text,
  open,
  children,
  onSetOpen = () => null,
}: DisclosureProps) => {
  return (
    <div className="w-full">
      <button
        onClick={() => onSetOpen(!open)}
        className="flex items-center justify-start w-full text-left"
      >
        {Text && <Text />}
        <ChevronDown
          className={clsx(
            "transition-all ease-in-out",
            open && "transform rotate-180",
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt={open ? intl.CLOSE : intl.OPEN}
        />
      </button>
      <div
        className={clsx("transition-all ease-in-out", open ? "pt-2" : "hidden")}
      >
        {children}
      </div>
    </div>
  );
};

interface RatingAndCommentContainerProps {
  display?: boolean;
  initialRating?: number;
  recipeId: number;
  initialComment?: string;
  id?: string;
}
interface IRatingAndCommentForm {
  comment: string;
  rating?: number;
}

const schema = yup.object({
  newComment: yup.string(),
  newRating: yup.number().min(0).max(5),
});

type RatingAndCommentCardState =
  | "default"
  | "not-cooked"
  | "ongoing"
  | "saved-compressed"
  | "saved-no-comment"
  | "saved-expanded";

const ensureIsInState = (
  states: RatingAndCommentCardState[],
  state: RatingAndCommentCardState,
) => {
  return states.includes(state);
};

export const RatingAndCommentContainer = ({
  initialRating,
  id,
  recipeId,
  initialComment,
  display = false,
}: RatingAndCommentContainerProps) => {
  const { isApp, sendAppMessage } = useMobileApp();
  const [userDefinedState, setUserDefinedState] =
    useState<
      Extract<
        RatingAndCommentCardState,
        "saved-expanded" | "saved-compressed" | "ongoing"
      >
    >();
  const { triggerAppRatingAction } = useAppRatingAction(isApp, sendAppMessage);

  const [rateAndCommentRecipeMutation] = useRateAndCommentRecipeMutation();

  const [deleteCommentMutation] = useDeleteCommentFromRecipeMutation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isSubmitting },
  } = useExtendedForm<IRatingAndCommentForm>({
    defaultValues: {
      comment: initialComment || "",
      rating: initialRating,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    reset({ comment: initialComment || "", rating: initialRating });
  }, [initialRating, initialComment]);

  const { comment, rating } = getValues();

  const haveNotMadeDish = rating === 0;

  let cardState: RatingAndCommentCardState = "default";

  // Compute the state based on variables
  if (!initialRating) {
    cardState = "default";
  } else {
    if (haveNotMadeDish) {
      cardState = "not-cooked";
    } else {
      if (comment) {
        cardState = "saved-compressed";
      } else {
        cardState = "saved-no-comment";
      }
    }
  }

  // If the user did anything, we override the computed state with the defined user state
  if (userDefinedState !== undefined) {
    cardState = userDefinedState;
  }

  const handleRatingClicked = (value: number) => {
    setValue("rating", value);
    setUserDefinedState("ongoing");
  };

  const deleteComment = async () => {
    try {
      await deleteCommentMutation({
        Recipe: recipeId,
      }).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const rateAndComment = async () => {
    try {
      await rateAndCommentRecipeMutation({
        RateCommentRecipes: [
          {
            recipe: recipeId,
            rating,
            comment,
          },
        ],
      }).unwrap();

      //TODO: need to test in app this changes
      triggerAppRatingAction();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmitRatingAndComment = async () => {
    if (comment?.length === 0 && initialComment) {
      await deleteComment();
    }
    if (
      rating !== initialRating ||
      (comment !== undefined && comment?.length > 0)
    ) {
      await rateAndComment();
    }

    setUserDefinedState(undefined);
  };

  const handleTickedHaveNotMadeDish = async () => {
    setValue("rating", 0);
    if (initialRating !== 0) {
      try {
        await rateAndCommentRecipeMutation({
          RateCommentRecipes: [
            {
              recipe: recipeId,
              rating: 0,
              comment: "",
            },
          ],
        }).unwrap();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const hasAlreadyRated = initialRating && initialRating > 0;

  if (!display) {
    return null;
  }

  return (
    <section id={id}>
      <div className="px-2 py-4 lg:px-4 bg-background">
        <span className="text-xs">
          {ensureIsInState(
            ["saved-compressed", "saved-no-comment", "saved-expanded"],
            cardState,
          ) ? (
            <strong>{intl.THANK_YOU_FOR_RATING}</strong>
          ) : (
            <strong>{intl.GIVE_US_YOUR_RATING}</strong>
          )}
        </span>

        <div className="flex flex-row items-center mt-2">
          <Rating
            id={`rating-${recipeId}`}
            value={rating}
            numOptions={5}
            className="flex w-full -ml-1 text-xl gap-x-0.5"
            defaultValue={0}
            size={28}
            onChange={handleRatingClicked}
          />
        </div>

        {ensureIsInState(["ongoing"], cardState) && (
          <div className="mt-4">
            <Textarea
              id={`comment-${recipeId}`}
              {...register("comment")}
              placeholder={intl.ADD_A_COMMENT}
              className="text-xs"
            />
            <Button
              id="save-rating-and-comment"
              full
              primary
              loading={isSubmitting}
              onClick={handleSubmit(handleSubmitRatingAndComment)}
            >
              {intl.SAVE}
            </Button>
          </div>
        )}

        {ensureIsInState(["saved-expanded", "saved-compressed"], cardState) && (
          <div className="mt-7">
            <Disclosure
              Text={() => (
                <span className="w-full text-xs">
                  <strong>
                    {userDefinedState === "saved-expanded"
                      ? intl.HIDE_COMMENT
                      : intl.SHOW_COMMENT}
                  </strong>
                </span>
              )}
              open={userDefinedState === "saved-expanded"}
              onSetOpen={() => {
                if (userDefinedState === "saved-expanded") {
                  setUserDefinedState("saved-compressed");
                } else {
                  setUserDefinedState("saved-expanded");
                }
              }}
            >
              <div className="text-xs break-words">{initialComment}</div>

              <div
                className="w-full mt-4 text-xs underline cursor-pointer"
                onClick={() => setUserDefinedState("ongoing")}
              >
                <strong>{intl.CHANGE_COMMENT}</strong>
              </div>
            </Disclosure>
          </div>
        )}

        {ensureIsInState(["saved-no-comment"], cardState) && (
          <div
            className="w-full text-xs underline cursor-pointer mt-7"
            onClick={() => setUserDefinedState("ongoing")}
          >
            <strong>{intl.ADD_A_COMMENT}</strong>
          </div>
        )}
      </div>

      {ensureIsInState(["default", "not-cooked", "ongoing"], cardState) &&
        !hasAlreadyRated && (
          <div
            className={clsx(
              "px-2 py-4 lg:px-4",
              haveNotMadeDish && "bg-grey-3",
            )}
          >
            <Checkbox
              className="items-center flex-shrink-0"
              checked={haveNotMadeDish}
              onChange={() => {
                handleTickedHaveNotMadeDish();
                setUserDefinedState(undefined);
              }}
              innerClassName="text-xs truncate max-w-max my-auto"
            >
              {intl.HAVE_NOT_MADE_THE_DISH}
            </Checkbox>
          </div>
        )}
    </section>
  );
};
