import {
  IconCalendarTime,
  IconHeartStar,
  IconTruckDelivery,
} from "@tabler/icons-react";
import { useState } from "react";
import Link from "next/link";
import { useDispatch } from "react-redux";

import { getNextCutoff, getNextCutoffs } from "@chef/helpers";
import {
  RecipeAndStepsWithAvailableMenuWeeksQuery,
  resetRecipeModal,
} from "@chef/state-management";
import { isEmptyArray } from "@chef/utils/array";
import { AnimatedCheck, Button } from "@chef/components";
import { getImageFromImages } from "@chef/state-management/helpers";
import { BRAND_PATHS } from "@chef/constants";
import { useFlag } from "@chef/feature-flags";
import { useTrack } from "@chef/state-management/hooks";

import { intl } from "./AvailableMenuWeeks.Intl";

type Recipe = RecipeAndStepsWithAvailableMenuWeeksQuery["recipeAndSteps"];

interface AvailableMenuWeeksProps {
  recipe: Recipe;
  selectedPortionsSize: number;
}

interface BackOnTheMenuProps {
  week: number;
  year: number;
  recipe: Recipe;
}

interface WishlistProps {
  recipe: Recipe;
}

const Wishlist = ({ recipe }: WishlistProps) => {
  const track = useTrack({ serverSide: true });
  const [clicked, setClicked] = useState(false);

  const enabled = useFlag("global_wishlist");

  const handleWishlist = () => {
    setClicked(true);

    track("recipeWishlisted", {
      affiliation: "Frontend process",
      recipeId: recipe.recipeId,
      mainRecipeId: recipe.mainRecipeId ?? recipe.recipeId,
      recipeName: recipe.recipeName,
    });
  };

  if (!enabled) {
    return null;
  }

  return (
    <>
      <p className="flex items-center h-15">
        <IconHeartStar
          width={28}
          height={28}
          stroke={1.5}
          className="mr-2 shrink-0"
        />
        {clicked
          ? intl.THANK_YOU_FOR_YOUR_FEEDBACK
          : intl.DO_YOU_WANT_THIS_RECIPE_BACK_ON_THE_MENU}
      </p>
      {!clicked && (
        <Button
          onClick={handleWishlist}
          className="bg-white text-nowrap"
          outlined
          tiny
        >
          {intl.REPORT_INTEREST}
        </Button>
      )}
    </>
  );
};

const CurrentlyOnTheMenu = () => {
  const dispatch = useDispatch();
  const nextCutoff = getNextCutoff();

  return (
    <>
      <p className="flex items-center h-15">
        <IconTruckDelivery
          width={28}
          height={28}
          stroke={1.5}
          className="mr-2 shrink-0"
        />
        {intl.THIS_DISH_IS_CURRENTLY_ON_THE_MENU}
      </p>
      <Link
        href={BRAND_PATHS.WEEK_EDITOR_PAGE({
          week: nextCutoff.week,
          year: nextCutoff.year,
        })}
        passHref
        legacyBehavior
      >
        <Button
          onClick={() => {
            dispatch(resetRecipeModal());
          }}
          className="bg-white text-nowrap"
          outlined
          tiny
        >
          {intl.GO_TO_ORDERING}
        </Button>
      </Link>
    </>
  );
};

const BackOnTheMenu = ({ recipe, week, year }: BackOnTheMenuProps) => {
  const track = useTrack({ serverSide: true });
  const [clicked, setClicked] = useState(false);

  const createReminderEnabled = useFlag("global_create_recipe_reminder");

  const cutoffs = getNextCutoffs(12);
  const cutoff = cutoffs.find((c) => c.week === week && c.year === year);

  const recipeImageUrl = getImageFromImages({ images: recipe.images });

  const handleCreateReminder = () => {
    track("recipeReminderCreated", {
      affiliation: "Frontend process",
      recipeId: recipe.recipeId,
      mainRecipeId: recipe.mainRecipeId ?? recipe.recipeId,
      recipeName: recipe.recipeName,
      image_url: recipeImageUrl,
      target_cutoff: cutoff ?? null,
    });

    setClicked(true);
  };

  return (
    <>
      <p className="flex items-center h-15">
        <IconCalendarTime
          width={28}
          height={28}
          stroke={1.5}
          className="mr-2 shrink-0"
        />
        {clicked ? (
          <>
            {intl.REMINDER_WLL_BE_SENT}
            <AnimatedCheck className="ml-2 shrink-0" isChecked />
          </>
        ) : (
          intl.THIS_DISH_IS_BACK_ON_THE_MENU_IN_WEEK(week)
        )}
      </p>
      {!clicked && createReminderEnabled && (
        <Button
          onClick={handleCreateReminder}
          className="bg-white text-nowrap"
          outlined
          tiny
        >
          {intl.REMIND_ME}
        </Button>
      )}
    </>
  );
};

export const AvailableMenuWeeks = ({
  recipe,
  selectedPortionsSize,
}: AvailableMenuWeeksProps) => {
  const availableWeeksWithPortionsSize = recipe.availableMenuWeeks
    .filter((availableWeek) =>
      availableWeek.portions.includes(selectedPortionsSize),
    )
    .map((w) => [w.week, w.year] as const);

  if (isEmptyArray(availableWeeksWithPortionsSize)) {
    return <Wishlist recipe={recipe} />;
  }

  const nextCutoff = getNextCutoff();

  const isOnNextWeeksMenu = availableWeeksWithPortionsSize.some(
    ([week, year]) => week === nextCutoff.week && year === nextCutoff.year,
  );

  if (isOnNextWeeksMenu) {
    return <CurrentlyOnTheMenu />;
  } else {
    const [week, year] = availableWeeksWithPortionsSize[0];
    return <BackOnTheMenu recipe={recipe} week={week} year={year} />;
  }
};
