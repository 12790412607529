import { GetStaticPaths, GetStaticPathsResult } from "next";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { createWrapper } from "next-redux-wrapper";

import features from "./features";

import { api } from "./graphql/api";
import { analyticsMiddleware, loadState } from "./util";

import { listenerMiddleware } from "./listeners";

const reducer = {
  ...features.reducers,
  [api.reducerPath]: api.reducer,
};

export const createStore = () => {
  const store = configureStore({
    preloadedState: loadState(),
    reducer,

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      // NOTE: disabling immutableCheck because of shit code causing issues.
      // cannot remove said code because it might be cruical for the site(s) to work
      getDefaultMiddleware({
        immutableCheck: false,
        // having serializableCheck enabled means you can't have certain types in queries
        // we don't serialize everything, so disabling it is fine
        serializableCheck: false,
      }).concat([
        ...features.middleware,
        api.middleware,
        listenerMiddleware.middleware,
        analyticsMiddleware,
      ]),
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch);

  gStore = store;
  return store;
};

type AppStore = ReturnType<typeof createStore>;
let gStore: AppStore;
export const getStore = () => gStore;

export type RootState = ReturnType<AppStore["getState"]>;

// https://github.com/phryneas/ssr-experiments/blob/main/nextjs-blog/lib/store.ts
export const storeWrapper = createWrapper(createStore);

type GetStaticPathsCallback = (
  store: AppStore,
) => (ctx: GetStaticPaths) => Promise<GetStaticPathsResult>;
export const getStaticPathsWithStore = (callback: GetStaticPathsCallback) => {
  // if gStore doesn't exist, we're creating a temporary one that just exists server side and is used for nothing other than getting static paths
  // this store won't actually contain any data, it's just to keep the api the same for all of these functions.
  if (!gStore) {
    createStore();
  }
  return (ctx: GetStaticPaths) => callback(gStore)(ctx);
};

export * from "./features";
export * from "./graphql/generated";
export * from "./listeners";

export type AppDispatch = typeof gStore.dispatch;

export { getLoginCookieData } from "./util";
