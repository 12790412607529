import * as React from "react";
import { SVGProps } from "react";
const Milk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 13 14"
    fill="none"
    {...props}
  >
    <path
      d="M2.50033 13H10.5003C10.8539 13 11.1931 12.8595 11.4431 12.6095C11.6932 12.3594 11.8337 12.0203 11.8337 11.6667V7.78667C11.8336 7.26919 11.7131 6.75883 11.4817 6.296L10.8523 5.03733C10.6209 4.5745 10.5004 4.06414 10.5003 3.54667V2.33333C10.5003 1.97971 10.3598 1.64057 10.1098 1.39052C9.85975 1.14048 9.52061 1 9.16699 1H3.83366C3.48004 1 3.1409 1.14048 2.89085 1.39052C2.6408 1.64057 2.50033 1.97971 2.50033 2.33333V4.33333M2.50033 13C2.1467 13 1.80756 12.8595 1.55752 12.6095C1.30747 12.3594 1.16699 12.0203 1.16699 11.6667V7.78667C1.16703 7.26919 1.28754 6.75883 1.51899 6.296L2.50033 4.33333M2.50033 13C2.85395 13 3.19309 12.8595 3.44313 12.6095C3.69318 12.3594 3.83366 12.0203 3.83366 11.6667V7.78667C3.83362 7.26919 3.71311 6.75883 3.48166 6.296L2.50033 4.33333M5.83366 3.66667H7.16699M6.50033 9C6.50033 9.35362 6.6408 9.69276 6.89085 9.94281C7.1409 10.1929 7.48004 10.3333 7.83366 10.3333C8.18728 10.3333 8.52642 10.1929 8.77647 9.94281C9.02652 9.69276 9.16699 9.35362 9.16699 9C9.16699 8.64638 9.02652 8.30724 8.77647 8.05719C8.52642 7.80714 8.18728 7.66667 7.83366 7.66667C7.48004 7.66667 7.1409 7.80714 6.89085 8.05719C6.6408 8.30724 6.50033 8.64638 6.50033 9Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Milk;
