import { useState } from "react";
import clsx from "clsx";

import { Card } from "@chef/components";
import { Info, TimeSwap } from "@chef/icons/small";
import { TimeSwap as SmallTimeSwap } from "@chef/icons/small";

import { Modal } from "./modal/Modal";

import { intl } from "./DeliveryTimeAdjustedWarning.Intl";

interface DeliveryTimeAdjustedWarningProps {
  BlockContent: () => JSX.Element | null;
}

export const DeliveryTimeAdjustedWarning = ({
  BlockContent,
}: DeliveryTimeAdjustedWarningProps) => {
  const [
    openWhyDeliveryTimeAdjustedModal,
    setOpenWhyDeliveryTimeAdjustedModal,
  ] = useState(false);

  const contentExists = !!BlockContent();

  return (
    <>
      <div>
        <button
          className={clsx(
            "text-sm text-left text-error content-center flex items-center ",
            !contentExists && "cursor-default",
          )}
          onClick={() =>
            contentExists && setOpenWhyDeliveryTimeAdjustedModal(true)
          }
        >
          <Info />

          <span className="pl-2">{intl.DELIVERY_TIME_ADJUSTED}</span>
        </button>
      </div>

      <Modal
        name="delivery-time-adjusted-warning-modal"
        open={openWhyDeliveryTimeAdjustedModal}
        size="md"
        onClose={() => setOpenWhyDeliveryTimeAdjustedModal(false)}
      >
        <Card>
          <div className="flex flex-col items-center justify-between text-center">
            <div className="hidden md:inline">
              <SmallTimeSwap className="w-20 h-20 mt-16" />
            </div>
            <div className="inline md:hidden">
              <TimeSwap className="mt-8 w-36 h-36" />
            </div>
            <h1 className="mt-6 text-xl">
              <strong>{intl.WHY_ARE_DELIVERY_TIME_ADJUSTED}</strong>
            </h1>
            <Card className="max-w-2xl mx-4 mt-6">
              <section className="p-4 space-y-4 text-sm">
                <BlockContent />
              </section>
            </Card>
          </div>
        </Card>
      </Modal>
    </>
  );
};
