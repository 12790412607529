import { memo } from "react";
import { motion } from "framer-motion";
import Link from "next/link";

import { language } from "@chef/constants";
import { ChevronLeft, Close, ReceiptDefault } from "@chef/icons/small";

import type { Step } from "./types";
import { Logo } from "../Logo";
import { Drawer } from "./Drawer";
import { StepsVariantComponent } from "./StepsComponent";

type StepsVariantProps = {
  variant: "steps";
  steps?: Step[];
  current?: number;
  title?: string;
} & (
  | {
      onClose: () => void;
      exitLabel: string;
    }
  | {
      onBack: () => void;
      exitLabel: string;
    }
  | {
      backHref: string;
      exitLabel: string;
    }
  | {
      closeHref: string;
      exitLabel: string;
    }
  | {
      exitLabel: undefined;
    }
) &
  (
    | {
        onCartClick: () => void;
        cartCount: number;
      }
    | { onCartClick?: never; cartCount?: never }
  );

const intl = {
  no: {
    SHOW_YOUR_ORDER: "Vis din bestilling",
  },
  se: {
    SHOW_YOUR_ORDER: "Visa din beställning",
  },
  dk: {
    SHOW_YOUR_ORDER: "Vis din ordre",
  },
}[language];

const variants = {
  hover: {
    width: "100%",
  },
  rest: {
    width: 40,
  },
};

export const StepsVariant = ({
  steps = [],
  title,
  current = 1,
  onCartClick,
  cartCount,

  ...rest
}: StepsVariantProps) => {
  const ExitComponent = memo(() => {
    if ("onClose" in rest) {
      return (
        <button
          onClick={rest.onClose}
          className="flex items-center w-10 h-10 gap-4 rounded bg-grey-2 lg:w-fit lg:h-fit lg:bg-transparent"
        >
          <Close />
          {rest.exitLabel}
        </button>
      );
    }

    if ("onBack" in rest) {
      return (
        <div className="flex items-center justify-center w-10 h-10 rounded bg-grey-2 lg:w-fit lg:h-fit lg:bg-transparent">
          <ChevronLeft />
          <button onClick={rest.onBack} className="text-sm">
            {rest.exitLabel}
          </button>
        </div>
      );
    }

    if ("closeHref" in rest) {
      return (
        <div className="flex items-center justify-center w-10 h-10 gap-4 rounded bg-grey-2 lg:w-fit lg:h-fit lg:bg-transparent">
          <Link href={rest.closeHref} passHref className="sm">
            <Close />
            <span className="hidden pl-4 lg:inline">{rest.exitLabel}</span>
          </Link>
        </div>
      );
    }

    if ("backHref" in rest) {
      return (
        <div className="flex items-center justify-center w-10 h-10 rounded bg-grey-2 lg:w-fit lg:h-fit lg:bg-transparent">
          <Link href={rest.backHref} passHref className="text-sm">
            <ChevronLeft />
            <span className="hidden pl-4 lg:inline">{rest.exitLabel}</span>
          </Link>
        </div>
      );
    }

    return <Logo href="/" responsive />;
  });

  ExitComponent.displayName = "ExitComponent";

  return (
    <div className="flex flex-col items-center justify-center h-15">
      <div className="flex items-center justify-between w-full h-14 lg:h-full">
        <div className="flex justify-start w-24 text-sm lg:w-48">
          <ExitComponent />
        </div>
        <StepsVariantComponent steps={steps} title={title} current={current} />

        <div className="flex justify-end w-24 lg:w-48">
          {onCartClick && (
            <motion.button
              className="relative flex items-center justify-center h-10 gap-2 group"
              whileHover="hover"
              animate="rest"
              onClick={onCartClick}
            >
              <div className="items-center hidden h-full px-2 text-sm rounded z-1 lg:flex">
                {intl.SHOW_YOUR_ORDER}
              </div>
              <div className="relative flex items-center justify-center w-10 h-full rounded z-5">
                <p className="absolute top-0.75 right-0.75 w-4.5 aspect-square flex items-center justify-center text-xs rounded-full bg-primary text-white">
                  {cartCount}
                </p>

                <ReceiptDefault className="w-6 h-6" />
              </div>

              <motion.div
                className="absolute right-0 h-full rounded bg-grey-2"
                variants={variants}
              />
            </motion.button>
          )}
        </div>
      </div>
      <Drawer hidden />
    </div>
  );
};
