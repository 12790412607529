import { BRAND_NAME } from "./misc";

export const BRAND_PATHS = (
  {
    AMK: {
      CUSTOMERIO_EMAIL_DOMAIN: "http://email.adamsmatkasse.no", // NOTE: can't use https://e.customeriomail.com when we have setup domain

      LOG_IN_URL: "/logg-inn",
      LOGGED_OUT_PATH: "/utlogget",
      START_PAGE_PATH: "/app/start",

      SIGNUP_HREF: "/velg-matkasse",
      SIGNUP_CREATE_ACCOUNT_HREF: "/registrering/opprett-konto",
      SIGNUP_DELIVERY_DETAILS_HREF: "/registrering/adresse",
      SIGNUP_PAYMENT_PAGE: "/registrering/kasse",

      SIGNUP_CHOOSE_MEALBOX_HREF: (slug: string) =>
        `/velg-matkasse?preferanser=${slug}`,
      SIGNUP_FINISHED_PAGE: "/registrering/bekreftelse",

      MY_MENU_LOGOUT_PAGE: "/menyen",
      CONTACT_US_PAGE_LOGOUT: "/kontakt-oss",
      TOS_PATH: "/vilkar",
      PRIVACY_POLICY_PATH: "/personvern",
      FAQ_HREF: "https://tips.adamsmatkasse.no/nb/",
      HOW_IT_WORKS_PAGE: "/slik-fungerer-adamsmatkasse",
      ARTICLE_PATH: (slug: string) => `/artikkel/${slug}`,
      ARTICLES_PATH: "/artikler",
      CAMPAIGN_DIRECTORY: "/side/",
      SUPPLIERS_PATH: "/leverandorer",
      FORGOT_PASSWORD_LINK: "/glemt-passord",

      INFORMATION_COOKIES_PATH: "/informasjonskapsler",
      WHERE_WE_DELIVER_PATH: "/hvor-leverer-adamsmatkasse",
      SUSTAINABILITY_PATH: "/barekraft",
      MEALBOX_PATH: (slug: string) => `/matkasse/${slug}`,

      RECIPE_HUB_PATH: "/oppskrifter",
      RECIPE_HUB_CATEGORIES_PATH: "/kategorier",

      //MY ACCOUNT
      MY_ACCOUNT_HREF: "/app/min-konto",
      PERSONAL_INFORMATION_PAGE: "/app/min-konto/personalia",
      DELETE_ACCOUNT_SUCCESS_PAGE:
        "/app/min-konto/personalia/slett-konto/bekreftelse",
      DELETE_ACCOUNT_REQUEST_SENT_PAGE:
        "/app/min-konto/personalia/slett-konto/foresporsel-sendt",
      CHANGE_PERSONAL_INFORMATION_LINK: "/app/min-konto/personalia/rediger",
      CHANGE_PASSWORD_LINK: "/app/min-konto/personalia/endre-passord",
      DELETE_ACCOUNT_LINK: "/app/min-konto/personalia/slett-konto",
      CHANGE_CONSENTS_LINK: "/app/min-konto/personalia/samtykker",

      //GIFTCARD
      GIFTCARD_PAGE: "/gavekort",
      CANONICAL_GIFTCARD_URL: "https://www.adamsmatkasse.no/gavekort",
      GIFTCARD_SUCCESS_PAGE: "/gavekort/bekreftelse",

      //DELIVERY SETTINGS
      DELIVERY_INFORMATION_PAGE: "/app/min-konto/adresser",
      ADD_NEW_ADDRESS_PAGE: "/app/min-konto/adresser/legg-til",
      EDIT_ADDRESS_PAGE: (addressId: string) =>
        `/app/min-konto/adresser/rediger/${addressId}`,
      CHANGE_ACTIVE_ADDRESS_PAGE: `/app/min-konto/adresser/endre-aktiv-adresse`,

      //PREFERENCES
      MEALBOX_PREFERENCE: "/app/min-konto/mine-matvaner/viktig-preferanse",
      PREFERENCE_INFO_LINK: "/app/min-konto/mine-matvaner/smakspreferanse",
      CONFIRMATION_OF_PREFERENCE_UPDATE_PAGE:
        "/app/min-konto/mine-matvaner/bekreftelse",
      CONFIRMATION_OF_PREFERENCE_UPDATE_PAGE_WITH_ALLERGEN:
        "/app/min-konto/mine-matvaner/bekreftelse?allergen=true",
      PREFERENCE_ERROR_PAGE: "/app/min-konto/mine-matvaner/tekniske-problemer",

      //LOYALTY
      LOYALTY_HREF: "/app/min-konto/kundeklubb",
      LOYALTY_POINTSHOP_HREF: "/app/min-konto/kundeklubb/poengshop",
      LOYALTY_TIERS_HREF: "/app/min-konto/kundeklubb/medlemsniva",
      LOYALTY_PARTNERS_HREF: "/app/min-konto/kundeklubb/partnere",

      //TELL A FRIEND
      TELL_A_FRIEND_HREF: "/app/min-konto/vervenvenn",
      TELL_A_FRIEND_CONFIRMATION_PAGE: "/app/min-konto/vervenvenn/bekreftelse",
      TELL_A_FRIEND_LANDING_PATH: "/vervenvenn",

      //PAYMENT
      PAYMENT_LINK: "/app/min-konto/betaling",

      CONTACT_US_PAGE: "/app/min-konto/kontakt",
      CONTACT_SETTINGS_PAGE: "/app/min-konto/kontaktreservasjon",

      //MY ACOUNT SUBSCRIPTION
      MY_SUBSCRIPTION_LINK: "/app/min-konto/abonnement",
      CHOOSE_OPTION_TO_PAUSE: "/app/min-konto/abonnement/pause",
      PAUSE_SUBSCRIPTION_QUIZ_PAGE: "/app/min-konto/abonnement/pause/avslutt",

      PAUSE_SUBSCRIPTION_FOR_PERIOD_PAGE_PATH:
        "/app/min-konto/abonnement/pause/begrenset",
      PAUSE_FOR_PERIOD_SUCCESS_PATH:
        "/app/min-konto/abonnement/pause/begrenset/pauset",

      PAUSE_SUBSCRIPTION_SUCCESS_PAGE:
        "/app/min-konto/abonnement/pause/avslutt/avsluttet",

      //ADDONS SUBSCRIPTION
      ADDONS_SUBSCRIPTION_PATH: "/app/min-konto/abonnement/dagligvarer",
      ADDONS_SUBSCRIPTION_CONFIRMATION_PAGE:
        "/app/min-konto/abonnement/dagligvarer/bekreft",

      //MY ACCOUNT DISCOUNTS
      MY_DISCOUNTS_PAGE: "/app/min-konto/rabattkoder",

      CONTACT_US_PAGE_ERROR: (id: string) => `/kontakt-oss?errorId=${id}`,

      //MY MENU
      MY_MENU_PAGE: "/app/min-matkasse",
      MY_MENU_WEEK_HREF: ({ week, year }: { week: number; year: number }) =>
        `/app/min-matkasse/uke/${week}/${year}`,
      RESTART_BILLING: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/informasjon`,
      SHARED_MENU_PAGE: (base64: string) => `/retter/${base64}`,

      //WEEK EDITOR
      WEEK_EDITOR_PAGE: ({ week, year }: { week: number; year: number }) =>
        `/app/min-matkasse/uke/${week}/${year}/alacarte`,
      WEEK_EDITOR_CONFIRM_ORDER_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/bekreft`,
      WEEK_EDITOR_CONFIRMATION_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/bekreftelse`,
      WEEK_EDITOR_STANDALONE_PAGE: ({
        week,
        year,
      }: {
        week: number;
        year: number;
      }) => `/app/min-matkasse/uke/${week}/${year}/dagligvarer`,

      //brand specific piece of link
      WEEK_PATH: "/uke",
      NEXT_WEEK_PATH: "/uke/neste-uke",
      NEXT_WEEK_FULL_PATH: "/app/min-matkasse/uke/neste-uke",

      //DELIVERY CALENDAR
      DELIVERY_CALENDAR_UPCOMING_LINK: "/app/leveringer",
      DELIVERY_CALENDAR_ON_THE_WAY_LINK: "/app/leveringer/underveis",
      DELIVERY_CALENDAR_HISTORY_LINK: "/app/leveringer/historikk",

      //DELIVERY CALENDAR: ORDER DETAILS
      ORDER_DETAILS_LINK: (orderId: number | string) =>
        `/app/leveringer/historikk/${orderId}`,

      //RECIPES
      MY_RECIPES_PAGE: "/app/mine-oppskrifter",

      //RECIPE
      RECIPE_HREF: (id: number) => `/oppskrift/${id}`,
      /**
       * @param recipeId id of the recipe
       * @param recipeName name of the recipe, must be slugified
       * @returns
       */
      RECIPE_WITH_NAME_HREF: ({
        recipeId,
        recipeName,
      }: {
        recipeId: number;
        recipeName: string;
      }) => `/oppskrift/${recipeId}/${recipeName}`,

      //CLAIMS
      CLAIM_PAGE: "/app/leveringer/historikk/reklamasjon",

      CLAIM_ORDER_HISTORY_HREF: (orderId: number | string) =>
        `/app/leveringer/historikk/${orderId}/reklamasjon`,
      CLAIM_SUCCESS_HREF: (orderId: string) =>
        `/app/leveringer/historikk/${orderId}/reklamasjon-sendt`,

      //CAMPAIGN
      CAMPAIGN_LINK: "/kampanje/",
      CAMPAIGN_PAGE: (slug: string) => `/app/kampanje/${slug}`,
      CAMPAIGN_SLUG: "kampanje",
      CAMPAIGN_FULL_SLUG: "app/kampanje",
      CAMPAIGN_GROCERY_SLUG: "app/kampanje/dagligvarer/",
      CAMPAIGN_SUCCESS_ORDER_PAGE: (slug: string) =>
        `/app/kampanje/${slug}/bestilt`,
      CAMPAIGN_MY_ORDER_PAGE: (slug: string) =>
        `/app/kampanje/${slug}/min-bestilling`,
      CAMPAIGN_DELIVERY_CANCEL_LINK: (promotionSlug: string) =>
        `/${BRAND_PATHS.CAMPAIGN_FULL_SLUG}/${promotionSlug}/avbestilt`,
      CAMPAIGN_SOLD_OUT_PATH: (slug: string) => `/app/kampanje/${slug}/utsolgt`,

      ORDERED_CAMPAIGN_OVERVIEW_PAGE: "/min-bestilling",

      //HERE CAN BE LINKS WHAT REPEATED BEFORE BUT WITH DIFFERENT PATHS DEPENDING ON BRAND
      POST_REACTIVATION_LOCATION: "/app/min-konto/abonnement",

      //EXTERNAL LINKS
      APP_STORE_URL: "https://apps.apple.com/no/app/adams-matkasse/id946946965",
      GOOGLE_PLAY_URL:
        "https://play.google.com/store/apps/details?id=no.adams.matkasse&hl=no&gl=NO",
      INSTAGRAM_URL: "https://www.instagram.com/adamsmatkasse/",
      FACEBOOK_URL: "https://www.facebook.com/adamsmatkasse",
      FACEBOOK_API_URL: "https://connect.facebook.net/nb_NO/sdk.js",
      MEMBERSHOP_LINK: "[PH] MEMBERSHOP_LINK",
      ANDROID_UPDATE_URL:
        "https://play.google.com/store/apps/details?id=no.adams.matkasse",
      IOS_UPDATE_URL:
        "https://apps.apple.com/no/app/adams-matkasse/id946946965",

      RAATASS_LINK: "https://raatass.no",
      WEIGHT_WATCHERS_LINK: "[PH]",
    },
    GL: {
      CUSTOMERIO_EMAIL_DOMAIN: "http://email.godtlevert.no", // NOTE: can't use https://e.customeriomail.com when we have setup domain

      LOG_IN_URL: "/logg-inn",
      LOGGED_OUT_PATH: "/utlogget",
      START_PAGE_PATH: "/app/start",

      SIGNUP_HREF: "/velg-matkasse",
      SIGNUP_CREATE_ACCOUNT_HREF: "/registrering/opprett-konto",
      SIGNUP_DELIVERY_DETAILS_HREF: "/registrering/adresse",

      SIGNUP_PAYMENT_PAGE: "/registrering/kasse",

      SIGNUP_CHOOSE_MEALBOX_HREF: (slug: string) =>
        `/velg-matkasse?preferanser=${slug}`,
      SIGNUP_FINISHED_PAGE: "/registrering/bekreftelse",

      MY_MENU_LOGOUT_PAGE: "/menyen",
      CONTACT_US_PAGE_LOGOUT: "/kontakt-oss",
      TOS_PATH: "/vilkar",
      PRIVACY_POLICY_PATH: "/personvern",
      FAQ_HREF: "https://intercom.help/godtlevert-tips/nb/",
      HOW_IT_WORKS_PAGE: "/matkasse",
      ARTICLE_PATH: (slug: string) => `/artikkel/${slug}`,
      ARTICLES_PATH: "/artikler",
      CAMPAIGN_DIRECTORY: "/side/",
      SUPPLIERS_PATH: "/leverandorer",
      FORGOT_PASSWORD_LINK: "/glemt-passord",

      INFORMATION_COOKIES_PATH: "/informasjonskapsler",
      WHERE_WE_DELIVER_PATH: "/hvor-leverer-godtlevert",
      SUSTAINABILITY_PATH: "/barekraft",
      MEALBOX_PATH: (slug: string) => `/matkasse/${slug}`,

      RECIPE_HUB_PATH: "/oppskrifter",
      RECIPE_HUB_CATEGORIES_PATH: "/kategorier",

      //MY ACCOUNT
      MY_ACCOUNT_HREF: "/app/min-konto",
      PERSONAL_INFORMATION_PAGE: "/app/min-konto/personalia",
      DELETE_ACCOUNT_SUCCESS_PAGE:
        "/app/min-konto/personalia/slett-konto/bekreftelse",
      DELETE_ACCOUNT_REQUEST_SENT_PAGE:
        "/app/min-konto/personalia/slett-konto/foresporsel-sendt",
      CHANGE_PERSONAL_INFORMATION_LINK: "/app/min-konto/personalia/rediger",
      CHANGE_PASSWORD_LINK: "/app/min-konto/personalia/endre-passord",
      DELETE_ACCOUNT_LINK: "/app/min-konto/personalia/slett-konto",
      CHANGE_CONSENTS_LINK: "/app/min-konto/personalia/samtykker",

      //GIFTCARD
      GIFTCARD_PAGE: "/gavekort",
      CANONICAL_GIFTCARD_URL: "https://www.godtlevert.no/gavekort",
      GIFTCARD_SUCCESS_PAGE: "/gavekort/bekreftelse",

      //DELIVERY SETTINGS
      DELIVERY_INFORMATION_PAGE: "/app/min-konto/adresser",
      ADD_NEW_ADDRESS_PAGE: "/app/min-konto/adresser/legg-til",
      EDIT_ADDRESS_PAGE: (addressId: string) =>
        `/app/min-konto/adresser/rediger/${addressId}`,
      CHANGE_ACTIVE_ADDRESS_PAGE: `/app/min-konto/adresser/endre-aktiv-adresse`,

      //PREFERENCES
      MEALBOX_PREFERENCE: "/app/min-konto/mine-matvaner/viktig-preferanse",
      PREFERENCE_INFO_LINK: "/app/min-konto/mine-matvaner/smakspreferanse",
      CONFIRMATION_OF_PREFERENCE_UPDATE_PAGE:
        "/app/min-konto/mine-matvaner/bekreftelse",
      PREFERENCE_ERROR_PAGE: "/app/min-konto/mine-matvaner/tekniske-problemer",

      //LOYALTY
      LOYALTY_HREF: "/app/min-konto/kundeklubb",
      LOYALTY_POINTSHOP_HREF: "/app/min-konto/kundeklubb/poengshop",
      LOYALTY_TIERS_HREF: "/app/min-konto/kundeklubb/medlemsniva",
      LOYALTY_PARTNERS_HREF: "/app/min-konto/kundeklubb/partnere",

      //TELL A FRIEND
      TELL_A_FRIEND_HREF: "/app/min-konto/vervenvenn",
      TELL_A_FRIEND_CONFIRMATION_PAGE: "/app/min-konto/vervenvenn/bekreftelse",
      TELL_A_FRIEND_LANDING_PATH: "/verv-en-venn",

      //PAYMENT
      PAYMENT_LINK: "/app/min-konto/betaling",

      CONTACT_US_PAGE: "/app/min-konto/kontakt",
      CONTACT_SETTINGS_PAGE: "/app/min-konto/kontaktreservasjon",

      //MY ACOUNT SUBSCRIPTION
      MY_SUBSCRIPTION_LINK: "/app/min-konto/abonnement",
      CHOOSE_OPTION_TO_PAUSE: "/app/min-konto/abonnement/pause",
      PAUSE_SUBSCRIPTION_QUIZ_PAGE: "/app/min-konto/abonnement/pause/avslutt",

      PAUSE_SUBSCRIPTION_FOR_PERIOD_PAGE_PATH:
        "/app/min-konto/abonnement/pause/begrenset",
      PAUSE_FOR_PERIOD_SUCCESS_PATH:
        "/app/min-konto/abonnement/pause/begrenset/pauset",

      PAUSE_SUBSCRIPTION_SUCCESS_PAGE:
        "/app/min-konto/abonnement/pause/avslutt/avsluttet",

      //ADDONS SUBSCRIPTION
      ADDONS_SUBSCRIPTION_PATH: "/app/min-konto/abonnement/dagligvarer",
      ADDONS_SUBSCRIPTION_CONFIRMATION_PAGE:
        "/app/min-konto/abonnement/dagligvarer/bekreft",

      //MY ACCOUNT DISCOUNTS
      MY_DISCOUNTS_PAGE: "/app/min-konto/rabattkoder",

      CONTACT_US_PAGE_ERROR: (id: string) => `/kontakt-oss?errorId=${id}`,

      //MY MENU
      MY_MENU_PAGE: "/app/min-matkasse",
      MY_MENU_WEEK_HREF: ({ week, year }: { week: number; year: number }) =>
        `/app/min-matkasse/uke/${week}/${year}`,
      RESTART_BILLING: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/informasjon`,
      SHARED_MENU_PAGE: (base64: string) => `/retter/${base64}`,

      //WEEK EDITOR
      WEEK_EDITOR_PAGE: ({ week, year }: { week: number; year: number }) =>
        `/app/min-matkasse/uke/${week}/${year}/alacarte`,
      WEEK_EDITOR_CONFIRM_ORDER_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/bekreft`,

      WEEK_EDITOR_CONFIRMATION_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-matkasse/uke/${week}/${year}/bekreftelse`,
      WEEK_EDITOR_STANDALONE_PAGE: ({
        week,
        year,
      }: {
        week: number;
        year: number;
      }) => `/app/min-matkasse/uke/${week}/${year}/dagligvarer`,

      //brand specific piece of link
      WEEK_PATH: "/uke",
      NEXT_WEEK_PATH: "/uke/neste-uke",
      NEXT_WEEK_FULL_PATH: "/app/min-matkasse/uke/neste-uke",

      //DELIVERY CALENDAR
      DELIVERY_CALENDAR_UPCOMING_LINK: "/app/leveringer",
      DELIVERY_CALENDAR_ON_THE_WAY_LINK: "/app/leveringer/underveis",
      DELIVERY_CALENDAR_HISTORY_LINK: "/app/leveringer/historikk",

      //DELIVERY CALENDAR: ORDER DETAILS
      ORDER_DETAILS_LINK: (orderId: number | string) =>
        `/app/leveringer/historikk/${orderId}`,

      //RECIPES
      MY_RECIPES_PAGE: "/app/mine-oppskrifter",

      //RECIPE
      RECIPE_HREF: (id: number) => `/oppskrift/${id}`,
      /**
       * @param recipeId id of the recipe
       * @param recipeName name of the recipe, must be slugified
       * @returns
       */
      RECIPE_WITH_NAME_HREF: ({
        recipeId,
        recipeName,
      }: {
        recipeId: number;
        recipeName: string;
      }) => `/oppskrift/${recipeId}/${recipeName}`,

      //CLAIMS
      CLAIM_PAGE: "/app/leveringer/historikk/reklamasjon",

      CLAIM_ORDER_HISTORY_HREF: (orderId: number | string) =>
        `/app/leveringer/historikk/${orderId}/reklamasjon`,
      CLAIM_SUCCESS_HREF: (orderId: string) =>
        `/app/leveringer/historikk/${orderId}/reklamasjon-sendt`,

      //CAMPAIGN
      CAMPAIGN_LINK: "/kampanje/",
      CAMPAIGN_PAGE: (slug: string) => `/app/kampanje/${slug}`,
      CAMPAIGN_SLUG: "kampanje",
      CAMPAIGN_FULL_SLUG: "app/kampanje",
      CAMPAIGN_GROCERY_SLUG: "app/kampanje/frokost/",
      CAMPAIGN_SUCCESS_ORDER_PAGE: (slug: string) =>
        `/app/kampanje/${slug}/bestilt`,
      CAMPAIGN_MY_ORDER_PAGE: (slug: string) =>
        `/app/kampanje/${slug}/min-bestilling`,
      CAMPAIGN_DELIVERY_CANCEL_LINK: (promotionSlug: string) =>
        `/${BRAND_PATHS.CAMPAIGN_FULL_SLUG}/${promotionSlug}/avbestilt`,
      CAMPAIGN_SOLD_OUT_PATH: (slug: string) => `/app/kampanje/${slug}/utsolgt`,

      ORDERED_CAMPAIGN_OVERVIEW_PAGE: "/min-bestilling",

      //HERE CAN BE LINKS WHAT REPEATED BEFORE BUT WITH DIFFERENT PATHS DEPENDING ON BRAND
      POST_REACTIVATION_LOCATION: "/app/min-matkasse",

      //EXTERNAL LINKS
      APP_STORE_URL: "https://apps.apple.com/no/app/godtlevert-no/id1244053561",
      GOOGLE_PLAY_URL:
        "https://play.google.com/store/apps/details?id=no.godtlevert.qa.godtlevertapp&hl=no&gl=NO",
      INSTAGRAM_URL: "https://www.instagram.com/godtlevert/",
      FACEBOOK_URL: "https://www.facebook.com/Godtlevert",
      FACEBOOK_API_URL: "https://connect.facebook.net/nb_NO/sdk.js",
      MEMBERSHOP_LINK: "[PH] MEMBERSHOP_LINK",
      ANDROID_UPDATE_URL:
        "https://play.google.com/store/apps/details?id=no.godtlevert.qa.godtlevertapp",
      IOS_UPDATE_URL:
        "https://apps.apple.com/no/app/godtlevert-no/id1244053561",

      RAATASS_LINK: "https://raatass.no",
      WEIGHT_WATCHERS_LINK: "[PH]",
    },
    LMK: {
      CUSTOMERIO_EMAIL_DOMAIN: "http://epost.linasmatkasse.se", // NOTE: can't use https://e.customeriomail.com when we have setup domain

      LOG_IN_URL: "/logga-in",
      LOGGED_OUT_PATH: "/utloggad",
      START_PAGE_PATH: "/mina-sidor/start",

      SIGNUP_HREF: "/registrering",
      SIGNUP_CREATE_ACCOUNT_HREF: "/registrering/skapa-konto",
      SIGNUP_DELIVERY_DETAILS_HREF: "/registrering/adress",

      SIGNUP_PAYMENT_PAGE: "/registrering/kassa",

      SIGNUP_CHOOSE_MEALBOX_HREF: (slug: string) =>
        `/registrering?preferanser=${slug}`,
      SIGNUP_FINISHED_PAGE: "/registrering/bekraftelse",

      MY_MENU_LOGOUT_PAGE: "/pa-menyn",
      CONTACT_US_PAGE_LOGOUT: "/kontaktuppgifter",
      TOS_PATH: "/villkor",
      PRIVACY_POLICY_PATH: "/integritetspolicy",
      FAQ_HREF: "https://support.linasmatkasse.se/sv/",
      HOW_IT_WORKS_PAGE: "/sa-funkar-det",
      ARTICLE_PATH: (slug: string) => `/artikel/${slug}`,
      ARTICLES_PATH: "/artiklar",
      CAMPAIGN_DIRECTORY: "/_kampanj/",
      SUPPLIERS_PATH: "/leverantorer",
      FORGOT_PASSWORD_LINK: "/glomt-losenord",

      INFORMATION_COOKIES_PATH: "/cookiepolicy",
      WHERE_WE_DELIVER_PATH: "/leverans",
      SUSTAINABILITY_PATH: "/hallbarhet",
      MEALBOX_PATH: (slug: string) => `/matkasse/${slug}`,

      RECIPE_HUB_PATH: "/receptbank",
      RECIPE_HUB_CATEGORIES_PATH: "/kategorier",

      //MY ACCOUNT
      MY_ACCOUNT_HREF: "/mina-sidor/mitt-konto",
      PERSONAL_INFORMATION_PAGE: "/mina-sidor/mitt-konto/mina-uppgifter",

      DELETE_ACCOUNT_SUCCESS_PAGE:
        "/mina-sidor/mitt-konto/mina-uppgifter/radera-konto/bekraftelse",
      DELETE_ACCOUNT_REQUEST_SENT_PAGE:
        "/mina-sidor/mitt-konto/mina-uppgifter/radera-konto/begaran-mottagits",
      CHANGE_PERSONAL_INFORMATION_LINK:
        "/mina-sidor/mitt-konto/mina-uppgifter/redigera",
      CHANGE_PASSWORD_LINK:
        "/mina-sidor/mitt-konto/mina-uppgifter/redigera-losenord",
      DELETE_ACCOUNT_LINK: "/mina-sidor/mitt-konto/mina-uppgifter/radera-konto",
      CHANGE_CONSENTS_LINK: "/mina-sidor/mitt-konto/mina-uppgifter/medgivanden",

      //GIFTCARD
      GIFTCARD_PAGE: "/presentkort",
      CANONICAL_GIFTCARD_URL: "https://www.linasmatkasse.se/presentkort",
      GIFTCARD_SUCCESS_PAGE: "/presentkort/bekraftelse",

      //DELIVERY SETTINGS
      DELIVERY_INFORMATION_PAGE: "/mina-sidor/mitt-konto/leveransuppgifter",
      ADD_NEW_ADDRESS_PAGE:
        "/mina-sidor/mitt-konto/leveransuppgifter/ny-adress",
      EDIT_ADDRESS_PAGE: (addressId: string) =>
        `/mina-sidor/mitt-konto/leveransuppgifter/${addressId}/redigera`,
      CHANGE_ACTIVE_ADDRESS_PAGE: `/mina-sidor/mitt-konto/leveransuppgifter/andra-aktiv-adress`,

      //PREFERENCES
      MEALBOX_PREFERENCE:
        "/mina-sidor/mitt-konto/mina-matvanor/viktiga-preferanser",
      PREFERENCE_INFO_LINK:
        "/mina-sidor/mitt-konto/mina-matvanor/matpreferenser",
      CONFIRMATION_OF_PREFERENCE_UPDATE_PAGE:
        "/mina-sidor/mitt-konto/mina-matvanor/bekraftelse",
      PREFERENCE_ERROR_PAGE:
        "/mina-sidor/mitt-konto/mina-matvanor/tekniska-problem",

      //LOYALTY
      LOYALTY_HREF: "/mina-sidor/kundklubb",
      LOYALTY_POINTSHOP_HREF: "/mina-sidor/kundklubb/poangshop",
      LOYALTY_TIERS_HREF: "/mina-sidor/kundklubb/medlemsniva",
      LOYALTY_PARTNERS_HREF: "/mina-sidor/kundklubb/partners",

      //TELL A FRIEND
      TELL_A_FRIEND_HREF: "/mina-sidor/tipsa-en-van",
      TELL_A_FRIEND_CONFIRMATION_PAGE: "/mina-sidor/tipsa-en-van/bekraftelse",
      TELL_A_FRIEND_LANDING_PATH: "/tipsa-en-van",

      //PAYMENT
      PAYMENT_LINK: "/mina-sidor/mitt-konto/betalningsoversikt",

      CONTACT_US_PAGE: "/mina-sidor/mitt-konto/kontaktuppgifter",
      CONTACT_SETTINGS_PAGE:
        "/mina-sidor/mitt-konto/kommunikationsinstallningar",

      //MY ACOUNT SUBSCRIPTION
      MY_SUBSCRIPTION_LINK: "/mina-sidor/mitt-konto/min-prenumeration",
      CHOOSE_OPTION_TO_PAUSE: "/mina-sidor/mitt-konto/pausa-prenumeration",
      PAUSE_SUBSCRIPTION_QUIZ_PAGE:
        "/mina-sidor/mitt-konto/min-prenumeration/pausa-prenumeration/avslut",

      PAUSE_SUBSCRIPTION_FOR_PERIOD_PAGE_PATH:
        "/mina-sidor/mitt-konto/pausa-prenumeration/begransad",
      PAUSE_FOR_PERIOD_SUCCESS_PATH:
        "/mina-sidor/mitt-konto/pausa-prenumeration/begransad/pausad",

      PAUSE_SUBSCRIPTION_SUCCESS_PAGE:
        "/mina-sidor/mitt-konto/min-prenumeration/pausa-prenumeration/avslut/avslutad",

      //ADDONS SUBSCRIPTION
      ADDONS_SUBSCRIPTION_PATH:
        "/mina-sidor/mitt-konto/min-prenumeration/livsmedel",
      ADDONS_SUBSCRIPTION_CONFIRMATION_PAGE:
        "/mina-sidor/mitt-konto/min-prenumeration/livsmedel/bekrafta",

      //MY ACCOUNT DISCOUNTS
      MY_DISCOUNTS_PAGE: "/mina-sidor/mitt-konto/rabatt",

      CONTACT_US_PAGE_ERROR: (id: string) => `/kontaktuppgifter?errorId=${id}`,

      //MY MENU
      MY_MENU_PAGE: "/mina-sidor/min-meny",
      MY_MENU_WEEK_HREF: ({ week, year }: { week: number; year: number }) =>
        `/mina-sidor/min-meny/vecka/${week}/${year}`,
      RESTART_BILLING: ({ week = 0, year = 0 }) =>
        `/mina-sidor/min-meny/vecka/${week}/${year}/information`,
      SHARED_MENU_PAGE: (base64: string) => `/ratter/${base64}`,

      //WEEK EDITOR
      WEEK_EDITOR_PAGE: ({ week, year }: { week: number; year: number }) =>
        `/mina-sidor/min-meny/vecka/${week}/${year}/alacarte`,
      WEEK_EDITOR_CONFIRM_ORDER_PAGE: ({ week = 0, year = 0 }) =>
        `/mina-sidor/min-meny/vecka/${week}/${year}/boka`,

      WEEK_EDITOR_CONFIRMATION_PAGE: ({ week = 0, year = 0 }) =>
        `/mina-sidor/min-meny/vecka/${week}/${year}/bekraftelse`,

      WEEK_EDITOR_STANDALONE_PAGE: ({
        week,
        year,
      }: {
        week: number;
        year: number;
      }) => `/mina-sidor/min-meny/vecka/${week}/${year}/tillagg`,

      //brand specific piece of link
      WEEK_PATH: "/vecka",
      NEXT_WEEK_PATH: "/vecka/nesta-vecka",
      NEXT_WEEK_FULL_PATH: "/mina-sidor/min-meny/vecka/nesta-vecka",

      //DELIVERY CALENDAR
      DELIVERY_CALENDAR_UPCOMING_LINK: "/mina-sidor/leveranser",
      DELIVERY_CALENDAR_ON_THE_WAY_LINK: "/mina-sidor/leveranser/pavag",
      DELIVERY_CALENDAR_HISTORY_LINK: "/mina-sidor/leveranser/historik",

      //DELIVERY CALENDAR: ORDER DETAILS
      ORDER_DETAILS_LINK: (orderId: number | string) =>
        `/mina-sidor/leveranser/historik/${orderId}`,

      //RECIPES
      MY_RECIPES_PAGE: "/mina-sidor/recept",

      //RECIPE
      RECIPE_HREF: (id: number) => `/recept/${id}`,
      /**
       * @param recipeId id of the recipe
       * @param recipeName name of the recipe, must be slugified
       * @returns
       */
      RECIPE_WITH_NAME_HREF: ({
        recipeId,
        recipeName,
      }: {
        recipeId: number;
        recipeName: string;
      }) => `/recept/${recipeId}/${recipeName}`,

      //CLAIMS
      CLAIM_PAGE: "/mina-sidor/leveranser/historik/reklamation",

      CLAIM_ORDER_HISTORY_HREF: (orderId: number | string) =>
        `/mina-sidor/leveranser/historik/${orderId}/reklamation`,
      CLAIM_SUCCESS_HREF: (orderId: string) =>
        `/mina-sidor/leveranser/historik/${orderId}/reklamation-skickat`,

      //CAMPAIGN
      CAMPAIGN_LINK: "/kampanj/",
      CAMPAIGN_PAGE: (slug: string) => `/mina-sidor/kampanj/${slug}`,
      CAMPAIGN_SLUG: "kampanj",
      CAMPAIGN_FULL_SLUG: "mina-sidor/kampanj",
      CAMPAIGN_GROCERY_SLUG: "mina-sidor/kampanj/livsmedel/",
      CAMPAIGN_MY_ORDER_PAGE: (slug: string) =>
        `/mina-sidor/kampanj/${slug}/min-bestallning`,
      CAMPAIGN_SUCCESS_ORDER_PAGE: (slug: string) =>
        `/mina-sidor/kampanj/${slug}/bestalld`,
      CAMPAIGN_DELIVERY_CANCEL_LINK: (promotionSlug: string) =>
        `/${BRAND_PATHS.CAMPAIGN_FULL_SLUG}/${promotionSlug}/avslutad`,
      CAMPAIGN_SOLD_OUT_PATH: (slug: string) =>
        `/mina-sidor/kampanj/${slug}/slutsald`,

      ORDERED_CAMPAIGN_OVERVIEW_PAGE: "/min-bestallning",

      //HERE CAN BE LINKS WHAT REPEATED BEFORE BUT WITH DIFFERENT PATH DEPENDING ON BRAND
      POST_REACTIVATION_LOCATION:
        "/mina-sidor/mitt-konto/min-prenumeration/genaktiveret",

      //EXTERNAL LINKS
      APP_STORE_URL: "https://apps.apple.com/se/app/linas-matkasse/id432892817",
      GOOGLE_PLAY_URL:
        "https://play.google.com/store/apps/details?id=se.linas.matkasse&hl=sv&gl=SE",
      INSTAGRAM_URL: "https://www.instagram.com/linasmatkasse/",
      FACEBOOK_URL: "https://www.facebook.com/linasmatkasse",
      FACEBOOK_API_URL: "https://connect.facebook.net/sv_SE/sdk.js",
      MEMBERSHOP_LINK: "http://linas.morot.co/webshop?access_key=843a6f64e25c",
      ANDROID_UPDATE_URL:
        "https://play.google.com/store/apps/details?id=se.linas.matkasse",
      IOS_UPDATE_URL:
        "https://apps.apple.com/no/app/linas-matkasse/id432892817",

      RAATASS_LINK: "N/A",
      WEIGHT_WATCHERS_LINK: "https://www.viktvaktarna.se/se/linas-matkasse",
    },
    RN: {
      CUSTOMERIO_EMAIL_DOMAIN: "http://email.retnemt.dk", // NOTE: can't use https://e.customeriomail.com when we have setup domain

      LOG_IN_URL: "/log-ind",
      LOGGED_OUT_PATH: "/logget-ud",
      START_PAGE_PATH: "/app/start",

      SIGNUP_HREF: "/vaelg-maltidskasse",
      SIGNUP_CREATE_ACCOUNT_HREF: "/registrering/opret-konto",
      SIGNUP_DELIVERY_DETAILS_HREF: "/registrering/adresse",

      SIGNUP_PAYMENT_PAGE: "/registrering/checkout",

      SIGNUP_CHOOSE_MEALBOX_HREF: (slug: string) =>
        `/vaelg-maltidskasse?praeferencer=${slug}`,
      SIGNUP_FINISHED_PAGE: "/registrering/bekraeftelse",
      MY_MENU_LOGOUT_PAGE: "/ugens-menu",
      CONTACT_US_PAGE_LOGOUT: "/kontaktinfo",
      TOS_PATH: "/online-handelsbetingelser",
      PRIVACY_POLICY_PATH: "/persondatapolitik",
      FAQ_HREF: "/ofte-stillede-spoergsmaal",
      HOW_IT_WORKS_PAGE: "/saadan-virker-retnemt",
      ARTICLE_PATH: (slug: string) => `/artikel/${slug}`,
      ARTICLES_PATH: "/artikler",
      CAMPAIGN_DIRECTORY: "/_kampagne/",
      SUPPLIERS_PATH: "/leverandorer",
      FORGOT_PASSWORD_LINK: "/glemt-password",

      INFORMATION_COOKIES_PATH: "/cookiepolitik",
      WHERE_WE_DELIVER_PATH: "/hvor-vi-leverer",
      SUSTAINABILITY_PATH: "/baeredygtighed",
      MEALBOX_PATH: (slug: string) => `/maltidskasse/${slug}`,

      RECIPE_HUB_PATH: "/opskrifter",
      RECIPE_HUB_CATEGORIES_PATH: "/kategorier",

      //MY ACCOUNT
      MY_ACCOUNT_HREF: "/app/min-konto",
      PERSONAL_INFORMATION_PAGE: "/app/min-konto/personalia",
      DELETE_ACCOUNT_SUCCESS_PAGE:
        "/app/min-konto/personalia/slet-konto/bekraeftelse",
      DELETE_ACCOUNT_REQUEST_SENT_PAGE:
        "/app/min-konto/personalia/slet-konto/anmodning-modtaget",
      CHANGE_PERSONAL_INFORMATION_LINK:
        "/app/min-konto/personalia/rediger-personalia",
      CHANGE_PASSWORD_LINK: "/app/min-konto/personalia/skift-password",
      DELETE_ACCOUNT_LINK: "/app/min-konto/personalia/slet-konto",
      CHANGE_CONSENTS_LINK: "/app/min-konto/personalia/samtykke",

      //GIFTCARD
      GIFTCARD_PAGE: "/gavekort",
      CANONICAL_GIFTCARD_URL: "https://www.retnemt.dk/gavekort",
      GIFTCARD_SUCCESS_PAGE: "/gavekort/success",

      //DELIVERY SETTINGS
      DELIVERY_INFORMATION_PAGE: "/app/min-konto/levering",
      ADD_NEW_ADDRESS_PAGE: "/app/min-konto/levering/skab-adresse",
      EDIT_ADDRESS_PAGE: (addressId: string) =>
        `/app/min-konto/levering/${addressId}/rediger-adresse`,
      CHANGE_ACTIVE_ADDRESS_PAGE: `/app/min-konto/levering/endre-aktiv-adresse`,

      //PREFERENCES
      MEALBOX_PREFERENCE: "/app/min-konto/mine-madvaner/praeferencer",
      PREFERENCE_INFO_LINK: "/app/min-konto/mine-madvaner/dine-praeferencer",
      CONFIRMATION_OF_PREFERENCE_UPDATE_PAGE:
        "/app/min-konto/mine-madvaner/bekraeftelse",
      PREFERENCE_ERROR_PAGE: "/app/min-konto/mine-madvaner/tekniske-problemer",

      //LOYALTY
      LOYALTY_HREF: "/app/min-konto/kundeklub",
      LOYALTY_POINTSHOP_HREF: "/app/min-konto/kundeklub/pointshop",
      LOYALTY_TIERS_HREF: "/app/min-konto/kundeklub/medlemsniveau",
      LOYALTY_PARTNERS_HREF: "/app/min-konto/kundeklub/partnere",

      //TELL A FRIEND
      TELL_A_FRIEND_HREF: "/app/anbefal-til-andre",
      TELL_A_FRIEND_CONFIRMATION_PAGE: "/app/anbefal-til-andre/bekraeftelse",
      TELL_A_FRIEND_LANDING_PATH: "/anbefal-til-andre",

      //PAYMENT
      PAYMENT_LINK: "/app/min-konto/betaling",

      CONTACT_US_PAGE: "/app/min-konto/kundeservice",
      CONTACT_SETTINGS_PAGE: "/app/min-konto/skift-kontaktindstillinger",

      //MY ACOUNT SUBSCRIPTION
      MY_SUBSCRIPTION_LINK: "/app/min-konto/abonnement",
      CHOOSE_OPTION_TO_PAUSE: "/app/min-konto/afmeld-abonnement",
      PAUSE_SUBSCRIPTION_QUIZ_PAGE: "/app/min-konto/afmeld-abonnement/afmeld",

      PAUSE_SUBSCRIPTION_FOR_PERIOD_PAGE_PATH:
        "/app/min-konto/afmeld-abonnement/pause",
      PAUSE_FOR_PERIOD_SUCCESS_PATH:
        "/app/min-konto/afmeld-abonnement/pause/bekraeftelse",

      PAUSE_SUBSCRIPTION_SUCCESS_PAGE:
        "/app/min-konto/afmeld-abonnement/afmeld/bekraeftelse",

      //ADDONS SUBSCRIPTION
      ADDONS_SUBSCRIPTION_PATH: "/app/min-konto/abonnement/specialvarer",
      ADDONS_SUBSCRIPTION_CONFIRMATION_PAGE:
        "/app/min-konto/abonnement/specialvarer/bekraeftelse",

      //MY ACCOUNT DISCOUNTS
      MY_DISCOUNTS_PAGE: "/app/min-konto/rabat",

      CONTACT_US_PAGE_ERROR: (id: string) => `/kontaktinfo?errorId=${id}`,

      //MY MENU
      MY_MENU_PAGE: "/app/min-menu",
      MY_MENU_WEEK_HREF: ({ week, year }: { week: number; year: number }) =>
        `/app/min-menu/uge/${week}/${year}`,
      RESTART_BILLING: ({ week = 0, year = 0 }) =>
        `/app/min-menu/uge/${week}/${year}/information`,
      SHARED_MENU_PAGE: (base64: string) => `/retter/${base64}`,

      //WEEK EDITOR
      WEEK_EDITOR_PAGE: ({ week, year }: { week: number; year: number }) =>
        `/app/min-menu/uge/${week}/${year}/alacarte`,
      WEEK_EDITOR_CONFIRM_ORDER_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-menu/uge/${week}/${year}/bestille`,

      WEEK_EDITOR_CONFIRMATION_PAGE: ({ week = 0, year = 0 }) =>
        `/app/min-menu/uge/${week}/${year}/bekraeftelse`,

      WEEK_EDITOR_STANDALONE_PAGE: ({
        week,
        year,
      }: {
        week: number;
        year: number;
      }) => `/app/min-menu/uge/${week}/${year}/specialvarer`,

      //brand specific piece of link
      WEEK_PATH: "/uge",
      NEXT_WEEK_PATH: "/uge/naeste-uge",
      NEXT_WEEK_FULL_PATH: "/app/min-menu/uge/naeste-uge",

      //DELIVERY CALENDAR
      DELIVERY_CALENDAR_UPCOMING_LINK: "/app/levering",
      DELIVERY_CALENDAR_ON_THE_WAY_LINK: "/app/levering/pa-vej",
      DELIVERY_CALENDAR_HISTORY_LINK: "/app/levering/tidligere",

      //DELIVERY CALENDAR: ORDER DETAILS
      ORDER_DETAILS_LINK: (orderId: number | string) =>
        `/app/levering/tidligere/${orderId}`,

      //RECIPES
      MY_RECIPES_PAGE: "/app/mine-opskrifter",

      //RECIPE
      RECIPE_HREF: (id: number) => `/opskrift/${id}`,
      /**
       * @param recipeId id of the recipe
       * @param recipeName name of the recipe, must be slugified
       * @returns
       */
      RECIPE_WITH_NAME_HREF: ({
        recipeId,
        recipeName,
      }: {
        recipeId: number;
        recipeName: string;
      }) => `/opskrift/${recipeId}/${recipeName}`,

      //CLAIMS
      CLAIM_PAGE: "/app/levering/tidligere/reklamation",

      CLAIM_ORDER_HISTORY_HREF: (orderId: number | string) =>
        `/app/levering/tidligere/${orderId}/reklamation`,
      CLAIM_SUCCESS_HREF: (orderId: string) =>
        `/app/levering/tidligere/${orderId}/reklamation-sendt`,

      //CAMPAIGN
      CAMPAIGN_LINK: "/kampagne/",
      CAMPAIGN_PAGE: (slug: string) => `/app/kampagne/${slug}`,
      CAMPAIGN_SLUG: "kampagne",
      CAMPAIGN_FULL_SLUG: "app/kampagne",
      CAMPAIGN_GROCERY_SLUG: "app/kampagne/specialvarer/",
      CAMPAIGN_SUCCESS_ORDER_PAGE: (slug: string) =>
        `/app/kampagne/${slug}/bestilt`,
      CAMPAIGN_MY_ORDER_PAGE: (slug: string) =>
        `/app/kampagne/${slug}/min-bestilling`,
      CAMPAIGN_DELIVERY_CANCEL_LINK: (promotionSlug: string) =>
        `/${BRAND_PATHS.CAMPAIGN_FULL_SLUG}/${promotionSlug}/afbestilt`,
      CAMPAIGN_SOLD_OUT_PATH: (slug: string) => `/app/kampagne/${slug}/udsolgt`,

      ORDERED_CAMPAIGN_OVERVIEW_PAGE: "/min-bestilling",

      //HERE CAN BE LINKS WHAT REPEATED BEFORE BUT WITH DIFFERENT PATHS DEPENDING ON BRAND
      POST_REACTIVATION_LOCATION: "/app/min-konto/abonnement/genaktiveret",

      //EXTERNAL LINKS
      APP_STORE_URL: "https://apps.apple.com/dk/app/retnemt/id1020587085",
      GOOGLE_PLAY_URL:
        "https://play.google.com/store/apps/details?id=com.andhype.retnemt&hl=da&gl=DK&pli=1",
      INSTAGRAM_URL: "https://www.instagram.com/retnemt/",
      FACEBOOK_URL: "https://www.facebook.com/retnemt",
      FACEBOOK_API_URL: "https://connect.facebook.net/da_DK/sdk.js",
      MEMBERSHOP_LINK: "[PH] MEMBERSHOP_LINK",
      ANDROID_UPDATE_URL:
        "https://play.google.com/store/apps/details?id=com.andhype.retnemt",
      IOS_UPDATE_URL: "https://apps.apple.com/sk/app/retnemt/id1020587085",

      RAATASS_LINK: "N/A",
      WEIGHT_WATCHERS_LINK: "[PH]",
    },
  } as const
)[BRAND_NAME];
